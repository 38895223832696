import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import {WaveSurferComponent} from "./WaveSurferComponent";
import {COLORS} from "@res/colors";
import {
	generateHex,
	Second
} from "@intuitionrobotics/ts-common";
import WaveSurfer from "wavesurfer.js";

export const icon_paused_gray = require("@res/icons/icon_paused_gray.svg");
export const icon_play_gray = require("@res/icons/icon_play_gray.svg");

type Props = {
	url: string | HTMLMediaElement;
	// Blob has priority over URL.
	blob?: Blob | File;
};

type State = {
	ready: boolean;
	waveSurfer?: WaveSurfer;
	playing: boolean;
	currentTimeSeconds?: number;
	duration?: number;
};

const audioPlayerStyle = emotion.css(
	{
		padding: "8px 13px",
		boxSizing: "border-box",
		border: `1px solid ${COLORS.neutral_gray_2}`,
		borderRadius: 8,
		backgroundColor: "white"
	});

const buttonStyle = emotion.css(
	{
		width: 32,
		minWidth: 32,
		height: 32,
		marginRight: 8
	}
);

const currentTimeStyle = emotion.css(
	{
		marginRight: 8,
		width: 32,
		minWidth: 32,
		boxSizing: "border-box"
	}
);

const duration = emotion.css(
	{
		marginLeft: 8,
		width: 32,
		boxSizing: "border-box"
	}
);

export class AudioWavePlayer
	extends BaseComponent<Props, State> {

	private audioPlayerRef?: HTMLDivElement;
	private throttleInterval: number = 0.05 * Second;

	constructor(props: Props) {
		super(props);
		this.state = {
			ready: false,
			waveSurfer: undefined,
			playing: false,
			currentTimeSeconds: undefined,
			duration: undefined
		};
	}

	waveSurferInit = (waveSurfer: WaveSurfer) => {
		if (this.props.blob)
			waveSurfer.loadBlob(this.props.blob);
		else
			waveSurfer.load(this.props.url);
		this.setState({waveSurfer}, () => {
			waveSurfer.on('finish', this.onFinish);
			waveSurfer.on('audioprocess', this.onAudioProcess);
			waveSurfer.on('seek', this.onSeek);
		});
	};

	onWaveSurferReady = () => {
		this.logInfo('waveSurfer instance is ready, parent call');
		if (!this.state.waveSurfer)
			return;

		this.setState(
			{
				ready: true,
				duration: this.state.waveSurfer.getDuration()
			});
	};

	onWaveSurferError = (error?: any) => {
		this.logError('Error event:', error);
	};

	onFinish = () => {
		if (!this.state.waveSurfer) {
			// This should not happen.
			this.logError('WaveSurfer instance not defined on finish.');
			return;
		}

		this.logInfo('Playing finished');
		this.state.waveSurfer.seekTo(0);
		this.setState({playing: false});
	};

	private onAudioProcess = (e: number): void => {
		return this.throttle(() => this.onAudioProcessImpl(e), 'audio-process', this.throttleInterval);
	};

	private onAudioProcessImpl = (sec: number) => {
		if (!this.state.waveSurfer) {
			this.setState({currentTimeSeconds: 0});
			return;
		}

		// This is required so that we don't change currentTimeSeconds after finishing.
		// For some reason an audioprocess event seems to be fired after the finish event.
		if (!this.state.waveSurfer.isPlaying())
			return;

		this.setState({currentTimeSeconds: sec});
	};

	// TODO: Investigate the events triggered when seeking while playing.
	private onSeek = (_progress: any) => {
		if (!this.state.waveSurfer) {
			// This should not happen.
			this.logError('WaveSurfer instance not defined on seek.');
			return;
		}

		if (this.state.waveSurfer.isPlaying())
			return;

		const currentTime = this.state.waveSurfer.getCurrentTime();
		this.setState(() => ({currentTimeSeconds: currentTime}));
	};

	private togglePlay = () => {
		if (!this.state.waveSurfer || !this.state.ready)
			return;

		this.setState({playing: !this.state.playing});
		this.state.waveSurfer.playPause();
	};

	private prettyCurrentTime = (sec?: number) => {
		if (!sec)
			return "0:00";

		const secInt = Math.floor(sec);
		const minutes = Math.floor(secInt / 60);
		const formattedNumber = (secInt % 60).toLocaleString('en-US', {
			minimumIntegerDigits: 2,
			useGrouping: false
		});

		return `${minutes}:${formattedNumber}`;
	};

	render() {
		return <div id={`audio-player-${generateHex(16)}`}
		            style={{width: "100%", height: "100%"}}
		            ref={(ref) => {
			            if (!ref || this.audioPlayerRef)
				            return;

			            this.audioPlayerRef = ref;
			            this.forceUpdate();
		            }}
		            className={`ll_h_c ${audioPlayerStyle}`}>
			{this.renderButton()}
			{this.renderCurrentTime()}
			{this.renderWaveSurfer()}
			{this.renderDuration()}
		</div>;
	}

	private renderButton = () => {
		const button = this.state.playing ?
			<img src={icon_paused_gray}/> :
			<img src={icon_play_gray}/>;

		return <div className={`${buttonStyle}`}
		            onClick={this.togglePlay}>
			{button}
		</div>;
	};

	private renderCurrentTime = () => {
		return <div className={`${currentTimeStyle}`}>
			{this.prettyCurrentTime(this.state.currentTimeSeconds)}
		</div>;
	};

	private renderDuration = () => {
		return <div className={`${duration}`}>
			{this.prettyCurrentTime(this.state.duration)}
		</div>;
	};

	private renderWaveSurfer = () => {
		if (!this.audioPlayerRef)
			return;

		const computerStyle = getComputedStyle(this.audioPlayerRef);

		const waveSurferHeight = this.audioPlayerRef.clientHeight -
			parseFloat(computerStyle.paddingTop) -
			parseFloat(computerStyle.paddingBottom);

		return <div className={`match_width match_height`}>
			<WaveSurferComponent
				wavesurferParams={{
					height: waveSurferHeight,
					barHeight: 1.5,
					cursorWidth: 0,
					backgroundColor: "white",
					progressColor: COLORS.neutral_text_dark_2,
					waveColor: COLORS.neutral_gray_2,
					barWidth: 2,
					barGap: 1,
					barMinHeight: 1,
					hideScrollbar: true
				}}
				placeholder={<div>Loading...</div>}
				onWaveSurferInit={this.waveSurferInit}
				onReady={this.onWaveSurferReady}
				onError={this.onWaveSurferError}
			/>
		</div>;
	};
}
