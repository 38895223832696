import * as React from "react";
import {BrowserHistoryModule, Playground, PlaygroundScreen} from "@intuitionrobotics/thunderstorm/frontend";
import {ICONS} from "@res/icons";
import {COLORS} from "@res/colors";
import {BugReport} from "@intuitionrobotics/bug-report/frontend";
import {selectStyles} from "../ui/SelectStyle";
import {Playground_Hello} from "./examples/Playground_Hello";
import {Playground_SendMessageToContact} from "./examples/Playground_SendMessageToContact";
import {Playground_ContactsInfo} from "./examples/Playground_ContactsInfo";
import {Playground_SendMmsMessageWholeFlow} from "./examples/Playground_SendMmsMessageWholeFlow";
import {Playground_ShowCloudAssets} from "./examples/Playground_ShowCloudAssets";
import {Playground_VisionLabel} from "./examples/Playground_VisionLabel";
import {CloudAssetsTable} from "../general-cloud-assets/CloudAssetsTable";
import {Playground_VideoCallDashboard} from "./examples/Playground_VideoCallDashboard";
import {Playground_VideoCall} from "./examples/Playground_VideoCall";
import {Playground_SendMmsTest} from "./examples/Playground_SendMmsTest";
import {Playground_SearchContacts} from "./examples/Playground_SearchContacts";
import {Playground_MessageList} from "./examples/Playground_MessageList";
import {Playground_TaskSchedulerTable} from "./examples/Playground_TaskSchedulerTable";
import * as emotion from "emotion";
import {CommunityCloudAssetTable} from "./examples/Playground_CommunityCloudAssets";

const clazz = emotion.css({
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 10,
    flex: 1
});
const buttonClass = emotion.css(`
    padding: 10px;
    border: 1px solid orange;
    border-radius: 20px;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
        background-color: orange;
        cursor: pointer;
    }
`)

export const VideoCall = {name: "Video Call", renderer: Playground_VideoCall}
export const VideoCallDashboard = {name: "Video Call Dashboard", renderer: Playground_VideoCallDashboard}
export const hello = {name: "hello", renderer: Playground_Hello}
export const TaskSchedulerDashboard = {name: "Task Scheduler Dashboard", renderer: Playground_TaskSchedulerTable}
export const SearchContacts = {name: "Search Contacts", renderer: Playground_SearchContacts}
export const sendSMStoContact = {name: "send SMS to contact", renderer: Playground_SendMessageToContact}
export const sendMMStoContact = {name: "send MMS to contact", renderer: Playground_SendMmsMessageWholeFlow}
export const displayOfMessages = {name: "display of messages", renderer: Playground_MessageList}
export const contacts = {name: "contacts", renderer: Playground_ContactsInfo}
// export const addCloudAssetTemplate = {name: "add cloud-asset template", renderer: Playground_AddCloudAssetTemplate}
export const showEditCloudAssets = {name: "show/edit cloud-assets", renderer: CloudAssetsTable}
export const showUnitCloudAssets = {name: "show unit cloud-assets", renderer: Playground_ShowCloudAssets}
export const VisionLabel = {name: "Vision Label", renderer: Playground_VisionLabel}
export const sendMMSToNumber = {name: "send MMS to number", renderer: Playground_SendMmsTest}
export const communityAssetsTable = {name: "community assets table", renderer: CommunityCloudAssetTable}


export class Page_Playground
    extends React.Component<{}> {

    // @ts-ignore
    ref: React.RefObject<Playground> = new React.createRef<>()

    constructor(props: {}) {
        super(props);
        this.state = {};
    }

    render() {
        const screens = this.getScreens();
        return <BugReport>
            {this.renderButtons()}
            <Playground
                selectStyle={selectStyles}
                iconClose={ICONS.triangle_up(COLORS.veryLightPink, 12)}
                iconOpen={ICONS.triangle_down(COLORS.veryLightPink, 12)}
                screens={screens}
                ref={this.ref}
            />
        </BugReport>;
    }

    getScreens(): PlaygroundScreen[] {
        return [
            VideoCall,
            VideoCallDashboard,
            hello,
            TaskSchedulerDashboard,
            SearchContacts,
            sendSMStoContact,
            sendMMStoContact,
            displayOfMessages,
            contacts,
            communityAssetsTable,
            // addCloudAssetTemplate,
            showEditCloudAssets,
            showUnitCloudAssets,
            VisionLabel,
            sendMMSToNumber
        ];
    }

    private renderButtons() {
        const screens = [
            contacts,
            SearchContacts,
            showEditCloudAssets,
            showUnitCloudAssets,
            displayOfMessages

        ];
        return <div className={`ll_h_c ${clazz}`}>
            {screens.map((screen, index) => {
                return <div
                    key={index}
                    className={buttonClass}
                    onClick={() => {
                        BrowserHistoryModule.addQueryParam("playground", screen.name)
                        return this.ref.current?.setState({selectedScreen: screen});
                    }}
                >{screen.name}</div>
            })}
        </div>
    }
}
