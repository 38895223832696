import {ObjectTS} from "@intuitionrobotics/ts-common";

export const buttonStyle = (color: "orange" | "green" | "blue" | "red" = "orange", disabled?: boolean) => {
    let dark, middle, light, shadow, textColor;
    switch (color) {
        case "blue":
            dark = "blue";
            middle = "#6699ff";
            light = "lightBlue";
            shadow = "darkBlue";
            textColor = "#fff";
            break;
        case "green":
            dark = "green";
            middle = "#00e600";
            light = "lightGreen";
            shadow = "darkGreen";
            textColor = "#fff";
            break;
        case "red":
            dark = "#990000";
            middle = "#e60000";
            light = "#ffcccc";
            shadow = "darkRed";
            textColor = "#fff";
            break;
        default:
            dark = "#ff6a00";
            middle = "orange";
            light = "yellow";
            shadow = "#c55620";
            textColor = "#000";
            break;
    }
    let btnCss = {
        color: textColor,
        padding: "5px 10px",
        marginRight: 5,
        marginLeft: 5,
        border: "1px solid transparent",
        borderRadius: 5,
        backgroundImage: `linear-gradient(to bottom right, ${dark}, ${middle},${light})`,
        boxShadow: `${shadow} 0px 0px 1px 1px`,
        cursor: "pointer"
    } as ObjectTS;

    if (disabled) {
        btnCss = {...btnCss, opacity: 0.6, cursor: "not-allowed"}
    };

    return btnCss;
};
