import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as emotion from "emotion";
import {COLORS} from "@res/colors";
import {FONTS} from "@res/fonts";

const icon__call_end = require('@res/icons/icon__end_call.svg');
const icon__audio_on = require('@res/icons/icon__audio_on.svg');
const icon__video_on = require('@res/icons/icon__video_on.svg');

const overlay = emotion.css(
	{
		boxSizing: "border-box",
		position: "absolute",
		justifyContent: "space-between",
		pointerEvents: "auto",
		top: 0,
		flexDirection: "column-reverse",
	}
);

const enablingCamera = emotion.css(
	{
		boxSizing: "border-box",
		position: "absolute",
		top: 0,
		left: 0,
		right: 0,
		padding: "6px 12px",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "16px",
		zIndex: 2,
		backgroundColor: "rgba(251, 216, 205, 0.15)",
		borderRadius: 4,
		height: 32,
		width: "fit-content",
	}
);

const controlButtons = emotion.css(
	{
		height: 112,
		boxSizing: "border-box",
		justifyContent: "center",
		backgroundColor: COLORS.midnight_blue(0.4),
		pointerEvents: "auto"
	}
);

const disabledButton = emotion.css(
	{
		position: "absolute",
		top: 0,
		left: 0,
		zIndex: 2,
		borderRadius: "50%",
		backgroundColor: "rgba(0, 0, 0, 0.4)",
	}
);

const buttonWrapper = emotion.css(
	{
		position: "relative",
		width: 64,
		height: 64,
		borderRadius: "50%"
	}
);

type State = {};

type Props = {
	remoteParticipantName?: string;
};

export class Connecting
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			shouldUnpublish: false,
		};
	}


	render = () => {
		return (
			<div className={`match_all`} style={{position: "relative"}}>
				<div className={enablingCamera}>
					{FONTS.centra_no2_book("Opening camera...", "white", 16)}
				</div>
				<div className={`ll_v_c match_all ${overlay}`}>
					{this.renderControlButtons()}
					{this.renderCallingOverlay()}
				</div>
			</div>
		);
	};

	private renderCallingOverlay = () => {
		const name = this.props.remoteParticipantName;
		return (
			<div className={`ll_v_c match_width`} style={{flex: 1, justifyContent: "center"}}>
				<div style={{zIndex: 2}}>
					{
						name && <div>
							{FONTS.centra_no2_bold(name, "white", 40)}
						</div>
					}
					<div style={{marginTop: 16}}>
						{FONTS.centra_no2_book("Starting call...", "white", 20)}
					</div>
				</div>
			</div>
		);
	};

	private renderControlButtons = () => {
		return (
			<div className={`ll_h_c match_width ${controlButtons}`}>
				<div className={buttonWrapper}>
					<div className={`match_all ${disabledButton}`}/>
					<img src={icon__audio_on}
					     className={`match_all`}
					     alt={"Mute"}
					     onClick={() => undefined}/>
				</div>
				<div className={buttonWrapper} style={{margin: "0px 56px"}}>
					<div className={`match_all ${disabledButton}`}/>
					<img src={icon__call_end}
					     className={`match_all`}
					     alt={"End call"}
					     onClick={() => undefined}/>
				</div>
				<div className={buttonWrapper}>
					<div className={`match_all ${disabledButton}`}/>
					<img src={icon__video_on}
					     className={`match_all`}
					     alt={"Camera"}
					     onClick={() => undefined}/>
				</div>
			</div>
		);
	};
}
