import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {DB_MessageStatus} from "@app/ir-q-app-common/types/message";
import {compareStatuses} from "@app/app-shared/utils";

export class StatusesModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_MessageStatus> {

	private statuses: DB_MessageStatus[] = [];

	getStatuses = () => {
		return this.statuses;
	}

	getLastStatusByMessageId(messageId: string) {
		return this.statuses.filter(status => status.messageId === messageId).reduce((toRet, status) => {
			if (!toRet.timestamp || compareStatuses(toRet.status, status.status) < 1)
				return status;

			return toRet;
		}, {} as DB_MessageStatus)
	}

	async onQueryReturned(items: DB_MessageStatus[]): Promise<void> {
		this.statuses = items;
	}
}

export const StatusesModule = new StatusesModule_Class({key: "statuses", relativeUrl: "/v1/message/status"}, "StatusesModule");
