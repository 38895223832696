import {Module} from "@intuitionrobotics/ts-common";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {
	StorageKey,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {AccountApi_ContactSendNewPassword} from "@app/app-shared/contact-login";

export const StorageKey_PWAUserName = new StorageKey<string>("storage-pwa-username");

export class ContactLoginFEModule_Class
	extends Module<{}> {

	sendNewPasswordToContact = (phoneNumber: string, callback: (success: boolean) => void) => {
		XhrHttpModule
			.createRequest<AccountApi_ContactSendNewPassword>(HttpMethod.POST, 'contact-send-new-link')
			.setRelativeUrl("/v1/account/contact-send-new-link")
			.setJsonBody({phoneNumber})
			.setOnError(() => {
				callback(false);
			})
			.execute((response) => {
				callback(true);
			});
	};
}

export const ContactLoginFEModule = new ContactLoginFEModule_Class("ContactLoginFEModule");
