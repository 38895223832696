import * as emotion from "emotion";
import {COLORS} from "@res/colors";

export const videoCallingApp = emotion.css`
		margin: auto;
    background-color: ${COLORS.grey_bg()};
    height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: calc(var(--vh, 1vh) * 100);
    min-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    text-align: center;
`;

export const pwaLabeledInputStyle = {
	fieldset: {
		appearance: "none",
		background: "transparent",
		border: "1px solid rgba(0, 0, 0, 0.3)",
		marginLeft: 0,
		marginRight: 0,
		padding: 0,
		borderRadius: 4,
		fontFamily: "centra_no2_book",
		fontSize: 16,
		// Needed for safari.
		maxHeight: "100%"
	},
	input: {
		fontFamily: "centra_no2_book",
		fontSize: 16,
		outline: "none",
		padding: 16,
		borderRadius: 4,
	},
	placeholder: {
		fontFamily: "centra_no2_book",
		fontSize: 16,
	},
	fieldsetFocusWithin: {
		outline: "none",
		// Needed for safari.
		maxHeight: "none",
		background: "white",
		opacity: 1,
		border: `1px solid ${COLORS.brand_blue()}`
	},
	legendFocusWithin: {
		padding: "0px 8px",
		marginLeft: 8,
		textAlign: "left",
		fontFamily: "centra_no2_book",
		color: "black",
		fontSize: 12,
		height: 18 - 1, // border width
	},
	inputFocusWithin: {
		paddingTop: 8,
	},
	fieldsetWrapper: {
		height: 58
	},
	error: {
		paddingLeft: 16,
		paddingTop: 4
	}
};