import {
	DB_CommunityCloudAsset,
	Key_CommunitySharedAsset
} from "@app/app-shared/cloud-asset";
import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {
	ToastModule,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	Api_CancelExhibition,
	Api_GetAllDalleCommunityAssets,
	Api_UploadSelectedCommunityAssets,
} from "@app/app-shared/cloud-asset-api";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {ThunderDispatcher} from "@intuitionrobotics/thunderstorm/app-frontend/core/thunder-dispatcher";

export interface OnGetCommunityAssets {
	__onGetCommunityAssets: (selectedItems?: DB_CommunityCloudAsset[]) => void;
}

export const dispatch_onGetCommunityAssets = new ThunderDispatcher<OnGetCommunityAssets, "__onGetCommunityAssets">(
	"__onGetCommunityAssets");

export class CommunityCloudAssetsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_CommunityCloudAsset> {
	get dueDateTimestamp() {
		return this._dueDateTimestamp;
	}

	dbAssetsWithImages = (sortFunction?: (a: DB_CommunityCloudAsset, b: DB_CommunityCloudAsset) => number) => {
		if (sortFunction)
			return this._dbAssetsWithImages?.sort(sortFunction)
		return this._dbAssetsWithImages;
	}

	get fetchUrlsOfImages(): () => void {
		return this._fetchUrlsOfImages;
	}

	private _dbAssetsWithImages: DB_CommunityCloudAsset[] | undefined;
	private _dueDateTimestamp: number | undefined;

	constructor() {
		super({key: "community-cloud-assets", relativeUrl: "/v1/cloud-asset"}, "CommunityCloudAssetsModule");
	}

	private _fetchUrlsOfImages = () => {
		XhrHttpModule
			.createRequest<Api_GetAllDalleCommunityAssets>(HttpMethod.GET, "get-all-dalle-community-cloud-assets")
			.setRelativeUrl("/v1/cloud-asset/get-all-dalle-community-cloud-assets")
			.setUrlParam("category", Key_CommunitySharedAsset)
			.setOnError(resError => {
				this.logError("Failed to get call doc status:", resError);
				return ToastModule.toastError("Could not fetch community cloud assets from server, please try again.");
			})
			.execute((response => {
				this._dbAssetsWithImages = response.assets;
				this._dueDateTimestamp = response.timestamp;
				dispatch_onGetCommunityAssets.dispatchUI([]);
			}));
	};

	public setSelectedAssets = (selectedAssets: DB_CommunityCloudAsset[], timestamp: number) => {
		XhrHttpModule
			.createRequest<Api_UploadSelectedCommunityAssets>(HttpMethod.POST, "upload-selected-community-assets")
			.setRelativeUrl("/v1/cloud-asset/upload-selected-community-assets")
			.setJsonBody({assets: selectedAssets, publishUntil: timestamp})
			.setOnError(resError => {
				this.logError("Failed to upload selected assets: ", resError);
			})
			.execute((response) => {
				this._dueDateTimestamp = timestamp;
				response?.assets.forEach((item: DB_CommunityCloudAsset) => {
					if (item && this._dbAssetsWithImages) {
						if (item.rate && item.rate === 0)
							return;

						const index = this._dbAssetsWithImages?.findIndex((_item: DB_CommunityCloudAsset) => item._id === _item._id)
						this._dbAssetsWithImages[index] = item;
					}
				});
				dispatch_onGetCommunityAssets.dispatchUI([response.assets]);
			});
	}

	public cancelExhibition = () => {
		XhrHttpModule
			.createRequest<Api_CancelExhibition>(HttpMethod.POST, "cancel-exhibition")
			.setRelativeUrl("/v1/cloud-asset/cancel-exhibition")
			.setOnError(resError => {
				this.logError("Failed to upload selected assets: ", resError);
			})
			.execute((response) => {
				this._dueDateTimestamp = undefined;
				if (this._dbAssetsWithImages)
					this._dbAssetsWithImages.forEach(item => item.rate = 0);
				dispatch_onGetCommunityAssets.dispatchUI([]);
			});
	}


}


export const CommunityCloudAssetsModule = new CommunityCloudAssetsModule_Class();
