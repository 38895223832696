import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';
import * as emotion from "emotion";
import {CSSProperties} from "react";

type State = {};

type Props = {
	disabled: boolean;
	videoTrackPublication: Video.VideoTrackPublication;
	identity: string;
	isLocal: boolean;
	shouldUnpublish: boolean
	videoTagStyle?: CSSProperties
};

const participantVideoTrackContainer = emotion.css(
	{
		boxSizing: "border-box",
		justifyContent: "center",
		height: "100%",
		width: "100%",
		// Keep the video element from overflowing.
		position: "relative"
	}
);

const participantVideo = (isLocal: boolean, disabled: boolean) => emotion.css(
	{
		boxSizing: "border-box",
		// Rotate video stream around the vertical axis.
		transform: "rotateY(180deg)",

		// Keep the video element from overflowing, filling the whole container
		position: "absolute",
		width: "100% !important",
		zIndex: 0,

		// Cover whole parent div, maintaining aspect ratio. Video may be cropped.
		objectFit: "cover",

		borderRadius: isLocal ? 10 : 0,
		visibility: isLocal && disabled ? "hidden" : "visible"
	}
);

export class VideoTrack
	extends BaseComponent<Props, State> {

	private videoRef = React.createRef<HTMLVideoElement>();

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		if (!this.videoRef?.current) {
			this.logError('Video track div refs are not set.');
			return;
		}

		const videoTrack = this.props.videoTrackPublication.track;
		if (videoTrack) {
			videoTrack.attach(this.videoRef.current);
		}

	}

	componentWillUnmount() {
		const {isLocal, shouldUnpublish} = this.props;
		// Workaround to avoid warning:
		// [RemoteVideoTrack #36: MTd46122610578cb1db69da98c44b6bbf6] Unintentionally paused:
		//     <video class="match_all css-1gb0r91" autoplay playsinline></video>
		if (this.videoRef?.current)
			this.videoRef.current.pause();

		if (!isLocal || !shouldUnpublish)
			return;

		// Stop all media tracks.
		const localVideoTrackPublication = this.props.videoTrackPublication as Video.LocalVideoTrackPublication;
		this.logInfo(`Unpublishing local video track publication ${localVideoTrackPublication.trackSid}...`);

		localVideoTrackPublication.track?.detach();
		localVideoTrackPublication.track?.stop();
		localVideoTrackPublication.unpublish();

		this.logInfo(`Done.`);
	}

	render = () => {
		const {identity, isLocal, disabled} = this.props;

		return (
			<div id={`video-track-${identity}`}
			     className={`ll_v_c ${participantVideoTrackContainer}`}>
				<video className={`match_all ${participantVideo(isLocal, disabled)}`}
				       ref={this.videoRef} muted={true} style={this.props.videoTagStyle}/>
			</div>
		);
	};
}
