import * as React from "react";
import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	AccountModule,
} from "@intuitionrobotics/user-account/frontend";
import * as emotion from "emotion";
import {isMobile} from "../videoCallUtils";
import {
	ContactLoginFEModule,
	StorageKey_PWAUserName
} from "@modules/ContactLoginFEModule";
import {COLORS} from "@res/colors";
import {OnUnauthenticatedResponse} from "../../../../index";
import {FONTS} from "@res/fonts";
import {_values} from "@intuitionrobotics/ts-common";
import {PWALoader} from "../../../../widgets/PWALoader";
import {LabeledInput} from "./LabeledInput";
import {pwaLabeledInputStyle} from "../css/videoCallingApp";

const flags = require("@res/flags.json");

const pwa_login_bg = require('@res/images/pwa_login_bg.png');
const icon__elliq_connect_logo = require('@res/icons/icon__elliq_connect_logo.svg');
const icon__login_select_down = require('@res/icons/icon__login_select_down.svg');
const validateInternationalPhoneNumber = /^(\+?)(?:[0-9]?){6,14}[0-9]$/;

const supportNumber = "(855) 888-1295";

const login = emotion.css(
	{
		boxSizing: "border-box",
		padding: 16,
	}
);

const header = (step: "phone" | "password") => emotion.css(
	{
		boxSizing: "border-box",
		padding: "0px 16px",
		marginBottom: step === "phone" ? 40 : 24
	}
);

const body = emotion.css(
	{
		boxSizing: "border-box",
		padding: "0px 8px"
	}
);

const countryCodeSelect = emotion.css(
	{
		fontFamily: "centra_no2_book",
		fontSize: 16,
		boxSizing: "border-box",
		width: "100%",
		height: "100%",
		outline: "none",
		borderRadius: 4,
		background: "transparent",
		textAlign: "center",
		paddingRight: 16,
		color: "black",
		border: "1px solid rgba(0, 0, 0, 0.3)",

		appearance: "none",
		backgroundImage: `url(${icon__login_select_down})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: 24,
		backgroundPosition: "95% 50%",
	}
);

const button = emotion.css(
	{
		boxSizing: "border-box",
		backgroundColor: "white",
		border: "0px solid",
		height: 56,
		justifyContent: "center",
		borderRadius: 8,
	}
);

const phoneError = emotion.css(
	{
		boxSizing: "border-box",
		height: 88,
		alignSelf: "center",
		backgroundColor: "rgba(252, 240, 237, 0.8)",
		borderRadius: 4,
		backdropFilter: "blur(4px)",
		padding: 8,
		marginTop: 8,
		justifyContent: "space-around"
	}
);

const codeError = emotion.css(
	{
		boxSizing: "border-box",
		alignSelf: "center",
		backgroundColor: "rgba(252, 240, 237, 0.8)",
		borderRadius: 4,
		backdropFilter: "blur(4px)",
		padding: 8,
		marginTop: 8,
	}
);

const support = emotion.css(
	{
		boxSizing: "border-box",
		width: 264,
		height: 40,
		marginTop: "auto",
		justifyContent: "center",
		background: "rgba(139, 139, 139, 0.2)",
		borderRadius: 30,
		backdropFilter: "blur(34px)"
	}
);

type Props = {};

type State = {
	phoneNumber?: string;
	password?: string;
	name?: string;
	errorSendingCode: boolean;
	invalidCode: boolean;
	passwordHasSentToContact: boolean;
	showLoader: boolean;
	selectedCountryCode: string;
};

export class Login
	extends BaseComponent<Props, State>
	implements OnUnauthenticatedResponse {

	private readonly countryCodes: string[] = [];

	constructor(props: Props) {
		super(props);

		this.countryCodes = Array.from(
			new Set(_values(flags).map(flag => flag.dialCode))
		).sort();

		this.state = {
			showLoader: false,
			errorSendingCode: false,
			invalidCode: false,
			passwordHasSentToContact: false,
			selectedCountryCode: this.countryCodes[0]
		};
	}

	onUnauthenticatedResponse(): void {
		this.setState({errorSendingCode: false, showLoader: false, password: undefined, invalidCode: true});
	}

	private getCompletePhoneNumber = (countryCode: string, phoneNumber: string = "") => {
		return `${countryCode}${phoneNumber}`;
	};

	sendNewPasswordToContact = () => {
		const {phoneNumber, name, showLoader, selectedCountryCode} = this.state;
		if (!this.isPhoneNumberValid() || !selectedCountryCode || !phoneNumber || !name?.trim() || showLoader) {
			return;
		}

		this.setState({showLoader: true, errorSendingCode: false});
		const trimmedName = name?.trim();
		StorageKey_PWAUserName.set(trimmedName.charAt(0).toUpperCase() + trimmedName.slice(1));
		const phoneNumberWithCode = this.getCompletePhoneNumber(selectedCountryCode, phoneNumber);
		ContactLoginFEModule.sendNewPasswordToContact(phoneNumberWithCode, (success: boolean) => {
			this.setState({passwordHasSentToContact: success, showLoader: false, errorSendingCode: !success});
		});
	};

	contactLogin = () => {
		const {phoneNumber, selectedCountryCode, password} = this.state;
		if (!phoneNumber || !selectedCountryCode || !password || !this.isPhoneNumberValid() || !this.isPasswordValid())
			return;

		const phoneNumberWithCode = this.getCompletePhoneNumber(selectedCountryCode, phoneNumber);
		this.setState({showLoader: true, invalidCode: false});
		const request = {phoneNumber: phoneNumberWithCode, email: '', password};
		AccountModule.login(request);
	};

	isPhoneNumberValid = () => {
		const {selectedCountryCode, phoneNumber} = this.state;
		if (!phoneNumber || !selectedCountryCode)
			return false;

		const phoneNumberWithCode = this.getCompletePhoneNumber(selectedCountryCode, phoneNumber);
		return validateInternationalPhoneNumber.test(phoneNumberWithCode);
	};

	isPasswordValid = () => {
		return this.state.password;
	};

	render() {
		return <>
			{this.state.showLoader && <PWALoader message={""} overlay={true}/>}
			{isMobile() ? this.renderLoginMobile() : this.renderDesktopLogin()}
		</>;
	}

	private renderLoginMobile = () => {
		return (
			<div className={`ll_v_c match_all ${login}`} style={{
				background: `no-repeat top/cover url(${pwa_login_bg})`,
			}}>
				{this.renderHeader()}
				{this.renderBody()}
				{this.renderSupport()}
			</div>
		);
	};

	private renderHeader = () => {
		return (
			<div className={`ll_v_l match_width ${header(!this.state.passwordHasSentToContact ? "phone" : "password")}`}>
				<div>
					{
						FONTS.centra_no2_book("Welcome to", "black", 16,)
					}
				</div>
				<div>
					<img src={icon__elliq_connect_logo} alt={`ElliQ Connect`}/>
				</div>
			</div>
		);
	};

	private renderDesktopLogin = () => {
		return <div className={`ll_v_c`}>
			<div className={`ll_h_c`}>
				<div className={`ll_v_c`}>
					{this.renderBody()}
				</div>
			</div>
		</div>;
	};

	private renderBody = () => {
		return (
			<div className={`ll_v_l match_width ${body}`}>
				{
					this.state.passwordHasSentToContact ?
						this.renderPasswordInput() :
						this.renderNameAndPhoneInput()
				}
			</div>
		);
	};

	private renderNameAndPhoneInput = () => {
		return (
			<div className={`ll_v_l match_width`} style={{boxSizing: "border-box"}}>
				<div>
					{FONTS.centra_no2_book("Let's connect with your elder's ElliQ", "black", 16)}
				</div>
				<div className={`match_width`} style={{marginBottom: 13}}>
					<LabeledInput
						autocomplete={"off"}
						inputType={"text"}
						inputMode={"text"}
						label={"Your Name"}
						placeholder={"Your Name"}
						onChange={(e) => {
							if (this.state.showLoader)
								return;

							this.setState({name: e.target.value});
						}}
						value={this.state.name || ""}
						styles={{
							...pwaLabeledInputStyle,
							fieldsetWrapper: {
								...pwaLabeledInputStyle.fieldsetWrapper,
								marginTop: 13
							}
						}}/>
				</div>
				<div className={`ll_h_r match_width`} style={{height: "fit-content"}}>
					<div className={`match_height`} style={{
						boxSizing: "border-box",
						width: 77,
						marginRight: 8,
						borderRadius: 4,
						marginBottom: this.state.errorSendingCode ? 0 : 50,
						// Same as sibling's fieldset...
						height: 55 // TODO: Find a way to make dynamic.
					}}>
						<select
							id="country-codes"
							className={`match_all ll_h_c ${countryCodeSelect}`}
							value={this.state.selectedCountryCode}
							onChange={e => {
								const selectedCountryCode = e.target.value;
								this.setState({selectedCountryCode});
							}}>
							{
								this.countryCodes.map(
									code => <option
										style={{fontFamily: "centra_no2_book", color: "black"}}
										key={code}>
										{code}
									</option>
								)
							}
						</select>
					</div>
					<LabeledInput
						autocomplete={"off"}
						inputType={"text"}
						inputMode={"tel"}
						label={"Your Phone Number"}
						placeholder={"Your Phone Number"}
						onChange={(e) => {
							if (this.state.showLoader)
								return;

							this.setState({phoneNumber: (e.target.value?.trim() || ""), errorSendingCode: false});
						}}
						error={
							this.state.errorSendingCode ?
								undefined :
								!!(this.state.phoneNumber?.trim() && !this.isPhoneNumberValid())
						}
						errorMessage={"Please enter a valid phone number"}
						value={this.state.phoneNumber || ""}
						styles={{
							...pwaLabeledInputStyle,
							root: {
								marginBottom: this.state.errorSendingCode ? 0 : 50,
								flex: 1
							}
						}}/>
				</div>
				{
					this.state.errorSendingCode && this.renderPhoneError()
				}
				{this.renderNextButton()}
			</div>
		);
	};

	private renderPasswordInput = () => {
		const {phoneNumber, selectedCountryCode} = this.state;
		const completePhoneNumber = this.getCompletePhoneNumber(selectedCountryCode, phoneNumber);
		return (
			<div className={`ll_v_l match_width`} style={{boxSizing: "border-box"}}>
				<div className={`match_width`} style={{
					boxSizing: "border-box",
					backgroundColor: "rgba(244, 242, 242, 1)",
					padding: "12px 16px",
				}}>
					{
						FONTS.centra_no2_book(
							`We sent a verification code ${completePhoneNumber ? `to ${completePhoneNumber}` : ""}`, COLORS.brand_blue(), 12
						)
					}
				</div>
				<div className={`ll_v_l match_width`} style={{boxSizing: "border-box"}}>
					<LabeledInput
						autocomplete={"off"}
						inputType={"text"}
						inputMode={"numeric"}
						label={"6-digit code"}
						placeholder={"6-digit code"}
						onChange={(e) => {
							if (this.state.showLoader)
								return;

							e.persist();
							const trimmedValue = e.target.value?.trim() || "";
							this.setState({password: trimmedValue}, () => {
								if (e.target.value.length === 6)
									this.contactLogin();
							});
						}}
						value={this.state.password || ""}
						styles={{
							...pwaLabeledInputStyle,
							fieldsetWrapper: {
								...pwaLabeledInputStyle.fieldsetWrapper,
								marginTop: 29
							}
						}}/>
				</div>
				{
					this.state.invalidCode && this.renderCodeError()
				}
				<div className={`match_width`}
				     style={{
					     marginTop: 64,
					     boxSizing: "border-box",
					     padding: 8,
				     }}
				     onClick={this.sendNewPasswordToContact}>
					{
						FONTS.centra_no2_medium("I didn’t get a code, send another", COLORS.brand_blue(), 16)
					}
				</div>
			</div>
		);
	};

	private renderNextButton = () => {
		return (
			<div className={`match_width ll_v_c`}>
				<div className={`match_width ll_v_c ${button}`}
				     onClick={this.sendNewPasswordToContact}
				     style={{
					     boxShadow: this.state.name && this.isPhoneNumberValid() ?
						     "2px 2px 8px rgba(0, 0, 0, 0.15)" :
						     undefined,
					     marginTop: this.state.errorSendingCode ? 8 : 0
				     }}>
					<span style={{
						opacity: this.state.name && this.isPhoneNumberValid() ? 1 : 0.5
					}}>
						{
							FONTS.centra_no2_medium("Next", COLORS.brand_blue(), 20)
						}
					</span>
				</div>
				<div style={{marginTop: 8}}>
					{
						FONTS.centra_no2_book("You will get a code via SMS", COLORS.brand_blue(), 14)
					}
				</div>
			</div>
		);
	};

	private renderPhoneError = () => {
		return (
			<div className={`ll_v_l match_width ${phoneError}`}>
				<div>
					{
						FONTS.centra_no2_book("This is not a valid ElliQ Connect phone number.", COLORS.error_red(), 14)
					}
				</div>
				<div>
					{
						FONTS.centra_no2_book("Is this number saved on your elder’s ElliQ?", COLORS.error_red(), 14)
					}
				</div>
				<div>
					{
						FONTS.centra_no2_book(`For more help, call support: ${supportNumber}`, COLORS.error_red(), 14)
					}
				</div>
			</div>
		);
	};

	private renderCodeError = () => {
		return (
			<div className={`ll_v_c match_width ${codeError}`} style={{justifyContent: "space-around"}}>
				<div>
					{FONTS.centra_no2_book("Invalid code. Please try again.", COLORS.error_red(), 14)}
				</div>
			</div>
		);
	};

	private renderSupport = () => {
		return (
			<div className={`ll_v_c ${support}`}>
				{
					FONTS.centra_no2_book(`For support call: ${supportNumber}`, COLORS.white(), 16,)
				}
			</div>
		);
	};

}
