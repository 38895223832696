import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';
import * as emotion from "emotion";
import {
	Participant,
	ParticipantType
} from "./Participant";
import {FONTS} from "@res/fonts";

const icon__flip_camera = require("@res/icons/icon__flip_camera.svg");

type State = {
	facingMode: "user" | "environment";
};

type Props = {
	videoPaused: boolean;
	videoDisabled: boolean;
	localParticipant: Video.LocalParticipant;
	onCameraSwitchClicked: (facingMode: "user" | "environment") => Promise<void>;
};

const localParticipantContainer = emotion.css(
	{
		boxSizing: "border-box",
		height: 187,
		width: 130,

		position: "relative"
	}
);

const localVideoOverlay = emotion.css(
	{
		boxSizing: "border-box",
		position: "absolute",
		bottom: 0,
		height: 50,
		zIndex: 1,
		background: "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.49) 100%)",
		borderRadius: 10
	}
);

const youContainer = emotion.css(
	{
		position: "absolute",
		zIndex: 1,
		bottom: 4,
		left: 8,
	}
);

const flipCameraContainer = emotion.css(
	{
		position: "absolute",
		width: 24,
		height: 24,
		zIndex: 1,
		bottom: 4,
		right: 8,
		pointerEvents: "auto"
	}
);

export class LocalParticipant
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			facingMode: "user"
		};
	}

	render = () => {
		const {localParticipant, videoPaused, videoDisabled, onCameraSwitchClicked} = this.props;
		const {facingMode} = this.state;

		return (
			<div id={"local-participant-container"} className={localParticipantContainer}>
				{
					!videoPaused && !videoDisabled &&
					<div className={`ll_h_r match_width ${localVideoOverlay}`}>
						<div className={youContainer}>
							{FONTS.centra_no2_book("You", "white", 20)}
						</div>
						<div className={flipCameraContainer}>
							<img src={icon__flip_camera}
							     alt={"Flip camera"}
							     onClick={async () => {
								     const newFacingMode = facingMode === "user" ? "environment" : "user";
								     await onCameraSwitchClicked(newFacingMode);
								     this.setState({facingMode: newFacingMode});
							     }}/>
						</div>
					</div>
				}
				<Participant
					participant={localParticipant}
					isLocal={true}
					videoDisabled={videoDisabled}
					videoPaused={videoPaused}
					key={localParticipant.identity}
					participantType={ParticipantType.Web}/>
			</div>
		);
	};
}
