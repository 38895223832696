import * as React from "react";
import { StringMap } from "@intuitionrobotics/ts-common";
import { CustomFieldRow, newCustomFieldRow } from "./CustomFieldRow";
import * as cfCss from "./css/customFieldCss";

const icon__add = require('@res/images/icon_add_blue.svg');
const icon__collapse = require('@res/images/collapse.png');
const icon__expand = require('@res/images/expand.png');

type State = {
	addRowOpen: boolean,
	collapse: boolean
}

type Props = {
	customField: StringMap,
	onCustomFieldUpdate: (customFields: StringMap) => void,
	disableEdit?: boolean,
	displayOnlyKey?: boolean
}

export class CustomField
	extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			addRowOpen: false,
			collapse: true
		}
	}

	renderAddRow() {
		const {disableEdit} = this.props;
		const {addRowOpen} = this.state;

		if (disableEdit)
			return null;

		if (addRowOpen) {
			return <CustomFieldRow displayOnlyKey={this.props.displayOnlyKey} onRowDelete={() =>{}} onRowUpdate={(customKey, newRow: newCustomFieldRow) => {this.handleCustomFieldUpdate(customKey, newRow)}} customKey={''}
			                       onRowClose={() => {this.setState({addRowOpen: false})}} customFieldRow={{'':''}} emptyRowMode={true} />;
		}
		return <div style={{cursor: 'pointer'}}>
			<img alt={'add'} onClick={() => {this.setState({addRowOpen: true, collapse: false})}} src={icon__add}/>
		</div>;
	}

	renderCollapse = () => {
		const icon = this.state.collapse ? icon__collapse: icon__expand;
		const width = this.state.collapse ? "15px": "10px";
		const right = this.state.collapse ? "-10px": "-8px";
		return <div style={{cursor: 'pointer', position: "absolute", right: right, top: "-8px"}}>
			<img alt={'collapse'} style={{width}} onClick={() => {this.setState({collapse: !this.state.collapse})}} src={icon}/>
			{

			}
		</div>;
	}

	handleCustomFieldUpdate(customKey: string, newRow: newCustomFieldRow) {
		const {customField, onCustomFieldUpdate} = this.props;
		if (!newRow.newKey || !newRow.newTextValue)
			return;

		if (newRow.newKey === customKey && newRow.newTextValue === customField[customKey])
			return;

		delete customField[customKey];
		delete customField[newRow.newKey];

		customField[newRow.newKey] = newRow.newTextValue;
		onCustomFieldUpdate(this.props.customField);
	}

	handleCustomRowDelete(customKey: string) {
		const {customField, onCustomFieldUpdate} = this.props;
		delete customField[customKey];
		onCustomFieldUpdate(this.props.customField);
	}

	render() {
		const {customField, disableEdit} = this.props;
		const customFieldTable = Object.keys(customField).map((key: string) => {
			return <CustomFieldRow displayOnlyKey={this.props.displayOnlyKey} disableEdit={disableEdit} onRowDelete={(customKey) => {this.handleCustomRowDelete(customKey)}} key={`${key}--${customField[key]}`} onRowUpdate={(customKey, newRow: newCustomFieldRow) => {this.handleCustomFieldUpdate(customKey, newRow)}} customKey={key} customFieldRow={{[key]: customField[key]}}/>;
		});

		return <div className={cfCss.customFieldContainer}>
			<div style={{position: "relative"}}>
				{this.renderAddRow()}
				{this.renderCollapse()}
			</div>
			{!this.state.collapse && customFieldTable}
		</div>;
	}
}
