import {DB_ImageConfig, ImageConfigKeysMapping, DefaultImageConfig} from "@app/app-shared/image-config";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import React from "react";
import CustomTableRow, {NewCustomTableRow} from "../ui/CustomTableRow";
import {buttonStyle} from "@styles/buttonStyle";
import {ImageConfigModule, OnImageConfigUpdated} from "@modules/ImageConfigModule";
import {DataEnvsModule} from "@modules/DataEnvsModule";

type Props = {
    imageConfig: DB_ImageConfig[]
}

type State = {
    isEditing: boolean;
    showLoader: boolean;
    editingConfig: Map<string, DB_ImageConfig>;
    editedUserGroups: Set<string>;
    errorOnSumValidate?: boolean;
};


class ImageConfigTable extends BaseComponent<Props, State> implements OnImageConfigUpdated {

    constructor(props: Props) {
        super(props);
        const editingConfig = new Map<string, DB_ImageConfig>();
        this.props.imageConfig.forEach(config => {
            editingConfig.set(config.userGroup, config);
        });
        this.state = {
            isEditing: false,
            showLoader: false,
            editingConfig: editingConfig,
            editedUserGroups: new Set<string>()
        }
    }

    componentDidMount(): void {
        if (!DataEnvsModule.getItems().length)
            DataEnvsModule.query()

        this.setState((prevState) => {
            const currState = {...prevState};
            currState.editingConfig = this.createAllEnvsEditingConfig();
            return currState;
        })
    }

    initState = () => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = false;
            currState.editingConfig = this.createAllEnvsEditingConfig();
            currState.editedUserGroups = new Set<string>();
            return currState;
        })
    }

    createAllEnvsEditingConfig(): Map<string, DB_ImageConfig> {
        const envs = DataEnvsModule.getItems();
        const imageConfig = ImageConfigModule.getImageConfig();

        return envs.reduce((map, envItem) => {
            let imageConfigItem = imageConfig.find((imageConfigItem) => imageConfigItem.userGroup === envItem.label);

            if (imageConfigItem) {
                map.set(envItem.label, {...imageConfigItem});
            } else {
                imageConfigItem = {...DefaultImageConfig};
                imageConfigItem.userGroup = envItem.label;
                map.set(envItem.label, imageConfigItem);
            }

            return map;
        }, new Map<string, DB_ImageConfig>());
    }

    __onImageConfigUpdated = () => {
        this.initState();
    }

    handleRowUpdate = (keyOfRow: string, updatedRow: NewCustomTableRow) => {
        if (!updatedRow.userGroup)
            return;
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = true;
            if (prevState.editingConfig.has(keyOfRow)) {
                const existingItem = prevState.editingConfig.get(keyOfRow);
                if (existingItem) {
                    prevState.editingConfig.set(keyOfRow, updatedRow as DB_ImageConfig);
                    prevState.editedUserGroups.add(keyOfRow);
                }
            }
            const error = this.validateRow(keyOfRow);
            console.log(error)
            return currState;
        })

    }

    validateRow = (keyOfRow: string): string => {
        const row = this.state.editingConfig.get(keyOfRow);
        if (!row)
            return "";
        if (!row.userGroup)
            return "Missing user group";
        let sum = 0;
        for (const key in row) {
            if (row.hasOwnProperty(key)) {
                const value = row[key as keyof DB_ImageConfig];
                if (key === 'userGroup') {
                    if (typeof value !== 'string' || value.trim() === '') {
                        return "Missing user group"
                    } else continue;
                }
                if (isNaN(Number(value))) {
                    return "Only numbers";
                }
                sum += Number(value);
                if (sum > 100) {
                    return "The sum is higher than 100%"
                }
            }
        }
        if (sum < 100) {
            return "The sum is less than 100%"
        }
        return "";
    }

    cancelChanges = () => {
        this.initState();
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const editedConfigs: DB_ImageConfig[] = Array.from(this.state.editingConfig.entries())
            .filter(([key]) => this.state.editedUserGroups.has(key))
            .map(([, value]) => value);
        ImageConfigModule.updateImageConfig(editedConfigs);
    }

    render() {
        const columnAccessors = ["userGroup", ...Object.keys(ImageConfigKeysMapping)]
        const disableSaveBtn = this.state.errorOnSumValidate;
        return (
            <div>
                <div>
                    <table className="table table-hover">
                        <tbody>
                        <tr>
                            <th></th>
                            {Object.keys(ImageConfigKeysMapping).map(key => (
                                <th key={key}>{ImageConfigKeysMapping[key as keyof typeof ImageConfigKeysMapping]}</th>
                            ))}
                        </tr>

                        {Array.from(this.state.editingConfig.values()).map(row => (
                            <CustomTableRow
                                key={row.userGroup}
                                keyOfRow={row.userGroup}
                                dataOfRow={row}
                                columnAccessors={columnAccessors}
                                onRowUpdate={this.handleRowUpdate}
                            />
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="cloud-asset-actions">
                    {!this.state.isEditing ?
                        <div></div>
                        :
                        <>
                            <div style={buttonStyle("green", disableSaveBtn)} onClick={(event) => {
                                if (disableSaveBtn)
                                    return;

                                this.handleSubmit(event);
                            }}>
                                Save
                            </div>
                            <div style={buttonStyle("red")} onClick={() => {
                                this.cancelChanges();
                            }}>
                                Cancel
                            </div>
                        </>
                    }
                </div>
            </div>
        );
    }

}

export default ImageConfigTable;