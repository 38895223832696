import {BaseComponent,} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';
import * as emotion from "emotion";
import {
	Participant,
	ParticipantType
} from "./Participant";
import {
	isSomParticipant,
	isTabletParticipant
} from "../videoCallUtils";
import {FONTS} from "@res/fonts";

type State = {};

type Props = {
	remoteParticipants: Video.RemoteParticipant[];
	videoStopped: boolean
};

const remoteParticipantsContainer = emotion.css(
	{
		boxSizing: "border-box",
	}
);

export class RemoteParticipants
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	render = () => {
		const {remoteParticipants} = this.props;

		return (
			<div id={"remote-participants-container"} className={`match_all ${remoteParticipantsContainer}`}>
				{
					remoteParticipants.length === 0 ?
						this.renderAlone() :
						remoteParticipants.map(
							participant => this.renderRemoteParticipant(participant)
						)
				}
			</div>
		);
	};

	private renderRemoteParticipant = (participant: Video.RemoteParticipant) => {
		let participantType: ParticipantType;
		if (isSomParticipant(participant))
			participantType = ParticipantType.Som;
		else if (isTabletParticipant(participant))
			participantType = ParticipantType.Tablet;
		else
			participantType = ParticipantType.Web;

		return <Participant
			participant={participant}
			videoDisabled={this.props.videoStopped}
			videoPaused={this.props.videoStopped}
			isLocal={false}
			participantType={participantType}
			key={participant.identity}/>;
	};

	private renderAlone = () => {
		return <div className={`match_all ll_v_c`} style={{justifyContent: "center"}}>
			<div>{FONTS.centra_no2_book_italic("You are currently alone in the video call", "white", 20)}</div>
			<div>{FONTS.centra_no2_book_italic("Please wait until someone joins", "white", 20)}</div>
		</div>;
	};

}
