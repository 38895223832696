import * as React from "react";
import {BaseComponent, ToastModule, TS_Table, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import DragnDrop, {_FilesType} from "../../ui/file-components/Drag&Drop";
import {plusSign} from "../../ui/file-components/css/file-uploader";
import {Loader} from "../../../../widgets/Loader";
import {FileStatus, OnFileStatusChanged} from "@intuitionrobotics/file-upload/shared/modules/BaseUploaderModule";
import {UploaderModule} from "@intuitionrobotics/file-upload/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm";
import {Api_LabelVision} from "@app/app-shared/vision";
import {generateHex} from "@intuitionrobotics/ts-common";
import {css} from "emotion";
import {ComputerVisionAnnotation} from "@app/ir-q-app-common/types/computer-vision-tags";

type State = {
    fileName: string
    file?: File
    fileId?: string
    fileSource: string
    loading: boolean
    mimeType: string
    labels: {
        labelAnnotations: ComputerVisionAnnotation[]
    }
}

export class Playground_VisionLabel
    extends BaseComponent<{}, State> implements OnFileStatusChanged {

    constructor(props: {}) {
        super(props);
        this.state = {
            fileName: "",
            fileSource: "",
            loading: false,
            mimeType: "",
            labels: {labelAnnotations: []}
        }
    }

    private inputRef = React.createRef<HTMLInputElement>();

    __onFileStatusChanged = (feId?: string) => {
        if (!feId || this.state.fileId !== feId)
            return;

        const fileInfo = UploaderModule.getFullFileInfo(feId);
        if (!fileInfo?.tempDoc || fileInfo.status !== FileStatus.PostProcessing)
            return;

        const bucketPointer = {
            path: fileInfo.tempDoc.path,
            bucketName: fileInfo.tempDoc.bucketName
        };
        this.setState({loading: true})
        XhrHttpModule
            .createRequest<Api_LabelVision>(HttpMethod.POST, 'label')
            .setRelativeUrl("/v1/vision/label")
            .setJsonBody({bucketPointer})
            .setOnError(() => {
                this.setState({loading: false})
            })
            .execute((response) => {
                console.log(response)
                this.setState({labels: response, loading: false})
            })
    }

    loading = () => {
        return this.state.loading ? <Loader/> : null;
    };

    private validateFiles = (files: File[]): _FilesType => {
        const failReply = {
            accepted: [] as File[],
            rejected: files,
            uploaded: [] as File[]
        };
        if (files.length === 0) {
            ToastModule.toastError("no files");
            return failReply;
        }
        if (files.length > 1) {
            ToastModule.toastError("more than one file");
            return failReply;
        }

        return {
            accepted: files,
            rejected: [] as File[],
            uploaded: [] as File[]
        };
    };

    private onDragAndDrop = (files: _FilesType) => {
        const file: File = files.accepted[0];
        this.setState({file, loading: true}, () => {
            const [fileId] = UploaderModule.upload([file], 'default')
            this.getFileAsUrl(file, fileId.feId)
        })
    };

    private getFileAsUrl(file: File, fileId: string): void {
        const reader = new FileReader();
        reader.onload = (e) => {
            const result = e.target?.result as string;
            //@ts-ignore
            if (!e.target || !result)
                return ToastModule.toastError('Failed to upload the file');

            //@ts-ignore
            const resultMimeType = result && typeof result.match("(?<=:).*(?=;)") && result.match("(?<=:).*(?=;)")[0] || ""
            this.setState({
                fileSource: result,
                fileName: file.name,
                file: file,
                fileId,
                mimeType: resultMimeType
            })
        }

        reader.onerror = (e) => {
            this.logError('Failed to read selected file', e);
            ToastModule.toastError('Failed to read selected file');
            return;
        };
        reader.readAsDataURL(file);
    }

    private onSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!e.target.files)
            return;

        const files: _FilesType = this.validateFiles(Object.values(e.target.files));
        this.onDragAndDrop(files);
    };

    private renderDragAndDrop = () => <DragnDrop
        validate={this.validateFiles}
        error={undefined}
        onChange={this.onDragAndDrop}
        height={'unset'}
    >
        <div
            onClick={() => this.inputRef.current?.click()}
            className={'ll_h_c match_height'}
            style={{justifyContent: 'center'}}
        >
            {this.state.fileSource ? <img style={{maxHeight: 200}} src={this.state.fileSource} alt={'My image'}/> :
                <div className={plusSign}>
                    <input id="fileInput" type="file" ref={this.inputRef} hidden={true}
                           onChange={this.onSelect}/>
                </div>}
        </div>
    </DragnDrop>;

    render() {
        return <div style={{width: 400}}>
            <h3>Label</h3>
            {this.renderUploadImage()}
            {this.renderLabels()}
            {this.loading()}
        </div>
    }

    private renderLabels = () => {
        const labelAnnotations = this.state.labels?.labelAnnotations;
        if (!labelAnnotations)
            return;

        const borderSolid = css({
            border: '1px solid black',
            borderCollapse: 'collapse'
        });
        return <TS_Table<ComputerVisionAnnotation>
            className={borderSolid}
            rows={labelAnnotations}
            id={generateHex(8)}
            header={[
                "description",
                "score",
                "topicality",
                "confidence"
            ]}
            tr={{
                className: borderSolid
            }}
            headerRenderer={((columnKey) => columnKey)}
            cellRenderer={((cellValue) => {
                if (typeof cellValue === "number")
                    return Math.floor(cellValue * 100) / 100
                return cellValue;
            })}
        />
    };

    private renderUploadImage() {
        return <>{this.renderDragAndDrop()}
            <div style={{marginBottom: "5px"}}>
                {this.state.file?.name}
            </div>
            <label>mimeType: </label>
            <label>{this.state.mimeType}</label>
        </>;
    }
}
