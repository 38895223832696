import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';
import {VideoTrack} from "./VideoTrack";
import {AudioTrack} from "./AudioTrack";

type State = {};

export enum ParticipantType {
	Web    = "web",
	Som    = "som",
	Tablet = "tablet"
}

type Props = {
	participant: Video.Participant;
	isLocal: boolean;
	participantType: ParticipantType;
	videoDisabled: boolean;
	videoPaused: boolean;
}

export class Participant
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	componentWillUnmount() {
		if (!this.props.isLocal)
			return;

		// Unpublish all media tracks.
		const participant = this.props.participant as Video.LocalParticipant;
		participant.tracks.forEach((publication: Video.LocalTrackPublication) => {
			this.logInfo(`Unpublishing ${publication.kind} track publication ${publication.trackSid}.`);
			if (publication.track.kind !== "data") {
				publication.track.detach();
				publication.track.stop();
				publication.unpublish();
				participant.unpublishTrack(publication.track);
			}
		});
		this.logInfo("Done.");
	}

	private publishesVideoTrack = () => this.props.participantType === "web" || this.props.participantType === "tablet";

	private publishesAudioTrack = () => this.props.participantType === "web" || this.props.participantType === "som";

	/*
		When unpublishing and republishing media tracks quickly (e.g. visibility hidden / visible due to app entering background and then foreground),
		there are issues with the media playing (e.g. video not working). A possible solution could be to make sure to detach the track properly
		from the media tag or remove the media tag completely.
		TODO: Make sure that camera or mic do not stay open.
	*/
	render = () => {
		const {participant} = this.props;
		const identity = participant.identity;

		const publishesVideoTrack = this.publishesVideoTrack();
		const publishesAudioTrack = this.publishesAudioTrack();

		const audioOnly = publishesAudioTrack && !publishesVideoTrack;

		return <div id={`tracks-container-${identity}`} className={`${audioOnly ? '' : "match_all"} ll_v_c`}>
			{
				publishesVideoTrack && (
					this.props.isLocal ?
						this.renderLocalVideoTrack() :
						this.renderRemoteVideoTrack()
				)
			}
			{
				publishesAudioTrack && !this.props.isLocal && this.renderAudioTrack()
			}
		</div>;
	};

	private renderVideoTrack = () => {
		const {participant, isLocal, videoDisabled} = this.props;
		const identity = participant.identity;
		const videoTrackPublication = Array.from(participant.videoTracks.values())[0];

		return videoTrackPublication && videoTrackPublication.track &&
			<VideoTrack
				disabled={videoDisabled}
				key={videoTrackPublication.trackSid}
				videoTrackPublication={videoTrackPublication}
				identity={identity}
				isLocal={isLocal}
				shouldUnpublish={false}
			/>;
	};

	private renderLocalVideoTrack = this.renderVideoTrack;

	private renderRemoteVideoTrack = () => {
		if (this.props.videoPaused)
			return <div id={`paused-video-track-${this.props.participant.identity}`}
			            className={`ll_v_c match_all`}>
				<div style={{margin: "auto"}}>
					Please wait until participant enables their camera
				</div>
			</div>;

		return this.renderVideoTrack();
	};

	private renderAudioTrack = () => {
		const {participant, isLocal} = this.props;
		const identity = participant.identity;
		const audioTrackPublication = Array.from(participant.audioTracks.values())[0];

		return audioTrackPublication && audioTrackPublication.track &&
			<AudioTrack
				key={audioTrackPublication.trackSid}
				audioTrackPublication={audioTrackPublication}
				identity={identity}
				isLocal={isLocal}/>;
	};

}
