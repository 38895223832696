import * as React from 'react';
import {css} from "emotion";
import {_keys} from "@intuitionrobotics/ts-common";

export type NodeProps = {
	// index: number
	node: object | string
	path: string
	name: string
	expanded: boolean
	expandToggler: (e: React.MouseEvent) => void
	onClick: (e: React.MouseEvent) => void
	onDoubleClick: (e: React.MouseEvent) => void
	filter: <T extends object>(obj: T, key: keyof T) => any
	parentArray?: boolean
};

type Props = {
	id: string
	root: object
	hideRootElement?: boolean
	onNodeClicked?: (path: string, id: string) => void;
	onNodeDoubleClicked?: (path: string, id: string) => void;
	childrenCss?: string;
	renderer?: (props: NodeProps) => any;
	childFilter?: <T extends object>(obj: T, key: keyof T) => any;
	indentPx?: number;
	nodesState?: TreeNodeState;
}

export type TreeNodeState = { [path: string]: boolean };
type State = {
	expanded: TreeNodeState
}

export const itemHover = css({':hover': {color: "red"}});
export const itemPicked = css({':focus': {color: "blue"}});

export const DefaultTreeRenderer = (props: NodeProps) => {
	function renderCollapse() {
		let toDisplay = "";
		if (typeof props.node !== "object")
			toDisplay = "";
		else if (Object.keys(props.node).length === 0)
			toDisplay = "";
		else if (_keys(props.node).filter((key) => props.filter((props.node as object), key)).length === 0)
			toDisplay = "";
		else if (props.expanded)
			toDisplay = "-";
		else
			toDisplay = "+";

		return <div className={`${itemHover} clickable`} id={props.path} onClick={props.expandToggler} style={{width: "15px"}}>{toDisplay}</div>
	}

	let label;
	if (typeof props.node !== "object")
		label = ` : ${props.node}`;
	else if (Object.keys(props.node).length === 0)
		label = " : {}";
	else
		label = "";
	return (<div className="ll_h_c">
		{renderCollapse()}
		<div tabIndex={1} className={`${itemHover} ${itemPicked} ${props.onClick || props.onDoubleClick ? 'clickable' : ''}`} id={props.path}
		     onClick={props.onClick} onDoubleClick={props.onDoubleClick}>{props.name || "root"} {label} </div>
	</div>);
};

export class Tree
	extends React.Component<Props, State> {

	static getDerivedStateFromProps(props: Props, prevState: State) {
		const state = props.nodesState;
		if (state && !state["/"])
			state["/"] = true;

		return {expanded: state || prevState.expanded};
	}

	private static DefaultNodeState: TreeNodeState = {"/": true};
	static defaultProps: Partial<Props> = {
		indentPx: 20,
	};

	constructor(props: Props) {
		super(props);
		const state = this.props.nodesState;
		if (state && !state["/"])
			state["/"] = true;

		this.state = {expanded: state || Tree.DefaultNodeState};
	}

	// expandStart = () => {
	// 	if (this.props.startCollapsed)
	// 		return {expanded: {"/": true}};
	// const expanded: { [k: string]: boolean } = {};
	// return {expanded: this.recursivelyExpand(this.props.root, "/", expanded)};
	// };


	static recursivelyExpand = (obj: object, condition: (key: string, value: any, level: number) => boolean) => {
		return Tree.recursivelyExpandImpl(obj, {}, condition);
	};

	private static recursivelyExpandImpl = (obj: object, state: TreeNodeState, condition: (key: string, value: any, level: number) => boolean, path: string = "/", level: number = 1) => {

		return _keys(obj).reduce((_state, key) => {
			const value = obj[key];
			_state[`${path}${key}/`] = condition ? condition(key, value, level) : false;
			if (_state[`${path}${key}/`] && typeof value === "object")
				Tree.recursivelyExpandImpl(value, _state, condition, `${path}${key}/`, level + 1);

			return _state;
		}, state);
	};

	toggleExpanded = (e: React.MouseEvent): void => {
		const path = e.currentTarget.id;
		this.setState((prevState) => {
			prevState.expanded[path] = !prevState.expanded[path];
			return prevState;
		})
	};

	onNodeClicked = (e: React.MouseEvent): void => {
		if (!this.props.onNodeClicked)
			return;
		const path = e.currentTarget.id;
		this.props.onNodeClicked(path, this.props.id);
	};

	onNodeDoubleClicked = (e: React.MouseEvent): void => {
		if (!this.props.onNodeDoubleClicked)
			return;
		const path = e.currentTarget.id;
		this.props.onNodeDoubleClicked(path, this.props.id);
	};

	render() {
		return this.renderNode(this.props.root, "", "");
	}


	private renderNode(item: object, key: string, _path: string, parentArray?: boolean) {
		const Renderer = this.props.renderer || DefaultTreeRenderer;
		const path = `${_path}${key}/`;
		return <div key={path} style={{marginLeft: _path.length === 0 || this.props.hideRootElement && _path.length <= 1 ? 0 : this.props.indentPx}}>
			{!(this.props.hideRootElement && _path.length === 0) &&
			<Renderer
				name={key}
				node={item}
				path={path}
				expandToggler={this.toggleExpanded}
				onClick={this.onNodeClicked}
				onDoubleClick={this.onNodeDoubleClicked}
				expanded={this.state.expanded[path]}
				filter={this.props.childFilter || (() => true)}
				parentArray={parentArray}
			/>}
			{this.renderChildren(item, path, Array.isArray(item))}
		</div>
	}

	private renderChildren(item: object | string, path: string, parentArray: boolean) {
		if (!this.state.expanded[path])
			return "";

		if (typeof item !== "object")
			return "";

		const filter = this.props.childFilter || (() => true);
		return <>
			{_keys(item)
				.filter((key) => filter(item, key))
				.map((key, idx) => this.renderNode(item[key], key, path, parentArray))}
		</>;
	}
}
