import {Module} from "@intuitionrobotics/ts-common";
import {
	QueryParam_Email,
	QueryParam_RedirectUrl,
	QueryParam_JWT
} from "@intuitionrobotics/user-account/shared/api";
import {
	AccountModule,
	LoggedStatus,
	OnLoginStatusUpdated
} from "@intuitionrobotics/user-account/frontend";
import {PushPubSubModule} from "@intuitionrobotics/push-pub-sub/frontend";

type Config = {
	envName: string
	kasperoUrl: string
}

export class EnvironmentModule_Class
	extends Module<Config>
	implements OnLoginStatusUpdated {

	onLoginStatusUpdated = () => {
		if (AccountModule.getLoggedStatus() === LoggedStatus.LOGGED_IN)
			this.onLogin();
	};

	getOrigin = () => {
		const jwtParam = `${QueryParam_JWT}=${QueryParam_JWT.toUpperCase()}`;
		const userEmailParam = `${QueryParam_Email}=${QueryParam_Email.toUpperCase()}`;
		return `${this.getOriginUrl()}${this.getPathname()}?${jwtParam}&${userEmailParam}&${this.getSearch()}`;
	};

	getPathname() {
		return window.location.pathname;
	}

	getKasperoUrl() {
		return this.config.kasperoUrl;
	}

	getOriginUrl() {
		return window.location.origin;
	}

	getSearch() {
		const search = window.location.search;
		return search.startsWith('?') ? search.slice(1) : search;
	}

	getQuery() {
		return window.location.pathname + window.location.search;
	}

	getEnvName() {
		return this.config.envName;
	}

	isProd() {
		return this.getEnvName() === 'prod';
	}

	getAppVersion() {
		return process.env.appVersion;
	}

	getLoginRedirectUrl = (logout?: boolean): string => {
		const encodedOrigin = encodeURIComponent(this.getOrigin());
		return `${this.getKasperoUrl()}${logout ? '/logout' : ''}?${QueryParam_RedirectUrl}=${encodedOrigin}`;
	};

	redirectToLogin() {
		window.location.href = this.getLoginRedirectUrl();
	}

	logout = () => {
		AccountModule.logout(this.getLoginRedirectUrl(true));
	};

	private onLogin = () => {
		PushPubSubModule.initApp();
	};
}

export const EnvironmentModule = new EnvironmentModule_Class("EnvironmentModule");
