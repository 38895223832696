import * as React from "react";
import {
	BaseComponent,
	BrowserHistoryModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	CallDetails,
	OnAnswerCallError,
	OnCallDetails,
	OnCallUnitReturned,
	OnFoundMissedCall,
	OnRequestAccessTokenReturned,
	TwilioVideoModule
} from "@modules/TwilioVideoModule";
import {VideoRoom} from "./components/VideoRoom";
import * as Video from 'twilio-video';
import {
	QueryParam_CallId,
	QueryParam_UnitIdCalling
} from "@app/app-shared/contact-login";
import {generateHex} from "@intuitionrobotics/ts-common";
import {isValidJsonConfig} from "./videoCallUtils";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {PlaceCall} from "./components/PlaceCall";
import {CallEnded} from "./components/CallEnded";
import {Connecting} from "./components/Connecting";
import {MissedCall} from "./components/MissedCall";
import {RejectedCall} from "./components/RejectedCall";
import {isBrowserReallyUnsupported} from "../../utils/utils";
import {DB_Call} from "@app/app-shared/call-status";

enum AppCallState {
	READY    = "READY",
	REJECTED = "REJECTED",
	MISSED   = "MISSED"
}

type State = {
	checkingMissed: boolean;
	missedCall?: DB_Call
	connecting: boolean;
	callDetails?: CallDetails;
	callEnded: boolean;
	lastCallDuration?: number;
	acceptingCall: boolean;
	appCallState: AppCallState;
	hasError?: boolean
}

type Props = {
	// Used for development.
	videoConfig?: string;
	audioConfig?: string;
	unitContact?: DB_Contact<"agentUser">;
	onCallEnded: () => void;
	onCallFailed: () => void;

	// Call ended screen.
	installClicked: () => Promise<void>;
	canInstall: boolean;

	// Used only for dev.
	isAdmin: boolean;
};

export class VideoCalling
	extends BaseComponent<Props, State>
	implements OnRequestAccessTokenReturned,
		OnCallUnitReturned, OnCallDetails, OnAnswerCallError, OnFoundMissedCall {

	private initialState: State = {
		lastCallDuration: undefined,
		checkingMissed: false,
		connecting: false,
		callDetails: undefined,
		callEnded: false,
		acceptingCall: false,
		appCallState: AppCallState.READY,
		missedCall: undefined
	};

	constructor(props: Props) {
		super(props);
		this.state = {...this.initialState};
	}

	componentDidMount() {
		const {callId, unitIdCalling} = this.unitCalling();

		if (callId && unitIdCalling && this.props.unitContact?.contactData.unitId === unitIdCalling)
			this.answerUnitCall(callId, unitIdCalling);
	}

	// ERROR BOUNDARY DEF
	static getDerivedStateFromError(error: any) {
		// Update state so the next render will show the fallback UI.
		return {hasError: true};
	}

	componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
		// You can also log the error to an error reporting service
		TwilioVideoModule.logInfo('Error caught');
		TwilioVideoModule.logInfo(error);
		TwilioVideoModule.logInfo(errorInfo);
	}

	// END ERROR BOUNDARY DEF

	private unitCalling = () => {
		const callId = VideoCalling.getQueryParameter(QueryParam_CallId)?.trim();
		const unitIdCalling = VideoCalling.getQueryParameter(QueryParam_UnitIdCalling)?.trim();
		return {callId, unitIdCalling};
	};

	private answerUnitCall = (callId: string, unitIdCalling: string) => {
		const roomName = `${unitIdCalling}--${generateHex(32)}`;
		this.logInfo(`Will attempt to start video call with unit ${unitIdCalling}. Room name will be ${roomName}.`);
		BrowserHistoryModule.removeQueryParam(QueryParam_CallId);
		BrowserHistoryModule.removeQueryParam(QueryParam_UnitIdCalling);
		TwilioVideoModule.answerCall(roomName, callId, unitIdCalling);
		this.resetState({checkingMissed: true, acceptingCall: true});
	};

	__onCallDetails(callDetails: CallDetails) {
		this.logInfo(`Call starting successfully. Room name is ${callDetails.roomName}.`);
		this.setState(state => ({connecting: false, callDetails}));
	}

	__onFoundMissedCall(call: DB_Call) {
		this.logInfo(`Call with id ${call._id} was missed.`);
		if (call.callerId === this.props.unitContact?._id)
			return this.resetState({missedCall: call});

		this.resetState();
	}

	__onAnswerCallError(callId: string, unitIdCalling: string): void {
		this.logError(`Failed to answer call with is ${callId} from unit ${unitIdCalling}.`);
		this.resetState();
	}

	__onCallUnitReturnedError = (unitId: string): void => {
		this.logError(`Failed to call unit ${unitId}.`);
		this.resetState();
		this.props.onCallFailed();
	};

	__onRequestAccessTokenReturnedError = (roomName: string): void => {
		this.logError(`Failed to join room ${roomName}.`);
		this.resetState();
		this.props.onCallFailed();
	};

	private resetState = (extra?: Partial<State>) => {
		this.setState({...this.initialState, ...extra});
	};

	private onSubmit = (acceptingCall: boolean) => {
		this.setState({connecting: true, acceptingCall});
	};

	dismissMissedCall = () => {
		this.resetState();
	}

	private onRoomDisconnected = (callDuration?: number) => {
		const callId = this.state.callDetails?.callId;
		const unitId = this.props.unitContact?.contactData.unitId;
		this.resetState({callEnded: true, lastCallDuration: callDuration});

		if (!callId || !unitId)
			return this.props.onCallEnded();

		// Make call ended API.
		TwilioVideoModule.notifyEndCall(unitId, callId);
		this.props.onCallEnded();
	};

	private callEndedBackClicked = () => {
		this.resetState();
	};

	private redialClicked = () => {
		const {unitContact} = this.props;
		if (!unitContact) {
			this.logError(`No unit contact provided`);
			return;
		}

		const unitId = unitContact.contactData.unitId;
		const roomName = `${unitContact.contactData.unitId}--${generateHex(32)}`;
		this.logInfo(`Will attempt to start video call (redial) with unit ${unitId}. Room name will be ${roomName}.`);
		TwilioVideoModule.callUnit(roomName, unitId);
		this.resetState({connecting: true});
	};

	private onCallRejected = () => {
		this.resetState({appCallState: AppCallState.REJECTED});
	};

	private onCallMissed = () => {
		const callId = this.state.callDetails?.callId;
		const unitId = this.props.unitContact?.contactData.unitId;
		this.resetState({appCallState: AppCallState.MISSED});

		if (!callId || !unitId)
			return this.props.onCallEnded();

		// Make call ended API.
		TwilioVideoModule.notifyEndCall(unitId, callId);
		this.props.onCallEnded();
	};

	render() {
		if (isBrowserReallyUnsupported() || this.state.hasError)
			return (
				<div className={`match_all ll_v_c`} style={{justifyContent: "center"}}>
					<div>ElliQ video call is not supported for this browser yet.</div>
				</div>
			);

		const {
			      callDetails,
			      connecting,
			      callEnded,
			      lastCallDuration,
			      appCallState
		      } = this.state;

		if (connecting)
			return this.renderConnecting();

		if (callDetails !== undefined)
			return this.renderVideoRoom(callDetails);

		if (this.props.isAdmin)
			return this.renderConnecting();

		if (callEnded)
			return this.renderCallEnded(lastCallDuration);

		switch (appCallState) {
			case AppCallState.READY:
				return this.renderPlaceCall(this.props.unitContact);
			case AppCallState.REJECTED:
				return this.renderRejectedCall(this.props.unitContact);
			case AppCallState.MISSED:
				return this.renderMissedCall(this.props.unitContact);
			default:
				return this.renderPlaceCall(this.props.unitContact);
		}

	}

	private renderMissedCall = (unitContact?: DB_Contact<any>) => {
		const {installClicked, canInstall} = this.props;

		return (
			<React.StrictMode>
				<MissedCall
					unitContact={unitContact}
					redialClicked={this.redialClicked}
					installClicked={installClicked}
					canInstall={canInstall}
					callEndedBackClicked={this.callEndedBackClicked}/>
			</React.StrictMode>
		);
	};

	private renderRejectedCall = (unitContact?: DB_Contact<any>) => {
		const {installClicked, canInstall} = this.props;

		return (
			<React.StrictMode>
				<RejectedCall
					unitContact={unitContact}
					redialClicked={this.redialClicked}
					installClicked={installClicked}
					canInstall={canInstall}
					callEndedBackClicked={this.callEndedBackClicked}/>
			</React.StrictMode>
		);
	};


	private renderVideoRoom = (callDetails: CallDetails) => {
		const {
			      roomName,
			      roomSid,
			      roomAccessToken
		      } = callDetails;

		const {audioConfig, videoConfig, isAdmin, unitContact} = this.props;

		const overrideAudioConfig = (audioConfig && audioConfig !== "" && audioConfig !== "true" && isValidJsonConfig(audioConfig)) ?
			JSON.parse(audioConfig) :
			undefined;
		const overrideVideoConfig = (videoConfig && videoConfig !== "" && videoConfig !== "true" && isValidJsonConfig(videoConfig)) ?
			JSON.parse(videoConfig) :
			undefined;

		const overrideConfig: Video.ConnectOptions = {};

		if (overrideAudioConfig !== undefined)
			overrideConfig.audio = overrideAudioConfig;

		if (overrideVideoConfig !== undefined)
			overrideConfig.video = overrideVideoConfig;

		if (!isAdmin && !unitContact)
			return <div>
				Couldn't find an ElliQ to call<br/>
				Please contact the support team
			</div>;

		return (
			<React.StrictMode>
				<VideoRoom
					callId={callDetails.callId}
					isAdmin={isAdmin}
					unitContact={unitContact}
					roomName={roomName}
					roomSid={roomSid}
					accessToken={roomAccessToken}
					onRoomDisconnect={this.onRoomDisconnected}
					onCallRejected={this.onCallRejected}
					onCallMissed={this.onCallMissed}
					overrideConfig={overrideConfig}
					acceptingCall={this.state.acceptingCall}/>
			</React.StrictMode>
		);
	};

	private renderPlaceCall = (unitContact?: DB_Contact<any>) => {
		return (
			<React.StrictMode>
				<PlaceCall
					unitContact={unitContact}
					checkingMissed={this.state.checkingMissed}
					missedCall={this.state.missedCall}
					dismissMissedCall={this.dismissMissedCall}
					onSubmit={() => this.onSubmit(false)}/>
			</React.StrictMode>
		);
	};

	private renderCallEnded = (lastCallDuration?: number) => {
		const {installClicked, canInstall} = this.props;
		return (
			<React.StrictMode>
				<CallEnded
					callEndedBackClicked={this.callEndedBackClicked}
					redialClicked={this.redialClicked}
					installClicked={installClicked}
					canInstall={canInstall}
					lastCallDuration={lastCallDuration}/>
			</React.StrictMode>
		);
	};

	private renderConnecting = () => {
		const {unitContact} = this.props;
		const remoteParticipantName = unitContact?.firstName.trim() || unitContact?.contactData.unitId;
		return <Connecting remoteParticipantName={remoteParticipantName}/>;
	};

}
