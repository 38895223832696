import {Module, ObjectTS} from "@intuitionrobotics/ts-common";
import {PermissionsFE} from "@intuitionrobotics/permissions/frontend";
import {StorageKey_JWT} from "@intuitionrobotics/user-account/frontend";
import {decodeJWT} from "../app/utils/utils";


class AppModule_Class extends Module {

    constructor() {
        super("AppModule");
    }

    doesUserHavePermissions(url: string): boolean | undefined {
        const b = PermissionsFE.doesUserHavePermissions(url);
        if (b === undefined) {
            const jwt = StorageKey_JWT.get();
            if (!jwt)
                return undefined;
            const userInformation = decodeJWT(jwt);
            const permissions = userInformation.permissions;
            if (!permissions)
                return undefined;

            const strings = url.split('/').slice(1);
            if (this.amIAllowed(strings, permissions))
                return true;
        }
        return b;
    }

    private amIAllowed(pathArray: string[], permissions?: ObjectTS): boolean {
        if (!permissions)
            return false;

        let temp = permissions;
        for (const pathPart of pathArray) {
            temp = temp[pathPart];
            if (!temp)
                return false;
        }

        return true;
    }
}

export const AppModule = new AppModule_Class();
