import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';
import {VideoTrack} from "./VideoTrack";
import * as emotion from "emotion";
import {COLORS} from "@res/colors";
import {FONTS} from "@res/fonts";
import {isIOS} from "../videoCallUtils";

const diallingTone = require("@res/audio/video-calling/video_call_dialling_tone.mp3");

const icon__call_end = require('@res/icons/icon__end_call.svg');
const icon__audio_on = require('@res/icons/icon__audio_on.svg');
const icon__audio_off = require('@res/icons/icon__audio_off.svg');
const icon__video_on = require('@res/icons/icon__video_on.svg');
const icon__video_off = require('@res/icons/icon__video_off.svg');

const overlay = emotion.css(
	{
		boxSizing: "border-box",
		position: "absolute",
		justifyContent: "space-between",
		pointerEvents: "auto",
		top: 0,
		flexDirection: "column-reverse"
	}
);

const controlButtons = emotion.css(
	{
		boxSizing: "border-box",
		height: 112,
		justifyContent: "center",
		backgroundColor: COLORS.midnight_blue(0.4),
		pointerEvents: "auto"
	}
);

const endCallButton = emotion.css(
	{
		width: 64,
		height: 64,
		margin: "0px 56px"
	}
);

const sideButton = emotion.css(
	{
		width: 64,
		height: 64,
	}
);

type State = {
	shouldUnpublish: boolean;
};

type Props = {
	localVideoPublication: Video.LocalVideoTrackPublication;
	onDisconnect: () => void;
	toggleTracks: (kind: Video.Track.Kind) => void;
	localAudioDisabled: boolean;
	remoteParticipantName?: string;
	acceptingCall: boolean;
	onCallMissedTimeout: () => void;
};

export class Preview
	extends BaseComponent<Props, State> {

	private missedCallTimer?: NodeJS.Timeout;
	private diallingToneRef?: HTMLAudioElement;

	constructor(props: Props) {
		super(props);

		this.state = {
			shouldUnpublish: false,
		};
	}

	componentDidMount() {
		this.missedCallTimer = setTimeout(() => {
			return this.props.onCallMissedTimeout();
		}, 90000);
	}

	componentWillUnmount() {
		if (this.diallingToneRef)
			this.diallingToneRef.pause();

		if (this.missedCallTimer)
			clearTimeout(this.missedCallTimer);
	}

	render = () => {
		const {shouldUnpublish} = this.state;
		const {localVideoPublication, acceptingCall} = this.props;

		if (!localVideoPublication)
			return null;

		return (
			<div className={`match_all`} style={{position: "relative"}}>
				<VideoTrack
					disabled={!localVideoPublication.track.isEnabled}
					identity={'preview-video'}
					isLocal={true}
					videoTrackPublication={localVideoPublication}
					shouldUnpublish={shouldUnpublish}
					videoTagStyle={{borderRadius: 0}}
				/>
				{this.renderOverlay()}
				{!acceptingCall && this.renderDiallingTone()}
			</div>
		);
	};

	private renderOverlay = () => {
		const {localVideoPublication} = this.props;
		if (!localVideoPublication)
			return null;

		return (
			<div className={`ll_v_c match_all ${overlay}`}>
				{this.renderControlButtons()}
				{this.renderCallingOverlay()}
			</div>
		);

	};

	private renderCallingOverlay = () => {
		const name = this.props.remoteParticipantName;
		return (
			<div className={`ll_v_c match_width`} style={{flex: 1, justifyContent: "center"}}>
				{
					name && <div>
						{FONTS.centra_no2_bold(name, "white", 40)}
					</div>
				}
				<div style={{marginTop: 16}}>
					{FONTS.centra_no2_book("Calling...", "white", 20)}
				</div>
			</div>
		);
	};

	// If we continue having issues with safari, let's look into simulating user interaction,
	// JUST for triggering the audio.
	private _workAroundSafariAutoplay = async (audioRef: HTMLAudioElement, audio: any) => {
		try {
			audioRef.src = audio;
			audioRef.muted = true;
			await audioRef.play();
		} catch (_e) {
		}

		audioRef.pause();
		audioRef.currentTime = 0;

		audioRef.muted = false;

		try {
			await audioRef.play();
		} catch (_e) {
		}
	};

	private renderDiallingTone = () => {
		return (
			<audio
				onEnded={async (e) => {
					if (this.diallingToneRef && isIOS())
						await this._workAroundSafariAutoplay(this.diallingToneRef, diallingTone);
				}}
				src={diallingTone}
				loop={isIOS() ? undefined : true}
				hidden={true}
				ref={
					async r => {
						if (!r || this.diallingToneRef)
							return;

						this.diallingToneRef = r;

						if (isIOS())
							await this._workAroundSafariAutoplay(this.diallingToneRef, diallingTone);
						else
							await this.diallingToneRef.play();

						this.forceUpdate();
					}
				}/>
		);
	};

	private renderControlButtons = () => {
		const {localVideoPublication, toggleTracks, onDisconnect, localAudioDisabled} = this.props;
		if (!localVideoPublication)
			return null;

		return (
			<div className={`ll_h_c match_width ${controlButtons}`}>
				<img src={!localAudioDisabled ? icon__audio_on : icon__audio_off}
				     className={`${sideButton}`}
				     alt={"Mute"}
				     onClick={() => toggleTracks("audio")}/>
				<img src={icon__call_end}
				     className={`${endCallButton}`}
				     alt={"End call"}
				     onClick={() => {
					     this.setState(
						     {
							     shouldUnpublish: true
						     },
						     () => onDisconnect()
					     );
				     }}/>
				<img src={localVideoPublication.track.isEnabled ? icon__video_on : icon__video_off}
				     className={`${sideButton}`}
				     alt={"Camera"}
				     onClick={() => toggleTracks("video")}/>
			</div>
		);
	};
}
