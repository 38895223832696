import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {Unit} from "@app/ir-q-app-common/types/units";
import {ContactsModule, OnContactsUpdated} from "@modules/ContactsModule";
import {BaseComponent, GenericSelect} from "@intuitionrobotics/thunderstorm/frontend";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import * as React from "react";

type Props = {
    userContact?: DB_Contact<any>,
    selectedUnit?: Unit,
    fromUnitToContact: boolean,
    setMessageDirection: () => void,
    setUserContact: (c: DB_Contact<any>) => void,
    setUnitContact: (unit: Unit, unitContact: DB_Contact<any>) => void
}


export class Playground_ParticipantsForm extends BaseComponent<Props, {}> implements OnContactsUpdated, OnUnitsLoaded {

    __onContactsUpdated = () => this.forceUpdate();
    __onUnitsLoaded = () => this.forceUpdate();

    render() {
        const contacts = this.props.selectedUnit ? ContactsModule.getContactsByUnit(this.props.selectedUnit) || [] : [];

        const selectUnitComponent =
            (<div style={{marginBottom: "5px"}}>
                <GenericSelect<Unit>
                    placeholder={"Unit"}
                    options={CommonFEModule.getUnits()}
                    selectedOption={this.props.selectedUnit}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={(unit: Unit) => {
                        ContactsModule.getByUnit(unit);
                        const unitContact = ContactsModule.getUnitContact(unit);
                        if (!unitContact)
                            return;

                        this.props.setUnitContact(unit, unitContact)
                    }}
                    styles={{}}
                    presentation={option => option.unitId}/>
            </div>);
        const selectContactComponent =
            (<div style={{marginBottom: "5px"}}>
                <GenericSelect<DB_Contact<any>>
                    placeholder={"User by Unit"}
                    options={contacts}
                    selectedOption={this.props.userContact}
                    iconClose={"D"}
                    iconOpen={"U"}
                    onChange={(contact: DB_Contact<any>) => {
                        this.props.setUserContact(contact)
                    }}
                    styles={{}}
                    presentation={option => `${option.firstName} - ${option.lastName}`}/>
            </div>);

        return <section style={{display: "flex", marginTop: "5mm", marginBottom: "5mm"}}>
            <button style={{backgroundColor: "Transparent", border: "none", cursor: "pointer"}}
                    onClick={this.props.setMessageDirection}>
                <img alt="Swap directions"
                     src="https://img.icons8.com/fluent-systems-filled/48/000000/swap-paths.png"/>
            </button>
            {this.props.fromUnitToContact ?
                <div style={{width: "-webkit-fill-available", marginRight: "2mm"}}>
                    {selectUnitComponent}
                    {selectContactComponent}
                </div>
                : <div style={{width: "-webkit-fill-available", marginRight: "2mm"}}>
                    {selectContactComponent}
                    {selectUnitComponent}
                </div>}
        </section>;
    }
}
