import {
	BaseComponent,
	ToastModule,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as emotion from "emotion";
import {TwilioVideoModule} from "@modules/TwilioVideoModule";
import {ContactsModule} from "@modules/ContactsModule";

type State = {
	roomName: string;
	unitId: string;
}

type Props = {
	onSubmit: () => void;
	overrideConfigInvalid?: boolean;
}

const input = emotion.css(
	{
		border: "1px solid",
		padding: 5,
		margin: 10
	}
);

const button = emotion.css(
	{
		border: "1px solid",
		margin: 10
	}
);

export class StartVideoCall
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			roomName: "",
			unitId: ""
		};
	}

	private startVideoCall = () => {
		const {roomName, unitId} = this.state;
		if (!roomName?.trim() || !unitId?.trim())
			return;

		if (this.props.overrideConfigInvalid) {
			this.logError(`Invalid override config`);
			return;
		}

		const units = ContactsModule.getItems();
		const unitContact = units.filter(contact => contact.contactType === "agentUser" && contact.contactData.unitId === unitId);
		if (unitContact.length !== 1)
			return ToastModule.toastError(`Invalid unit id`);

		this.logInfo(`Will attempt to start video call with unit ${unitId}. Room name will be ${roomName}.`);
		this.logDebug(`Unit contact`, unitContact[0]);

		TwilioVideoModule.callUnit(roomName, unitId);
		return this.props.onSubmit();
	};

	render = () => {
		const {roomName, unitId} = this.state;
		const {overrideConfigInvalid} = this.props;

		return <div className={`ll_v_c`}>
			<h3 style={{
				margin: 10
			}}>
				Start a video call
			</h3>
			<div className={`ll_h_c`}>
				<div className={`ll_v_c`}>
					<TS_Input
						placeholder={"Room name"}
						type={"text"}
						className={input}
						value={roomName}
						id={'room-to-create-name'}
						onChange={
							(value) => {
								this.setState({roomName: (value?.trim() || "")});
							}
						}/>
					<TS_Input
						placeholder={"Unit ID"}
						type={"text"}
						className={input}
						value={unitId}
						id={'unit-id'}
						onChange={
							(value) => {
								this.setState({unitId: (value?.trim() || "")});
							}
						}/>
					<button
						className={button}
						disabled={!roomName || !roomName.trim() || !unitId || !unitId.trim() || overrideConfigInvalid}
						onClick={() => this.startVideoCall()}>
						Start Video Call
					</button>
				</div>
			</div>
		</div>;
	};

}
