import {Module} from "@intuitionrobotics/ts-common";

type Config = {
    supportUrls: string[]
}

class SupportModule_Class
    extends Module<Config> {

    getSPUrls = () => this.config.supportUrls;
}

export const SupportModule = new SupportModule_Class("SupportModule")
