import {FirebaseListenerModule_Class} from "@modules/FirebaseListenerModule";

class CallStatusesListenerModule_Class extends FirebaseListenerModule_Class {

	constructor() {
		super("calls-statuses", "CallStatusListener");
	}

	init() {
		super.init();
	}
}

export const CallStatusListener = new CallStatusesListenerModule_Class();
