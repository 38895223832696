import {BaseComponent, TS_Input} from "@intuitionrobotics/thunderstorm/frontend";
import React from "react";

export type NewCustomTableRow = { [key: string]: string | number }

type Props = {
    onRowUpdate: (keyOfRow: string, newRow: NewCustomTableRow) => void,
    keyOfRow: string,
    dataOfRow: { [key: string]: string | any }
    columnAccessors: string[]
}

type State = {
    showLoader: boolean;
    updatedDataOfRow: { [key: string]: string | any };
};


class CustomTableRow extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showLoader: false,
            updatedDataOfRow: {...this.props.dataOfRow}
        }
    }

    handleChange = (key: string, value: string) => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.updatedDataOfRow[key] = value;
            return currState;
        })
    };

    handleRowBlur = () => {
        this.props.onRowUpdate(this.props.keyOfRow, this.state.updatedDataOfRow);
    }

    render() {

        return (
            <tr onBlur={this.handleRowBlur}>
                {
                    this.props.columnAccessors.map(key => {
                        return (
                            <td>
                                <TS_Input id={key} value={this.state.updatedDataOfRow[key]}
                                          onChange={(value) => {
                                              this.handleChange(key, value);
                                          }} type="text"
                                          style={{
                                              border: "1px solid gray",
                                              display: "block",
                                              marginBottom: "5px"
                                          }}/>
                            </td>
                        );
                    })
                }

            </tr>
        );
    }

}

export default CustomTableRow;