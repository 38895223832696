import * as React from "react";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {Playground_MessageListWithPermission} from "./Playground_MessageListWithPermission";

export class Playground_MessageList
	extends React.Component {

	constructor(props: {}) {
		super(props);
		this.state = {};
	}

	render() {
		return <PermissionsComponent url={"/v1/message/query"}>
			<Playground_MessageListWithPermission/>
		</PermissionsComponent>
	}

}
