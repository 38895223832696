import * as React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {OnPermissionsChanged} from "@intuitionrobotics/permissions/app-frontend/modules/PermissionsModuleFE";
import {AppModule} from "@modules/AppModule";

type Props = {
    url: string
    loadingComponent?: React.ComponentType
    fallback?: React.ComponentType
}

export class AppPermissionsComponent
    extends BaseComponent<Props>
    implements OnPermissionsChanged {

    __onPermissionsChanged() {
        this.forceUpdate();
    }

    render() {
        const permitted = AppModule.doesUserHavePermissions(this.props.url);
        if (permitted === undefined)
            return this.props.loadingComponent ? <this.props.loadingComponent/> : null;

        if (permitted)
            return <>{this.props.children}</>;

        if (this.props.fallback)
            return <this.props.fallback/>;

        return null;
    }
}
