import * as React from "react";
import * as emotion from "emotion";
import {COLORS} from "@res/colors";

export const spin = emotion.keyframes`
  0% { 
    transform: rotate(0deg);
  }
  30% {
   transform: rotate(300deg);
  }
  100% { transform: rotate(360deg); }
`;
export const loader = emotion.css`
	border: 8px solid transparent;
	border-radius: 50%;
	border-top: 8px solid #3498db;
	border-bottom: 8px solid #3498db;
	border-right: 8px solid #3498db;
	width: 30px;
	height: 30px;
	animation: ${spin} 2s linear infinite;
`;

type Props = {
	message?: string
	overlay?: boolean
}

export class Loader
	extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const spinner = this.renderSpinner();
		if (!this.props.overlay)
			return spinner;

		return <div
			id={'loader'}
			className="match_width match_height full_screen"
			style={{background: COLORS.grays_05}}>
			{spinner}
		</div>;
	}

	private renderSpinner = () => (
		<div className="ll_h_c match_height fill">
			<div className="ll_v_c match_width">
				<div className={`loader`}/>
				<div style={{marginTop: '20px', color: '#f9f9f9'}}>{this.props.message ? this.props.message : "Loading..."}</div>
			</div>
		</div>
	);
}