import { DataEnvItem } from "@app/ir-q-app-common/types/units";
import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";

export class DataEnvsModule_Class
	extends BaseDB_ApiGeneratorCaller<DataEnvItem> {

	constructor() {
		super({key: "data-envs", relativeUrl: "/v1/data-env"}, "DataEnvsModule");
	}
}

export const DataEnvsModule = new DataEnvsModule_Class();
