import {
	BaseComponent,
	TS_Table
} from "@intuitionrobotics/thunderstorm/frontend";
import * as emotion from "emotion";
import React from "react";
import {Loader} from "../../../../widgets/Loader";
import {TaskModuleListener} from "@modules/TaskModule";
import {
	DB_Task,
	StatusType,
} from "@app/ir-q-app-common/types/tasks";
import moment from "moment";
import {OnCollectionUpdated} from "@modules/FirebaseListenerModule";

type State = {
	showLoader: boolean
}


type ExtendedDB_Task = DB_Task & {
	timestampFormat: string
}

export class Playground_TaskSchedulerTable
	extends BaseComponent<{}, State> implements OnCollectionUpdated {

	constructor(props: {}) {
		super(props);

		TaskModuleListener.listenImpl();

		this.state = {
			showLoader: true
		};
	}

	__onCollectionUpdated = (collectionName: string) => {
		if (collectionName !== TaskModuleListener.getCollectionName())
			return

		this.setState({showLoader: false});
	};

	componentWillUnmount() {
		TaskModuleListener.stopListening();
	}

	private getTsTable() {
		type extendedDB_Task = DB_Task & {
			timestampFormat?: string
		}
		const data = TaskModuleListener.getData() as DB_Task[];
		const preparedData: extendedDB_Task[] = this.prepareData(data);

		return <div style={{height: "calc(100vh - 190px)", overflow: "auto"}}>
			<TS_Table<extendedDB_Task>
				className={emotion.css`
				width: 97%;
				margin: 15px;
				border-collapse: collapse;
				position: relative;
				tbody > * > * {
					border: 1px solid black;
					padding: 8px;
				}`}
				id={"video-call-table"}
				header={["_id",
				         "executionTimestamp",
				         "timestampFormat",
				         "moduleName",
				         "methodName",
				         "methodParams",
				         "finishedAt",
				         "createdAt",
				         "status",
				]}
				rows={preparedData}
				cellRenderer={(cellValue: any) => {
					if (Array.isArray(cellValue)) {
						return this.printArray(cellValue);
					}

					if (typeof cellValue === "object") {

						switch (cellValue.status) {
							case StatusType.Completed: {
								return <span style={{color: 'green'}}>{cellValue.status}</span>;
							}
							case StatusType.InProgress: {
								return <span style={{color: 'yellow'}}>{cellValue.status}</span>;
							}
							case StatusType.ToBeExecuted: {
								return <span>{cellValue.status}</span>;
							}
							case StatusType.Error: {
								return <pre style={{color: 'red'}}>{JSON.stringify(cellValue, null, 2) }</pre>;
							}
						}

					}

					return cellValue;
				}}
				headerRenderer={(columnKey: keyof extendedDB_Task) => {
					return columnKey;
				}}
			/></div>;
	}

	private printArray(arr: any[]): string {
		return "[" + arr.map((item) => {
			if(typeof item === "object" && !Array.isArray(item))
				return JSON.stringify(item, null, 2);

			if(Array.isArray(item))
				return this.printArray(item);

			return item;
		}).join(", ") + "]";
}

	render() {

		if (this.state.showLoader)
			return <Loader overlay={true}/>

		return <div style={{marginTop: 30, overflow: "hidden"}}>
			{this.getTsTable()}
		</div>;
	}

	private prepareData(data: DB_Task[]) {
		data.sort((task1, task2) => task2.executionTimestamp - task1.executionTimestamp);
		return data.map((item) => {
			return {
				...item,
				timestampFormat: moment(item.executionTimestamp).format("HH:mm:ss DD-MM-YYYY")
			}
		}) as ExtendedDB_Task[];
	}
}
