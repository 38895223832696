import {DB_CloudAsset} from "@app/app-shared/cloud-asset";
import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {ThunderDispatcher} from "@intuitionrobotics/thunderstorm/app-frontend/core/thunder-dispatcher";
import {Api_GetAssetsWithUrl} from "@app/app-shared/cloud-asset-api";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";

export interface OnCloudAssetCreated {
	__onCloudAssetCreated: () => void;
}

export interface OnGeneralCloudAssetUpdated {
	__onGeneralCloudAssetUpdated: (assetId?: string) => void;
}

export interface OnGetImageWithStorageUrls {
	__onGetImageWithStorageUrls: () => void;
}

export const dispatch_onGeneralCloudAssetUpdated = new ThunderDispatcher<OnGeneralCloudAssetUpdated, "__onGeneralCloudAssetUpdated">(
	"__onGeneralCloudAssetUpdated");
export const dispatch_onCloudAssetCreated = new ThunderDispatcher<OnCloudAssetCreated, "__onCloudAssetCreated">("__onCloudAssetCreated");
export const dispatch_onGetImageWithStorageUrls = new ThunderDispatcher<OnGetImageWithStorageUrls, "__onGetImageWithStorageUrls">(
	"__onGetImageWithStorageUrls");

export class CloudAssetsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_CloudAsset> {

	private dbAssetsWithImages: { [key: string]: DB_CloudAsset[] } = {};
	private files: {[key: string]: File} = {}

	public getDbAssetsWithImages() {
		return this.dbAssetsWithImages;
	}
	public getDbAssetsWithImagesByKey(key: string) {
		return this.dbAssetsWithImages[key];
	}

	protected onQueryReturned(items: DB_CloudAsset[]): Promise<void> {
		const valueToReturn = super.onQueryReturned(items);
		dispatch_onGeneralCloudAssetUpdated.dispatchUI([]);
		return valueToReturn;
	}

	protected onEntryCreated(item: DB_CloudAsset): Promise<void> {

		const cacheId = item.cacheId;
		if (cacheId && this.files[cacheId])
			item.signedUrl = URL.createObjectURL(this.files[cacheId]);

		const exitAssetIndex = this.dbAssetsWithImages[item.key].findIndex(asset => asset._id === item._id);
		if (exitAssetIndex !== -1)
			this.dbAssetsWithImages[item.key].splice(exitAssetIndex, 1);

		this.dbAssetsWithImages[item.key].push(item);

		dispatch_onCloudAssetCreated.dispatchUI([]);
		dispatch_onGeneralCloudAssetUpdated.dispatchUI([item._id]);
		return Promise.resolve();
	}

	protected onEntryUpdated(item: DB_CloudAsset): Promise<void> {
		// const valueToReturn = super.onEntryUpdated(item);
		this.dbAssetsWithImages[item.key] = this.dbAssetsWithImages[item.key].filter(asset => asset._id !== item._id)

		const cacheId = item.cacheId;
		if (cacheId && this.files[cacheId])
			item.signedUrl = URL.createObjectURL(this.files[cacheId]);

		this.dbAssetsWithImages[item.key].push(item);

		dispatch_onGeneralCloudAssetUpdated.dispatchUI([item._id]);
		 return Promise.resolve();
	}

	protected onEntryDeleted(item: DB_CloudAsset): Promise<void> {
		const valueToReturn = super.onEntryDeleted(item);
		this.dbAssetsWithImages[item.key] = this.dbAssetsWithImages[item.key].filter(asset => asset._id !== item._id);
		dispatch_onGeneralCloudAssetUpdated.dispatchUI([]);
		return valueToReturn;
	}

	public fetchUrlsOfImages = () => {
		XhrHttpModule
			.createRequest<Api_GetAssetsWithUrl>(HttpMethod.GET, "get-assets-with-url")
			.setRelativeUrl("/v1/cloud-asset/get-assets-with-url")
			.setOnError(resError => {
				this.logError("Failed to get call doc status:", resError);
			})
			.execute((response => {
				this.dbAssetsWithImages = response;
				dispatch_onGetImageWithStorageUrls.dispatchUI([]);
			}));
	};

	public setFileInCache(feId: string, file: File) {
		this.files[feId] = file;
	}

	public replaceFileInCache(feId:string, cacheId: string) {
		const file = this.files[feId];
		if (!file)
			return

		delete this.files[feId]
		this.files[cacheId] = file;
	}

	public getFile(cacheId: string) {
		return this.files[cacheId];
	}
}


export const CloudAssetsModule = new CloudAssetsModule_Class({
	                                                             key: "cloud-assets",
	                                                             relativeUrl: "/v1/cloud-asset"
                                                             }, "CloudAssetsModule");
