import * as React from "react";
import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	createRef,
	CSSProperties,
} from "react";
import WaveSurfer from "wavesurfer.js";
import {WaveSurferParams} from "wavesurfer.js/types/params";

type Props = {
	// Using Omit<Wavesurfer.WaveSurferParams, 'container'> so that the ref can
	// only be defined inside the Wavesurfer component.
	wavesurferParams: Omit<WaveSurferParams, 'container'>;
	onWaveSurferInit: (wavesurfer: WaveSurfer) => void;
	// Do not override WaveSurfer's instance 'ready' and 'error' event handlers.
	// Provide methods that will be called from the component instead.
	onReady: () => void;
	onError: (error?: any) => void;
	placeholder?: React.ReactNode;
	style?: CSSProperties;
};

type State = {
	ready: boolean;
};

export class WaveSurferComponent
	extends BaseComponent<Props, State> {

	private readonly wavesurferRef?: React.RefObject<HTMLDivElement>;
	private wavesurfer?: WaveSurfer;

	constructor(props: Props) {
		super(props);
		this.wavesurferRef = createRef<HTMLDivElement>();
		this.state = {
			ready: false
		};
	}

	componentDidMount() {
		if (!this.wavesurferRef?.current) {
			this.logError('WaveSurfer div ref is not set on mount.');
			return;
		}

		this.wavesurfer = WaveSurfer.create(
			{
				container: this.wavesurferRef?.current,
				...this.props.wavesurferParams,
			}
		);

		if (!this.wavesurfer) {
			this.logError('Failed to create WaveSurfer instance.');
			return;
		}

		this.logDebug(`WaveSurfer created successfully.`);
		this.props.onWaveSurferInit(this.wavesurfer);

		this.wavesurfer.on('ready', () => {
			this.logInfo('waveSurfer instance is ready');
			this.setState({ready: true}, () => {
				this.props.onReady();
			});
		});

		this.wavesurfer.on('error', (e: any) => {
			this.logError('Error event:', e);
			this.props.onError(e);
		});
	}

	render() {
		// Note: The wavesurfer package requires the "wavesurfer" div to have width, in order to draw its canvas.
		// If we decide to not show the "wavesurfer" div until the wave form is loaded,
		// then while it's not ready, use position: absolute and render it off screen.
		// For now, we simple display a centered placeholder that the parent element provides.
		return <>
			<div className={`match_width match_height`} style={{position: "relative"}}>
				<div id={"wavesurfer"}
				     ref={this.wavesurferRef}
				     className={`match_width match_height`}
				/>
				{
					!this.state.ready &&
					<div style={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)"
					}}>
						{this.props.placeholder}
					</div>
				}
			</div>
		</>;
	}
}
