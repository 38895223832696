import * as React from "react";
import {chipCss} from "./css/chipsCss";


const icon__x = require('@res/images/icon_x.svg');

type State = {
}

type Props = {
    text: string;
    editable?: boolean;
    closable?: boolean;
    onClose?: () => void;
    onEdit?: () => void;
}

export class Chip
    extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }


    render() {
        return <div className={chipCss}>
            <div onClick={this.props.onEdit}>
                {this.props.text}
            </div>
            {
                !!this.props.closable &&
                <div>
                    <img alt={'close'} onClick={this.props.onClose} src={icon__x}/>
                </div>
            }
        </div>;
    }
}
