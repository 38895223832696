import * as React from "react";
import {
	BaseComponent,
	BrowserHistoryModule,
	Dialog,
	Toaster
} from "@intuitionrobotics/thunderstorm/frontend";
import {OnUnauthenticatedResponse} from "../../../index";
import {
	AccountModule,
	LoggedStatus,
	OnLoginStatusUpdated
} from "@intuitionrobotics/user-account/frontend";
import {BadImplementationException} from "@intuitionrobotics/ts-common";
import {QueryParam_UnitIdCalling} from "@app/app-shared/contact-login";
import {VideoCallingAppSuggested} from "./VideoCallingAppSuggested";
import * as videoCallingCss from "./css/videoCallingApp";
import {Login} from "./components/Login";
import {StorageKey_PWAUserName} from "@modules/ContactLoginFEModule";
import {PWALoader} from "../../../widgets/PWALoader";

type State = {
	showLoader: boolean,
}

export class VideoCallingWrapper
	extends BaseComponent<{}, State>
	implements OnUnauthenticatedResponse, OnLoginStatusUpdated {

	constructor(props: {}) {
		super(props);
		this.state = {
			showLoader: false
		};

		// CSS issue with mobile browser viewport.
		// Get the viewport height and we multiple it by 1% to get a value for a vh unit
		const vh = window.innerHeight * 0.01;
		// Then we set the value in the --vh custom property to the root of the document.
		// Used in `videoCallingCss.videoCallingApp`
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}

	private resizeVh = () => {
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		this.forceUpdate();
	};

	componentDidMount() {
		window.addEventListener('resize', this.resizeVh);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.resizeVh);
	}

	onLoginStatusUpdated = () => {
		if (AccountModule.getLoggedStatus() === LoggedStatus.LOGGED_OUT) {
			StorageKey_PWAUserName.delete();
			BrowserHistoryModule.removeQueryParam(QueryParam_UnitIdCalling);
		}

		this.forceUpdate();
	};

	onUnauthenticatedResponse = () => {
		AccountModule.logout();
		this.forceUpdate();
	};

	showLoader = () => {
		if (this.state.showLoader)
			return <PWALoader message={""} overlay={true}/>;
	};

	render() {
		return <>
			<div className={videoCallingCss.videoCallingApp}>
				{this.renderBasedOnStatus()}
			</div>
			<Toaster/>
			<Dialog/>
		</>;
	}

	private renderBasedOnStatus = () => {
		const status = AccountModule.getLoggedStatus();
		switch (status) {
			case LoggedStatus.LOGGED_IN:
				return <VideoCallingAppSuggested/>;

			case LoggedStatus.LOGGED_OUT:
				return <Login/>;

			case LoggedStatus.VALIDATING:
				return <PWALoader message={""} overlay={true}/>;

			default:
				throw new BadImplementationException(`logged status can only be one of 'LOGGED_IN', 'LOGGED_OUT' or 'VALIDATING'`);
		}
	};

}
