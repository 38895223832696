import {BaseComponent,} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as emotion from "emotion";
import * as Video from 'twilio-video';
import {RemoteParticipants} from "./RemoteParticipants";
import * as videoCallUtils from "../videoCallUtils";
import {isMobile} from "../videoCallUtils";
import {Overlay} from "./Overlay";
import {merge} from "@intuitionrobotics/ts-common";
// import {VideoRoomMonitor} from '@twilio/video-room-monitor';
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {Preview} from "./Preview";
import {Connecting} from "./Connecting";
import {TwilioVideoModule} from "@modules/TwilioVideoModule";
import {
	CallStatuses,
	DB_Call
} from "@app/app-shared/call-status";


type State = {
	room?: Video.Room;
	startTime?: number;

	// Used for handling app in mobile moving to the background and losing camera access.
	localVideoPaused: boolean;

	// Can be due to disabling, or unpublishing.
	remoteVideoStopped: boolean;

	// (Un)Mute audio and video.
	localAudioDisabled: boolean;
	localVideoDisabled: boolean;
};

type Props = {
	callId?: string;
	roomName: string;
	roomSid: string;
	accessToken: string;
	onRoomDisconnect: (callDuration?: number) => void;
	unitContact?: DB_Contact<"agentUser">;
	acceptingCall: boolean;

	onCallRejected: () => void;
	onCallMissed: () => void;

	isAdmin: boolean;
	overrideConfig?: Video.ConnectOptions;
};

const roomContainer = emotion.css(
	{
		boxSizing: "border-box",
		height: "100%",
		width: "100%",
		position: "relative"
	}
);

const wrapper = emotion.css(
	{
		boxSizing: "border-box",
		height: "100%",
		width: "100%",
		minHeight: "100%",
		minWidth: "100%",
		maxHeight: "100%",
		maxWidth: "100%"
	}
);


export class VideoRoom
	extends BaseComponent<Props, State> {

	private disconnectedCalled: boolean = false;

	private readonly defaultAudioConfig = {
		echoCancellation: true,
		noiseSuppression: true
	};

	private readonly defaultVideoConfig = {
		height: 180,
		width: 240,
		frameRate: 10,
	};

	private readonly defaultConnectionConfig = {
		audio: this.defaultAudioConfig,
		video: this.defaultVideoConfig,
		region: "gll",
	};

	constructor(props: Props) {
		super(props);
		this.state = {
			room: undefined,
			startTime: undefined,
			remoteVideoStopped: false,
			localVideoPaused: false,
			localAudioDisabled: false,
			localVideoDisabled: false,
		};
	}

	async componentDidMount() {
		this.logInfo(`Is mobile: ${videoCallUtils.isMobile()}.`);
		this.disconnectedCalled = false;

		const {roomName, accessToken} = this.props;
		if (!roomName || !accessToken) {
			this.logError('Room name and access toke props are mandatory.');
			return;
		}

		const room = await this.joinRoom(roomName, accessToken);

		room.localParticipant.on("disconnected", this.handleLocalDisconnectedParticipant);

		// Investigate if anything special needs to be done for local participant.
		this.handleMobileBrowserHiding();

		// Remote participants.
		room.participants.forEach(this.handleConnectedParticipant);

		room.on("participantConnected", this.handleConnectedParticipant);
		room.on("participantDisconnected", this.handleDisconnectedParticipant);

		window.addEventListener("pagehide", () => this.disconnectFromRoom());
		window.addEventListener("beforeunload", () => this.disconnectFromRoom());

		this.setState({room});
	}

	componentWillUnmount() {
		// Remove window event listeners.
		window.removeEventListener("pagehide", () => this.disconnectFromRoom());
		window.removeEventListener("beforeunload", () => this.disconnectFromRoom());

		if (this.state.room && !this.disconnectedCalled)
			this.disconnectFromRoom();
	}

	private joinRoom = async (roomName: string, accessToken: string) => {
		this.logInfo(`Connecting to room ${roomName}...`, accessToken);

		const defaultConnectOptions: Video.ConnectOptions = {
			name: roomName,
			...this.defaultConnectionConfig,
		};

		const localTrackOptions: Video.ConnectOptions = merge(defaultConnectOptions, this.props.overrideConfig || {});

		this.logInfo(`Attempting to connect to room using the following config:`, localTrackOptions);

		const room = await Video.connect(
			accessToken,
			localTrackOptions
		);

		this.logInfo(`Connected to room ${room.name} successfully.`);

		// if (this.props.isAdmin) {
		// 	VideoRoomMonitor.registerVideoRoom(room);
		// }

		return room;
	};

	private disconnectFromRoom = (reason?: "rejection" | "timeout") => {
		const {room, startTime} = this.state;
		if (!room || this.disconnectedCalled)
			return;

		this.disconnectedCalled = true;
		this.unpublishAllLocalTracks(room);

		document.removeEventListener("visibilitychange", this.mobileVisibilityChangeHandler);

		const timeElapsed = startTime ? Date.now() - startTime : undefined;

		if (!room) {
			this.props.onRoomDisconnect(timeElapsed);
			return;
		}

		this.logInfo(`Reason for disconnect is ${reason || "undefined"}.`);

		this.logInfo(`Disconnecting from room ${room.name}.`);
		room.disconnect();

		this.setState({room: undefined});
		if (reason === "rejection")
			this.props.onCallRejected();
		else if (reason === "timeout") {
			this.props.onCallMissed();
		} else {
			this.props.onRoomDisconnect(timeElapsed);
		}
	};

	private handleMobileBrowserHiding = () => {
		document.addEventListener("visibilitychange", this.mobileVisibilityChangeHandler);
	};

	private mobileVisibilityChangeHandler = () => {
		const room = this.state.room;
		if (!videoCallUtils.isMobile() || !room) {
			return;
		}

		if (document.visibilityState === "visible") {
			Video.createLocalVideoTrack(this.defaultVideoConfig).then((localVideoTrack: Video.LocalVideoTrack) => {
				if (this.state.localVideoDisabled)
					localVideoTrack.disable();

				return room.localParticipant.publishTrack(localVideoTrack).then((_localTrackPublication: Video.LocalTrackPublication) => {
					this.setState({localVideoPaused: false});
					this.logInfo("Republished video track due to visibility changing to 'visible'.");
					// Edge case, may not be enough.
					if (document.visibilityState !== "visible") {
						this.logWarning(`Visibility state was changed rapidly to 'visible'. Unpublishing video.`);
						this.unpublishLocalVideoTracks(room);
						this.setState({localVideoPaused: true});
					}
				});
			}).catch(e => {
				this.setState({localVideoPaused: true});
				this.logError("Error while republishing video track due to visibility changing to 'visible'.", e);
			});
		} else {
			// When the app is moved to the background, it can no longer capture
			// video frames. So, stop and unpublish the LocalVideoTrack.
			this.unpublishLocalVideoTracks(room);
			this.setState({localVideoPaused: true});
		}
	};

	private handleConnectedParticipant = (participant: Video.RemoteParticipant) => {
		this.logInfo('Remote participant connected:', participant);
		if (!this.state.startTime) {
			// Start the timer for call duration only when the remote participant connects for the first time.
			this.logDebug("Setting timestamp for call duration.");
			this.setState({startTime: Date.now()});
		}

		// Iterate through the participant's published tracks and add listeners on them.
		participant.tracks.forEach((trackPublication: Video.RemoteTrackPublication) => {
			this.logInfo(`${participant.identity} TrackPublication: ${trackPublication}`);
			this.onRemoteTrackPublished(trackPublication);
		});

		// Listen for any new track publications.
		participant.on("trackPublished", this.onRemoteTrackPublished);
		participant.on("trackUnpublished", this.onRemoteTrackUnpublished);
	};

	private onRemoteTrackPublished = (trackPublication: Video.RemoteTrackPublication) => {
		if (trackPublication.isSubscribed) {
			this.logInfo(`Already subscribed to track: ${trackPublication}`);
			this.addRemoteTrackListeners(trackPublication);
		} else {
			// Listen for any new subscriptions to this track publication.
			trackPublication.on("subscribed", (track: Video.RemoteTrack) => {
				this.logInfo(`Subscribed to track publication: ${track}`);
				this.setState({remoteVideoStopped: false});

				this.addRemoteTrackListeners(trackPublication);
			});
		}

		trackPublication.on("unsubscribed", (track: Video.RemoteTrack) => {
			this.logInfo(`Unsubscribed from track publication: ${track}`);
			this.setState({remoteVideoStopped: true});
		});
	};

	private onRemoteTrackUnpublished = (trackPublication: Video.RemoteTrackPublication) => {
		this.logInfo(`This track was unpublished from the room:`, trackPublication);
	};

	private addRemoteTrackListeners = (trackPublication: Video.RemoteTrackPublication) => {
		if (trackPublication.track) {
			if (trackPublication.kind === "video") {
				const remoteVideoTrack = trackPublication.track as Video.RemoteVideoTrack;
				this.setState({remoteVideoStopped: (!remoteVideoTrack.isEnabled || remoteVideoTrack.isStarted)});
			}

			this.logInfo(`Adding remote track listeners for ${trackPublication.kind} track with SID ${trackPublication.trackSid}.`);
			trackPublication.track.on("enabled", this.onRemoteTrackEnabled);
			trackPublication.track.on("disabled", this.onRemoteTrackDisabled);
		}
	};

	private onRemoteTrackEnabled = (track: Video.RemoteTrack) => {
		this.logInfo(`Remote ${track.kind} track with SID ${track.sid} enabled.`);
		if (track.kind === "video") {
			this.setState({remoteVideoStopped: false});
		} else {
			this.forceUpdate();
		}
	};

	private onRemoteTrackDisabled = (track: Video.RemoteTrack) => {
		this.logInfo(`Remote ${track.kind} track with SID ${track.sid} disabled.`);
		if (track.kind === "video") {
			this.setState({remoteVideoStopped: true});
		} else {
			this.forceUpdate();
		}
	};

	private disconnectIfRoomEnded = () => {
		if (this.props.callId) {
			TwilioVideoModule.getCallStatus(this.props.callId, (callStatus: DB_Call) => {
				switch (callStatus.status) {
					case CallStatuses.REJECTED:
						return this.disconnectFromRoom("rejection");
					case CallStatuses.MISSED:
						return this.disconnectFromRoom("timeout");
					case CallStatuses.ENDED:
						return this.disconnectFromRoom();
				}
			});
		} else {
			this.disconnectFromRoom();
		}
	};

	private onCallMissedTimeout = () => {
		return this.disconnectFromRoom("timeout");
	};

	private handleLocalDisconnectedParticipant = () => {
		this.disconnectIfRoomEnded();
	};

	// Remote participant.
	private handleDisconnectedParticipant = (participant: Video.Participant) => {
		// Stop listening for this participant.
		this.logInfo(`Participant ${participant.identity} disconnected.`);
		participant.removeAllListeners();
		this.forceUpdate();
	};

	private unpublishLocalVideoTracks = (room: Video.Room) => {
		room.localParticipant.videoTracks.forEach((videoTrackPublication: Video.LocalVideoTrackPublication) => {
			this.logInfo(`Unpublishing local video track ${videoTrackPublication.trackSid}...`);
			videoTrackPublication.track.detach();
			videoTrackPublication.track.stop();
			videoTrackPublication.unpublish();
			room.localParticipant.unpublishTrack(videoTrackPublication.track);
		});
		this.logInfo("Done.");
	};

	private unpublishAllLocalTracks = (room: Video.Room) => {
		room.localParticipant.tracks.forEach((localTrackPublication: Video.LocalTrackPublication) => {
			this.logInfo(`Unpublishing ${localTrackPublication.kind} track ${localTrackPublication.trackSid}.`);
			if (localTrackPublication.track.kind !== "data") {
				localTrackPublication.track.detach();
				localTrackPublication.track.stop();
				localTrackPublication.unpublish();
				room.localParticipant.unpublishTrack(localTrackPublication.track);
			}
		});
		this.logInfo("Done.");
	};

	private toggleTracks = (trackKind: Video.Track.Kind) => {
		const localParticipant = this.state.room?.localParticipant;
		if (!localParticipant) {
			this.logError(`Failed to get local participant. Can't enable/disable tracks.`);
			return;
		}

		switch (trackKind) {
			case "audio":
				localParticipant.audioTracks.forEach(
					publication => this.state.localAudioDisabled ? publication.track.enable() : publication.track.disable()
				);
				this.logInfo(`${this.state.localAudioDisabled ? "Enabled" : "Disabled"} audio tracks.`);
				this.setState({localAudioDisabled: !this.state.localAudioDisabled});
				break;
			case "video":
				/*
					From the twilio-video docs (https://www.twilio.com/docs/video/javascript-getting-started#mute-your-local-media):
					NOTE: Although disabling a LocalVideoTrack whose source is a camera stops sending media,
					the camera is still reserved by the LocalVideoTrack and hence its light still stays on.
					In some use cases, the desired behavior might be that the light should turn off when users mutes their camera.
					Although this method is not recommend, this can be achieved by calling stop() on the LocalVideoTrack and unpublishing it from the Room:

					```
					publication.track.stop();
					publication.unpublish();
					```
				 */
				localParticipant.videoTracks.forEach(
					publication => this.state.localVideoDisabled ? publication.track.enable() : publication.track.disable()
				);
				this.logInfo(`${this.state.localVideoDisabled ? "Enabled" : "Disabled"} video tracks.`);
				this.setState({localVideoDisabled: !this.state.localVideoDisabled});
				break;
			case "data":
				this.logError(`Will not enable/disable data track.`);
				break;
			default:
				this.logError(`Unknown track kind.`);
				break;
		}
	};

	private switchCamera = async (facingMode: "user" | "environment") => {
		if (!isMobile())
			return;

		const localParticipant = this.state.room?.localParticipant;
		if (!localParticipant) {
			this.logError(`Failed to get local participant. Can't enable/disable tracks.`);
			return;
		}

		const videoTracks = Array.from(localParticipant.videoTracks.values());
		this.logInfo(`Found ${videoTracks.length} video tracks.`, videoTracks);

		const restartPromises = videoTracks.map(async videoTrack => {
			const currentConstraints = videoTrack.track.mediaStreamTrack.getConstraints();
			this.logInfo(`Current constraints of video track ${videoTrack.trackSid}:`, currentConstraints);
			this.logInfo(`Setting facingMode to ${facingMode}.`);
			return videoTrack.track.restart({...currentConstraints, facingMode});
		});

		await Promise.all(restartPromises);
	};

	render = () => {
		const {isAdmin, unitContact, acceptingCall} = this.props;
		const {room, localVideoPaused, localAudioDisabled, localVideoDisabled, startTime} = this.state;

		if (!isAdmin && !unitContact)
			return <div>
				Couldn't find an ElliQ to call<br/>
				Please contact the support team
			</div>;

		if (!Video.isSupported)
			return <div>ElliQ video call is not supported for this browser yet.</div>;

		if (!room) {
			const remoteParticipantNameNoRoom = unitContact?.firstName.trim() || unitContact?.contactData.unitId;
			return <div className={wrapper}><Connecting remoteParticipantName={remoteParticipantNameNoRoom}/></div>;
		}

		const localParticipant: Video.LocalParticipant = room.localParticipant;
		const remoteParticipants: Video.RemoteParticipant[] = Array.from(room.participants.values());
		const remoteParticipantName = unitContact ? unitContact.firstName.trim() : remoteParticipants[0]?.identity.split('--').pop();

		const localVideoPublication = Array.from(room.localParticipant.videoTracks.values())[0];
		if (!room.participants.size && !startTime)
			return <Preview
				onCallMissedTimeout={this.onCallMissedTimeout}
				remoteParticipantName={remoteParticipantName}
				onDisconnect={this.disconnectFromRoom}
				toggleTracks={this.toggleTracks}
				localAudioDisabled={localAudioDisabled}
				localVideoPublication={localVideoPublication}
				acceptingCall={acceptingCall}
			/>;

		return (
			<div className={`${wrapper}`}>
				<div id={`room-container`} className={`match_all ${roomContainer}`}>
					{this.renderRemoteParticipants(room)}
					<Overlay
						isAdmin={isAdmin}
						disconnectFromRoom={this.disconnectFromRoom}
						remoteParticipantName={remoteParticipantName}
						localParticipant={localParticipant}
						localVideoPaused={localVideoPaused}
						toggleTracks={this.toggleTracks}
						onCameraSwitchClicked={this.switchCamera}
						localAudioDisabled={localAudioDisabled}
						localVideoDisabled={localVideoDisabled}/>
				</div>
			</div>
		);
	};

	private renderRemoteParticipants = (room: Video.Room) => {
		const {remoteVideoStopped} = this.state;
		const remoteParticipants: Video.RemoteParticipant[] = Array.from(room.participants.values());

		return (
			<div id={`participants-container`}
			     className={`match_all`}
			     style={{boxSizing: "border-box"}}>
				<RemoteParticipants
					videoStopped={remoteVideoStopped}
					remoteParticipants={remoteParticipants}/>
			</div>
		);
	};

};
