import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {ContactsList} from "../../contacts/ContactsList";

export class Playground_ContactsInfo extends BaseComponent {

  constructor(props: {}) {
    super(props);
  }

  render() {
      return <section style={{marginTop: "5mm", marginBottom: "5mm", width: "98vw"}}>
        <ContactsList/>
      </section>;
  }
}
