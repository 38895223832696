import * as emotion from 'emotion';

export const plusSign = emotion.css`
      border-radius: 20px;
      border: 2px dashed #eee;
      width: 200px;
      height: 200px;
      position: relative;
      &::after {
          content: " ";
          position: absolute;
          display: block;
          background-color: #eee;
          height: 2px;
          margin-top: -5px;
          top: 50%;
          left: 50px;
          right: 50px;
          z-index: 9;
       }
      &::before {
          content: " ";
          position: absolute;
          display: block;
          background-color: #eee;
          width: 2px;
          margin-left: -5px;
          left: 50%;
          top: 50px;
          bottom: 50px;
          z-index: 9;
      }
`;
