import {ThunderDispatcher} from "@intuitionrobotics/thunderstorm/app-frontend/core/thunder-dispatcher";
import {XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {HttpMethod} from "@intuitionrobotics/thunderstorm/shared/types";
import {Api_AddImageConfig, Api_QueryImageConfig, DB_ImageConfig} from "@app/app-shared/image-config";
import {Module} from "@intuitionrobotics/ts-common";

export interface OnImageConfigUpdated {
    __onImageConfigUpdated: () => void;
}

export interface OnGetImageConfig {
    __onGetImageConfig: () => void;
}

export const dispatch_onImageConfigUpdated = new ThunderDispatcher<OnImageConfigUpdated, "__onImageConfigUpdated">(
    "__onImageConfigUpdated");
export const dispatch_onGetImageConfig = new ThunderDispatcher<OnGetImageConfig, "__onGetImageConfig">(
    "__onGetImageConfig");

export class ImageConfigModule_Class
    extends Module<DB_ImageConfig> {

    private imageConfig: DB_ImageConfig[] = [];

    public getImageConfig() {
        return this.imageConfig;
    }

    public fetchImageConfig = () => {
        XhrHttpModule
            .createRequest<Api_QueryImageConfig>(HttpMethod.GET, "query")
            .setRelativeUrl("/v1/image-config/query")
            .setOnError(resError => {
                this.logError("Failed to get image config", resError);
            })
            .execute((response => {
                this.imageConfig = response;
                dispatch_onGetImageConfig.dispatchUI([]);
            }));
    };

    public updateImageConfig = (imageConfig: DB_ImageConfig[]) => {
        const imageConfigWithoutDefault = imageConfig.filter(item => item.userGroup !== 'default');
        XhrHttpModule
            .createRequest<Api_AddImageConfig>(HttpMethod.POST, "add")
            .setRelativeUrl("/v1/image-config/add")
            .setJsonBody(imageConfig)
            .setOnError(resError => {
                this.logError("Failed to update image config:", resError);
            })
            .execute((response) => {
                this.imageConfig = imageConfigWithoutDefault;
                dispatch_onImageConfigUpdated.dispatchUI([]);
            });
    }

}


export const ImageConfigModule = new ImageConfigModule_Class("ImageConfigModule");
