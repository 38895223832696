import * as React from "react";
import {DialogModule} from "@intuitionrobotics/thunderstorm/frontend";

type Props = {
	okTitle: string
	okPressed: () => void
	okEnabled?: boolean,
	showCancel?: boolean
}

export class DialogButtons
	extends React.Component<Props> {

	render() {

		return <div id={'dialogButtons'} className='ll_h_c' style={{
			justifyContent: "flex-end",
			paddingLeft: 25, paddingRight: 25, paddingBottom: 17, paddingTop: 30
		}}>
			{this.renderCancel()}
			<div style={{width: 13, height: 20}}/>
			{this.renderOk()}
		</div>;
	}

	private renderCancel = () => {
		const showCancel = this.props.showCancel;
		if (!showCancel)
			return;

		return <button onClick={DialogModule.close}>Cancel</button>;
	};

	private renderOk = () => {
		if (this.props.okEnabled === undefined ? true : this.props.okEnabled)
			return <button onClick={this.props.okPressed}>{this.props.okTitle}</button>;

		return <button style={{backgroundColor: "grey"}}>{this.props.okTitle}</button>;
	};

}
