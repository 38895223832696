import {
  BaseComponent,
  BrowserHistoryModule,
  GenericSelect,
  ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {
  PARAM_PRODUCT,
  PARAM_UNIT_CLOUD_ASSET_KEY,
  PARAM_UNIT_ID
} from "../../../Routes";
import {Unit} from "@app/ir-q-app-common/types/units";
import {
  Key_GetCloudAssetsPerUnit,
  OnCloudAssetUpdated,
  UnitCloudAssetsModule
} from "@modules/UnitCloudAssetsModule";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {DB_UnitCloudAsset} from "@app/app-shared/cloud-asset";
import {getMediaTag} from "../../../utils/utils";
import {Key_MessagingOrigin, Key_PicturePlan} from "@app/app-shared/cloud-asset";
import {Loader} from "../../../../widgets/Loader";
import {
  TypedMap
} from "@intuitionrobotics/ts-common";

type State = {
  selectedKey?: string
  selectedUnit?: Unit
  selectedAsset?: DB_UnitCloudAsset
}
export class Playground_ShowCloudAssets extends BaseComponent<{ }, State>
       implements OnRequestListener, OnCloudAssetUpdated, OnUnitsLoaded {

  private dontDelete: TypedMap<boolean> = {};

  constructor(props: {}) {
    super(props);

    const unitIdParam = BaseComponent.getQueryParameter(PARAM_UNIT_ID);
    const productParam = BaseComponent.getQueryParameter(PARAM_PRODUCT);
    const selectedKey = BaseComponent.getQueryParameter(PARAM_UNIT_CLOUD_ASSET_KEY);

    const unit = unitIdParam && productParam ? {unitId: unitIdParam, product: productParam} : undefined;
    if (unit)
      UnitCloudAssetsModule.queryUnitCloudAssets(unit);

    this.state = {
      selectedKey: selectedKey,
      selectedUnit: unit
    }
  }

  __onCloudAssetUpdated(): void {
    this.forceUpdate();
  }
  __onUnitsLoaded(): void {
    this.forceUpdate();
  }

  __onRequestCompleted = (key: string, success: boolean, requestData: string | undefined): void => {
    if (success && key === Key_GetCloudAssetsPerUnit)
      this.forceUpdate()
  };

  componentWillUnmount() {
    BrowserHistoryModule.removeQueryParam(PARAM_UNIT_ID)
    BrowserHistoryModule.removeQueryParam(PARAM_PRODUCT)
    BrowserHistoryModule.removeQueryParam(PARAM_UNIT_CLOUD_ASSET_KEY)
  }

  render() {
    return <div style={{width: "100%", height: 700}}>
      <div style={{width: 244, marginTop: 12, marginLeft: 15}}>
        <GenericSelect<Unit>
          options={CommonFEModule.getUnits()}
          selectedOption={this.state.selectedUnit}
          iconClose={"D"}
          iconOpen={"U"}
          onChange={(unit: Unit) => {
            BrowserHistoryModule.addQueryParam(PARAM_UNIT_ID, unit.unitId);
            BrowserHistoryModule.addQueryParam(PARAM_PRODUCT, unit.product);

            if (!UnitCloudAssetsModule.getUnitCloudAssets(unit, this.state.selectedKey))
              UnitCloudAssetsModule.queryUnitCloudAssets(unit);

            this.setState({selectedUnit: unit, selectedAsset: undefined});
          }}
          styles={{}}
          presentation={option => option.unitId}/>
      </div>
      <div style={{width: 244, marginTop: 12, marginLeft: 15}}>
        <GenericSelect<string>
          options={UnitCloudAssetsModule.getKeys()}
          selectedOption={this.state.selectedKey}
          iconClose={"D"}
          iconOpen={"U"}
          onChange={(key: string) => {
            BrowserHistoryModule.addQueryParam(PARAM_UNIT_CLOUD_ASSET_KEY, key);

            if (!UnitCloudAssetsModule.getUnitCloudAssets(this.state.selectedUnit, key))
              UnitCloudAssetsModule.queryUnitCloudAssets(this.state.selectedUnit);

            this.setState({selectedKey: key, selectedAsset: undefined});
          }}
          styles={{}}
          presentation={option => option}/>
      </div>
      <div style={{marginTop: "20px", marginLeft: "50px"}}>
        {this.state.selectedUnit && this.state.selectedKey && this.renderButtons(this.state.selectedUnit, this.state.selectedKey)}
      </div>
      <div style={{display: "flex"}}>
        {this.state.selectedUnit && this.state.selectedKey && this.renderUnitCloudAssets(this.state.selectedUnit, this.state.selectedKey)}
        {this.state.selectedAsset && this.renderAsset(this.state.selectedAsset)}
      </div>
    </div>;
  }

  private renderButtons(unit: Unit, unitCloudAssetKey: string) {
    const unitCloudAssets = UnitCloudAssetsModule.getUnitCloudAssets(unit, unitCloudAssetKey);

    if (!unitCloudAssets)
      return null;

    if (unitCloudAssetKey !== Key_PicturePlan)
      return null;

    return <>
      <button onClick={() => {
        if (!this.state.selectedUnit || !this.state.selectedKey)
          return;

        UnitCloudAssetsModule.deleteAllOthers(this.state.selectedUnit, this.state.selectedKey, Object.keys(this.dontDelete).filter(key => !!this.dontDelete[key]));
      }}>Delete all besides checks</button>
      <button style={{marginLeft: "5px"}} onClick={() => {
        if (!this.state.selectedUnit || !this.state.selectedKey)
          return;

        UnitCloudAssetsModule.resetUnitPicturePlan(this.state.selectedUnit);
      }}>Reset pictures for {this.state.selectedUnit?.unitId}</button>
    </>;

  }

  private renderUnitCloudAssets(unit: Unit, unitCloudAssetKey: string) {
    const unitCloudAssets = UnitCloudAssetsModule.getUnitCloudAssets(unit, unitCloudAssetKey);

    if (!unitCloudAssets)
      return <Loader/>

    return <ul>{unitCloudAssets.map(asset => <li key={asset._id} style={{cursor: "pointer", color: asset.metadata.origin === Key_MessagingOrigin ? "green" : "black"}}
                                                 onClick={() => this.setState({selectedAsset: asset})}>
      <input type="checkbox" onClick={() => this.dontDelete[asset._id] = !this.dontDelete[asset._id]} />
      <button style={{color: asset.metadata.origin === Key_MessagingOrigin ? "green" : "black"}} onClick={() => {
        if (!this.state.selectedUnit)
          return ToastModule.toastError("Select unit first");

        UnitCloudAssetsModule.deleteAsset(asset, this.state.selectedUnit);
      }}>X</button>
      {asset.metadata.promptTitle || asset.metadata.description || asset.metadata.subject || unitCloudAssetKey}</li>)}</ul>;

  }

  private renderAsset(asset: DB_UnitCloudAsset) {
    if (!asset.mimeType || !asset.signedUrl)
      return null

    if (asset.mimeType === "audio/amr")
      return <a href={asset.signedUrl}>Click to download the asset</a>

    return getMediaTag(asset.mimeType, asset.signedUrl)
  }
}
