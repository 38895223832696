import {
	BaseComponent,
	DialogModule,
	ToastModule,
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	CommunityCloudAssetsModule,
	OnGetCommunityAssets
} from "@modules/CommunityCloudAssetsModule";
import React from "react";
import {Loader} from "../../../../widgets/Loader";
import CommunityAssetComponent from "../../general-cloud-assets/CommunityAssetComponent";
import {DB_CommunityCloudAsset} from "@app/app-shared/cloud-asset";
import Calendar from 'react-calendar';

import '@styles/calendar.scss';
import {ConfirmDialog} from "../../ui/dialogs/ConfirmDialog";
import moment from "moment-timezone";
import {Day} from "@intuitionrobotics/ts-common";

type State = {
	showLoader: boolean,
	selectedRates: string[],
	date?: moment.Moment,
	showCalendar: boolean,
	showPopup: boolean,
	popupPosition: { x: number, y: number }
	isActiveExhibition: boolean
}

export class CommunityCloudAssetTable
	extends BaseComponent<{}, State>
	implements OnGetCommunityAssets {
	private readonly popupRef: React.RefObject<any>;
	private readonly calendarRef: React.RefObject<any>;
	
	__onGetCommunityAssets(selectedAssets?: DB_CommunityCloudAsset[]): void {
		const data = CommunityCloudAssetsModule.dbAssetsWithImages();
		const timestamp = CommunityCloudAssetsModule.dueDateTimestamp;
		const array = new Array(5);
		
		if (selectedAssets !== undefined) {
			selectedAssets.forEach((item) => {
				if (item.rate !== undefined) {
					array[item.rate - 1] = item._id;
				}
			})
		} else {
			data?.forEach(item => {
				if (item.rate !== undefined) {
					array[item.rate - 1] = item._id;
				}
			})
		}
		
		// @ts-ignore
		this.setState(() => {
			const isActiveExhibition = timestamp !== undefined && new Date(timestamp).getTime() > new Date().getTime();
			const stateToSet = {
				showLoader: false,
				selectedRates: array,
				timestamp: undefined,
				isActiveExhibition,
				date: timestamp ? this.getDate(timestamp - Day) : undefined
			};
			if (timestamp !== undefined) {
				return {...stateToSet, date: this.getDate(timestamp - Day)}
			}
			return stateToSet;
		});
	}
	
	constructor(props: any) {
		super(props);
		this.state = {
			isActiveExhibition: false,
			showLoader: true,
			selectedRates: new Array(5),
			date: undefined,
			showCalendar: false,
			showPopup: false,
			popupPosition: {x: 0, y: 0},
		};
		
		this.popupRef = React.createRef();
		this.calendarRef = React.createRef();
		this.handleClick = this.handleClick.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.onCancelExhibition = this.onCancelExhibition.bind(this);
		this.handleClickCalendar = this.handleClickOutsideCalendar.bind(this);
		this.handleClickOutsideCalendar = this.handleClickOutsideCalendar.bind(this);
		this.getDate = this.getDate.bind(this);
	}
	
	handleClick(event: { clientX: any; clientY: any; }) {
		const popupPosition = {x: event.clientX, y: event.clientY};
		this.setState({
			              showPopup: true,
			              popupPosition: popupPosition,
		              });
		document.addEventListener('mousedown', this.handleClickOutside);
	}
	
	onCancelExhibition() {
		ConfirmDialog.show(
			{
				okLabel: "Stop",
				onOk: () => {
					DialogModule.close();
					this.setState({showLoader: true, isActiveExhibition: false, showPopup: false});
					CommunityCloudAssetsModule.cancelExhibition();
				},
				title: "Stop current exhibition",
				displayMessage: `Are you sure you want to stop the current exhibition?`
			});
	}
	
	// getDate(date: Date) {
	//
	// 	const dateInNYtime = moment.tz('America/New_York');
	// 	dateInNYtime.set({ month: date.getMonth(), date: date.getDate(), hour: 23, minute: 59, second: 0, millisecond: 0 });
	// 	dateInNYtime.tz('America/New_York');
	//
	// 	return dateInNYtime
	// }
	
	getDate(timestamp: number) {
		const currentDate = new Date(timestamp)
		const date = moment.tz(timestamp, 'America/New_York');
		date.set({hours: 23, date: currentDate.getDate(), month: currentDate.getMonth()})
		
		return date;
	}
	
	handleClickOutside(event: { target: any; }) {
		if (this.popupRef.current && !this.popupRef.current.contains(event.target) && this.state.showPopup) {
			this.setState({showPopup: false});
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
	}
	
	handleClickCalendar(event: { target: any; }) {
		this.setState({
			              showCalendar: true,
		              });
		document.addEventListener('mousedown', this.handleClickOutsideCalendar);
	}
	
	handleClickOutsideCalendar(event: { target: any; }) {
		if (this.calendarRef.current && !this.calendarRef.current.contains(event.target) && this.state.showCalendar) {
			this.setState({showCalendar: false});
			document.removeEventListener('mousedown', this.handleClickOutside);
		}
	}
	
	componentDidMount() {
		CommunityCloudAssetsModule.fetchUrlsOfImages();
		document.addEventListener('mousedown', this.handleClickOutside);
		document.addEventListener('mousedown', this.handleClickOutsideCalendar);
	}
	
	onClick = (_id: string, position: number) => {
		let copiedArray = [...this.state.selectedRates];
		
		if (copiedArray[position - 1] === _id)
			copiedArray[position - 1] = "";
		else {
			copiedArray.forEach((item, index) => {
				if (item === _id)
					return copiedArray[index] = "";
			});
			
			copiedArray[position - 1] = _id;
		}
		
		this.setState({selectedRates: copiedArray})
	}
	
	sortFunction = (a: DB_CommunityCloudAsset, b: DB_CommunityCloudAsset) => {
		if (this.state.selectedRates.includes(a._id) && this.state.selectedRates.includes(b._id)) {
			return this.state.selectedRates.indexOf(a._id) - this.state.selectedRates.indexOf(b._id);
		} else if (this.state.selectedRates.includes(b._id))
			return 1;
		else if (this.state.selectedRates.includes(a._id))
			return -1;
		else
			return b._created - a._created;
	}
	
	validateSelection = () => {
		if (this.state.date === undefined) {
			return ToastModule.toastError("You haven't selected a date, please select one");
		}
		
		if (this.state.selectedRates.filter((item) => item === undefined || item === "").length > 0)
			return ToastModule.toastError("You have selected less than 5 images, please check your selections");
		else if (new Date().getTime() > this.state.date?.valueOf())
			return ToastModule.toastError("Selected date can not be in the past");
		
		const data = CommunityCloudAssetsModule.dbAssetsWithImages();
		
		ConfirmDialog.show(
			{
				okLabel: "Upload",
				onOk: () => {
					DialogModule.close();
					
					const selectedAssets = [];
					
					for (const [index, item] of this.state.selectedRates.entries()) {
						const asset = data?.find((_item) => _item._id === item);
						
						if (asset === undefined)
							return ToastModule.toastError("There was an error on the items you selected please try again");
						
						asset.rate = index + 1;
						selectedAssets.push(asset)
					}
					
					if (this.state.selectedRates.filter((item) => item === undefined || item === "").length > 0)
						return ToastModule.toastError("You have selected less than 5 images, please check your selections");
					else if (this.state.date) {
						this.setState({showLoader: true});
						CommunityCloudAssetsModule.setSelectedAssets(selectedAssets, this.state.date?.valueOf());
					}
				},
				title: "Upload community cloud assets",
				displayMessage: `Would you like to publish the selected images until the ${this.state.date && this.state.date.format('DD/MM/YYYY')}?`
			});
	}
	
	clearSelection = () => {
		this.setState({selectedRates: new Array(5)});
	}
	
	getDateForCalendar(date: moment.Moment | undefined) {
		if (!date)
			return new Date();
		
		return new Date(date.year(), date.month(), date.date())
	}
	
	render() {
		if (this.state.showLoader)
			return <Loader/>
		
		const data = CommunityCloudAssetsModule?.dbAssetsWithImages()?.sort(this.sortFunction);
		return <div style={{width: '98vw'}}>
			<text style={{fontWeight: '400', fontSize: 14}}>Last day of the exhibition</text>
			<div style={{display: 'flex', flexDirection: 'row', height: 60}}>
				<div style={{
					flexDirection: 'row',
					width: 700,
					borderRadius: 5,
					borderColor: '#53BAD5',
					borderStyle: "solid",
					marginBottom: 20,
					marginTop: 5,
					padding: 5,
					display: 'flex',
					justifyContent: 'space-between',
					cursor: 'pointer'
				}} onClick={() => {
					this.setState({showCalendar: !this.state.showCalendar})
				}}>
					<text style={{}}>{this.state.date && this.state.date.format('DD/MM/YYYY')}</text>
					<img style={{width: 18, height: 18}} alt={'image'} src={require("@res/icons/icon__calendar.png")}/>
				</div>
				<div style={{
					width: 120,
					padding: 10,
					backgroundColor: '#53BAD5',
					paddingLeft: 30,
					paddingRight: 30,
					marginBottom: 20,
					marginTop: 5,
					marginLeft: 10,
					borderRadius: 5,
					display: 'flex',
					justifyContent: 'center',
					cursor: 'pointer'
				}} onClick={this.clearSelection}>
					<text style={{color: 'white'}}>Clear Selection</text>
				</div>
				<div style={{
					width: 120,
					padding: 10,
					marginBottom: 20,
					marginTop: 5,
					borderRadius: 5,
					display: 'flex',
					justifyContent: 'center'
				}}>
					<text style={{color: this.state.isActiveExhibition ? 'green' : 'red'}}>
						{
							this.state.isActiveExhibition ? "Running" : "Not Running"
						}
					</text>
					<img style={{width: 18, height: 18, cursor: 'pointer'}} onClick={this.handleClick} ref={this.popupRef} alt={'image'}
					     src={require("@res/icons/icon__button.svg")}/>
					{
						this.state.showPopup && <div ref={this.popupRef} onClick={this.onCancelExhibition} style={{
							position: 'absolute',
							padding: 10,
							top: this.state.popupPosition.y - 50,
							left: this.state.popupPosition.x - 50,
							background: 'white',
							boxShadow: '0px 0px 5px 0px rgba(0,0,0,0.3)',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							cursor: 'pointer'
						}}>
												<img style={{width: 36, height: 36, cursor: 'pointer', marginRight: 10}} alt={'image'}
														 src={require("@res/icons/icon__stop.svg")}/>
												<text>Stop the exhibition</text>
											</div>
						
					}
				</div>
			</div>
			{this.state.showCalendar &&
							<div ref={this.calendarRef} style={{position: 'absolute', left: 500, backgroundColor: 'white', padding: 15, borderStyle: 'solid'}}>
								<Calendar onChange={(value) => {
					// @ts-ignore
					const date = new Date(value)
					this.setState({date: this.getDate(date.getTime())})
				}} value={this.getDateForCalendar(this.state.date)} className={'calendar'}/>
								<div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 10}}>
									<div style={{
					  width: 60,
					  padding: 10,
					  backgroundColor: '#53BAD5',
					  paddingLeft: 30,
					  paddingRight: 30,
					  borderRadius: 5,
					  display: 'flex',
					  justifyContent: 'center'
				  }} onClick={this.validateSelection}>
										<text style={{color: 'white', cursor: 'pointer'}}>Save</text>
									</div>
								</div>
							</div>}
			{
				data?.map((item) => {
					return <CommunityAssetComponent key={item._id} selectedItems={this.state.selectedRates} id={item._id} url={item.signedUrl || "image"}
					                                onClick={(_id: string, position: number) => {
						                                this.onClick(_id, position)
					                                }} unitId={item.unitId}
					                                createdAt={item._created}
					                                exhibitionDate={item.datePublished}
					/>
				})
			}
		</div>
	}
	
}
