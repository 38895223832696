import * as React from "react";
import {BaseComponent, TS_TextArea} from "@intuitionrobotics/thunderstorm/frontend";
import {Loader} from "../../../../widgets/Loader";
import {MessagesModule, OnImageTransfer} from "@modules/MessagesModule";

type State = {loading: boolean, value: string}

export class ImageTransfer
    extends BaseComponent<{}, State>
    implements OnImageTransfer{

    constructor(props: {}) {
        super(props);

        this.state = {
            loading: false,
            value: "" //url
        }
    }

    __onImageTransfer= () => {
        this.setState({loading: false})
    }

    loading = () => {
        return this.state.loading ? <Loader/> : null;
    };

    render() {
        return <div>
            <TS_TextArea type={"text"} onChange={(value, id) => this.setState({value: value})}/>
            <button onClick={() => {
                this.setState({loading: true});
                MessagesModule.imageTransfer(this.state.value);
            }}>touch here</button>
            {this.loading()}

        </div>
    }

}
