import * as React from "react";
import {
	registerRoutes,
	Route_Agent_User_Details, Route_Archive,
	Route_Contacts,
	Route_Contacts_Table,
	Route_Image_Transfer,
	Route_Playground,
	// Route_Video_Calling_InHouse
} from "./Routes";
import {globalStyleGuide} from "@res/fonts";
import {
	Dialog,
	PopupMenu,
	RoutingModule,
	Toaster,
	Tooltip
} from "@intuitionrobotics/thunderstorm/frontend";
import {EnvironmentModule} from "@modules/EnvironmentModule";
import {BugReport} from "@intuitionrobotics/bug-report/frontend";
import {ErrorBoundary} from "./utils/ErrorBoundary";
import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {PushPubSubModule} from "@intuitionrobotics/push-pub-sub/frontend";
import { PushKey_GotMessageByTwilio,PushKey_NewMessage } from "@app/app-shared/common";

export class DataFetchingComponent
	extends React.Component {
	constructor(props: {}) {
		super(props);
		AccountModule.listUsers();
	}

	componentDidMount() {
		PushPubSubModule.subscribeMulti([{pushKey: PushKey_GotMessageByTwilio}, {pushKey: PushKey_NewMessage}]);
	}

	componentWillUnmount() {
		[{pushKey: PushKey_GotMessageByTwilio}, {pushKey: PushKey_NewMessage}].forEach(sub => PushPubSubModule.unsubscribe(sub))
	}

	render() {
		registerRoutes();
		return <div className={globalStyleGuide}>
			<div>
				<ErrorBoundary>
					{this.renderRoutes()}
				</ErrorBoundary>
				<Dialog/>
				<Toaster/>
				{this.renderBugReport()}
				<PopupMenu/>
				<Tooltip/>
			</div>
			{/* <VersionDisplay/> */}
		</div>;
	}

	private renderRoutes = () => RoutingModule.getRoutesMap(
		[
			Route_Contacts,
			Route_Archive,
			Route_Contacts_Table,
			Route_Agent_User_Details,
			Route_Image_Transfer,
			// Route_Video_Calling_InHouse,
			Route_Playground
		]);

	private renderBugReport = () => {
		if (EnvironmentModule.getEnvName() === "prod")
			return;

		return <BugReport/>;
	};
}
