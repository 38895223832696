import * as React from "react";
import {BaseComponent, Dialog_Builder} from "@intuitionrobotics/thunderstorm/frontend";
import {ContactsTable} from "./ContactsTable";
import {UnitAndComment} from "@app/ir-q-app-common/types/units";

type Props = {
	unit?: UnitAndComment
}

export class Dialog_ContactsInfo
	extends BaseComponent<Props> {

	constructor(props: Props) {
		super(props);
	}

	public static show(props: Props) {
		new Dialog_Builder(
			<Dialog_ContactsInfo {...props} />)
			.setAllowIndirectClosing(true)
			.show();
	}

	render() {
		return <ContactsTable unit={this.props.unit}/>
	}
}
