import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
	Dialog_Builder,
	DialogModule
} from "@intuitionrobotics/thunderstorm/frontend";

const xButtonWrapper = emotion.css(
	// center-based-positioning
	{
		position: "absolute",
		top: 16,
		right: 16,
		width: 0,
		height: 0,
		"> div": {
			transform: "translate(-50%, -50%)"
		}
	}
);
type Props = {
	displayMessage: string
	title: string
	okLabel: string
	onOk: () => void
}
type State = {
	loading: boolean
}

const dialogCardStyle: React.CSSProperties = {
	minWidth: 500,
	maxWidth: 800,
	minHeight: 50,
	maxHeight: "80vh",
	margin: 64,
	borderRadius: 4,
	backgroundColor: "#fff",
	boxShadow: `0 4px 16px 0 rgba(0, 0, 0, 0.05)`
};

export class ConfirmDialog
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	public static show(props: Props) {
		return new Dialog_Builder(<ConfirmDialog {...props}/>)
			.clearInlineStyle()
			.setStyle(dialogCardStyle)
			.setOverlayColor(`rgb(42, 49, 56, 0.54)`) // COLORS.neutral_text_dark as rgb
			.setAllowIndirectClosing(true)
			.show();
	}

	render() {
		return <div
			style={{width: 500}}
		>
			{this.renderXButton()}
			{this.props.title}
			<div
				className={`match_width`}
				style={{height: 1, backgroundColor: "#ccced0"}}
			/>
			{this.props.displayMessage}
			{this.renderSaveCancel()}
		</div>;
	}

	private renderSaveCancel = () => {
		const saveEnabled = !this.state.loading;
		return <div className={"ll_h_c"}>
			<div style={{width: 32, pointerEvents: "none"}}/>
			<div className={"flex_center clickable"}
			     onClick={() => DialogModule.close()}
			     style={{
				     minHeight: 40,
				     minWidth: 120,
				     borderRadius: 4,
				     textAlign: "center",
				     border: `1px solid #566270`
			     }}>
				{"Cancel"}
			</div>
			<div style={{width: 16, pointerEvents: "none"}}/>
			<div className={"flex_center"}
			     onClick={() =>
				     saveEnabled && this.props.onOk()
			     }
			     style={{
				     backgroundColor: saveEnabled ? "#53bad5" : "#cbd3dd",
				     // border: `1px solid ${enabled ? COLORS.primary_blue : COLORS.neutral_gray_4}`, // also add border to be in sync with other buttons
				     cursor: saveEnabled ? "pointer" : "default",
				     opacity: saveEnabled ? 1 : 0.8,
				     minHeight: 40,
				     minWidth: 120,
				     borderRadius: 4,
				     textAlign: "center"
			     }}>
				{this.props.okLabel}
			</div>
		</div>;
	};

	private renderXButton = () => {
		return <div className={xButtonWrapper}>
			<div className={"clickable"}
			     style={{padding: 8, width: 9, lineHeight: 0}}
			     onClick={() => DialogModule.close()}
			>
				<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10">
					<path fill="#FFF"
					      d="M8.3.757L5 4.057 1.7.757a.667.667 0 0 0-.943.943l3.3 3.3-3.3 3.3a.667.667 0 0 0 .943.943l3.3-3.3 3.3 3.3a.667.667 0 0 0 .943-.943L5.943 5l3.3-3.3A.667.667 0 0 0 8.3.757"/>
				</svg>
			</div>
		</div>;

	};
}
