import * as React from "react";
import {
	ErrorHandler_Log,
	ThunderInstance
} from "./index";
import {App} from "./app/App";
import {
	ErrorHandler_Dispatch,
	WrapperProps,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {EnvironmentModule} from "@modules/EnvironmentModule";

ThunderInstance
	.setMainApp((props:WrapperProps) => <App {...props} isPWA={true}/>)
	.build(() => {
		XhrHttpModule.logInfo(`Version: ${require("../../dist/package.json")?.version}`, `Env: ${EnvironmentModule.getEnvName()}`);
		XhrHttpModule.setErrorHandlers([ErrorHandler_Dispatch, ErrorHandler_Log]);
	});
