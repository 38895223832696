import * as Video from "twilio-video";
import {RemoteVideoTrack} from "twilio-video/tsdef/RemoteVideoTrack";
import {RemoteTrackPublication} from "twilio-video/tsdef/RemoteTrackPublication";
import {RemoteDataTrack} from "twilio-video/tsdef/RemoteDataTrack";
import {Track} from "twilio-video/tsdef/Track";
import {
	NetworkQualityLevel,
	NetworkQualityStats,
	RemoteTrack
} from "twilio-video/tsdef/types";
import {TwilioError} from "twilio-video/tsdef/TwilioError";
import {Room} from "twilio-video/tsdef/Room";
import {RemoteParticipant} from "twilio-video/tsdef/RemoteParticipant";
import {VideoTrack} from "twilio-video/tsdef/VideoTrack";
import {
	SOM_IDENTITY_PREFIX,
	TABLET_IDENTITY_PREFIX
} from "@app/app-shared/twilio";

/**
 * Whether the web app is running on a mobile browser.
 * @type {boolean}
 */
export const isMobile = () => {
	if (typeof navigator === 'undefined') {
		return false;
	}

	return /Mobile|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const isIOS = () => {
	return [
		'iPad Simulator',
		'iPhone Simulator',
		'iPod Simulator',
		'iPad',
		'iPhone',
		'iPod'
	].includes(navigator.platform)

	// Triggers false positives for macbook.
	// // iPad on iOS 13 detection
	// || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
};

export const isSomParticipant = (participant: Video.RemoteParticipant) => {
	return participant.identity.startsWith(SOM_IDENTITY_PREFIX);
};

export const isTabletParticipant = (participant: Video.RemoteParticipant) => {
	return participant.identity.startsWith(TABLET_IDENTITY_PREFIX);
};

export const areRemoteParticipantsUnit = (remoteParticipants: Video.RemoteParticipant[]) => {
	if (remoteParticipants.length !== 2)
		return false;

	const somParticipant = remoteParticipants.find(
		participant => isSomParticipant(participant)
	);

	const tabletParticipant = remoteParticipants.find(
		participant => isTabletParticipant(participant)
	);

	return !!(somParticipant && tabletParticipant);
};

export const isValidJsonConfig = (value: string) => {
	if (value === "")
		return true;

	try {
		JSON.parse(value);
		return true;
	} catch (e) {
		return false;
	}
}

// Used for dev/debugging.
export const subscribeToAllRemoteParticipant = (participant: Video.RemoteParticipant) => {

	// Video.Participant events.
	participant.on('disconnected', (_participant: Video.Participant) => {
		console.log("disconnected", _participant);
	});
	participant.on('networkQualityLevelChanged', (networkQualityLevel: NetworkQualityLevel, networkQualityStats: NetworkQualityStats) => {
		console.log("networkQualityLevelChanged", networkQualityLevel, networkQualityStats);
	});
	participant.on('reconnected', (_participant: Video.Participant) => {
		console.log("reconnected", _participant);
	});
	participant.on('reconnecting', (_participant: Video.Participant) => {
		console.log("reconnecting", participant);
	});
	participant.on('trackDimensionsChanged', (track: VideoTrack) => {
		console.log("trackDimensionsChanged", track);
	});
	participant.on('trackStarted', (track: Track) => {
		console.log("trackStarted", track);
	});

	// Video.RemoteParticipant events.
	participant.on('disconnected', (_participant: Video.RemoteParticipant) => {
		console.log("disconnected called", _participant);
	});
	participant.on('trackDimensionsChanged', (track: RemoteVideoTrack) => {
		console.log("trackDimensionsChanged", track);
	});
	participant.on('trackDisabled', (track: RemoteTrackPublication) => {
		console.log("trackDisabled", track);
	});
	participant.on('trackEnabled', (track: RemoteTrackPublication) => {
		console.log("trackEnabled", track);
	});
	participant.on('trackMessage', (data: string | ArrayBuffer, track: RemoteDataTrack) => {
		console.log("trackMessage", data, track);
	});
	participant.on('trackPublished', (publication: RemoteTrackPublication) => {
		console.log("trackPublished", publication);
	});
	participant.on('trackPublishPriorityChanged', (priority: Track.Priority, publication: RemoteTrackPublication) => {
		console.log("trackPublishPriorityChanged", priority, publication);
	});
	participant.on('trackStarted', (track: RemoteTrack) => {
		console.log("trackStarted", track);
	});
	participant.on('trackSubscribed', (track: RemoteTrack, publication: RemoteTrackPublication) => {
		console.log("trackSubscribed", track, publication);
	});
	participant.on('trackSubscriptionFailed', (error: TwilioError, publication: RemoteTrackPublication) => {
		console.log("trackSubscriptionFailed", error, publication);
	});
	participant.on('trackSwitchedOff', (track: RemoteTrack, publication: RemoteTrackPublication) => {
		console.log("trackSwitchedOff", track, publication);
	});
	participant.on('trackSwitchedOn', (track: RemoteTrack, publication: RemoteTrackPublication) => {
		console.log("trackSwitchedOn", track, publication);
	});
	participant.on('trackUnpublished', (publication: RemoteTrackPublication) => {
		console.log("trackUnpublished", publication);
	});
	participant.on('trackUnsubscribed', (track: RemoteTrack, publication: RemoteTrackPublication) => {
		console.log("trackUnsubscribed", track, publication);
	});
};

// Used for dev/debugging.
export const subscribeToAllRoom = (room: Video.Room) => {
	room.on('disconnected', (_room: Room, error: TwilioError) => {
		console.log("disconnected", _room, error);
	});
	room.on('dominantSpeakerChanged', (dominantSpeaker: RemoteParticipant) => {
		console.log("dominantSpeakerChanged", dominantSpeaker);
	});
	room.on('participantConnected', (participant: RemoteParticipant) => {
		console.log("participantConnected", participant);
	});
	room.on('participantDisconnected', (participant: RemoteParticipant) => {
		console.log("participantDisconnected", participant);
	});
	room.on('participantReconnected', (participant: RemoteParticipant) => {
		console.log("participantReconnected", participant);
	});
	room.on('participantReconnecting', (participant: RemoteParticipant) => {
		console.log("participantReconnecting", participant);
	});
	room.on('reconnected', () => {
		console.log("reconnected");
	});
	room.on('reconnecting', (error: TwilioError) => {
		console.log("reconnecting", error);
	});
	room.on('recordingStarted', () => {
		console.log("recordingStarted");
	});
	room.on('recordingStopped', () => {
		console.log("recordingStopped");
	});
	room.on('trackDimensionsChanged', (track: RemoteVideoTrack, participant: RemoteParticipant) => {
		console.log("trackDimensionsChanged", track, participant);
	});
	room.on('trackDisabled', (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackDisabled", publication, participant);
	});
	room.on('trackEnabled', (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackEnabled", publication, participant);
	});
	room.on('trackMessage', (data: string | ArrayBuffer, track: RemoteDataTrack, participant: RemoteParticipant) => {
		console.log("trackMessage", data, track, participant);
	});
	room.on('trackPublished', (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackPublished", publication, participant);
	});
	room.on('trackPublishPriorityChanged', (priority: Track.Priority, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackPublishPriorityChanged", priority, publication, participant);
	});
	room.on('trackStarted', (track: RemoteTrack, participant: RemoteParticipant) => {
		console.log("trackStarted", track, participant);
	});
	room.on('trackSubscribed', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackSubscribed", track, publication, participant);
	});
	room.on('trackSubscriptionFailed', (error: TwilioError, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackSubscriptionFailed", error, publication, participant);
	});
	room.on('trackSwitchedOff', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackSwitchedOff", track, publication, participant);
	});
	room.on('trackSwitchedOn', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackSwitchedOn", track, publication, participant);
	});
	room.on('trackUnpublished', (publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackUnpublished", publication, participant);
	});
	room.on('trackUnsubscribed', (track: RemoteTrack, publication: RemoteTrackPublication, participant: RemoteParticipant) => {
		console.log("trackUnsubscribed", track, publication, participant);
	});
};