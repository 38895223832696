import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {ThunderDispatcher,ToastModule, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {BaseHttpRequest, HttpMethod} from "@intuitionrobotics/thunderstorm";
import {DB_UnitCloudAsset, Key_TimeCapsuleRecord, Key_PicturePlan, Key_MemoirRecord} from "@app/app-shared/cloud-asset";
import {
	Api_CloudGetAllUnitCloudAssets, Api_DeleteAllOthersUnitCloudAsset,
	Api_DeleteUnitCloudAsset, Api_NotifyUnitsToReloadPictures,
	Api_ResetUnitPicturePlan
} from "@app/app-shared/cloud-asset-api";

export const Key_GetCloudAssetsPerUnit = "get-unit-cloud-assets";

export interface OnCloudAssetUpdated {
	__onCloudAssetUpdated: () => void;
}

export const dispatch_onCloudAssetUpdated = new ThunderDispatcher<OnCloudAssetUpdated, "__onCloudAssetUpdated">("__onCloudAssetUpdated");

export class UnitCloudAssetsModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_UnitCloudAsset> {
	private cloudAssetsPerUnit: {[key: string] : {[key: string]: DB_UnitCloudAsset[]}} = {};

	queryUnitCloudAssets(unit?: Unit): BaseHttpRequest<any> | void {
		if (!unit)
			return

		return XhrHttpModule
			.createRequest<Api_CloudGetAllUnitCloudAssets>(HttpMethod.GET, Key_GetCloudAssetsPerUnit)
			.setRelativeUrl(`/v1/cloud-asset/get-all-unit-cloud-assets`)
			.setUrlParams({unitId: unit.unitId, product: unit.product})
			.setOnError(() => ToastModule.toastError("Could not fetch unit cloud assets"))
			.execute((response: DB_UnitCloudAsset[]) => {
				this.cloudAssetsPerUnit[unit.unitId] = {};

				response.forEach(asset => {
					if (!this.cloudAssetsPerUnit[unit.unitId][asset.key])
						this.cloudAssetsPerUnit[unit.unitId][asset.key] = []

					this.cloudAssetsPerUnit[unit.unitId][asset.key] = [...this.cloudAssetsPerUnit[unit.unitId][asset.key], asset]
				});
			})
	}

	deleteAllOthers(unit: Unit, key: string, dontDelete: string[]) : BaseHttpRequest<any> {
		return XhrHttpModule
			.createRequest<Api_DeleteAllOthersUnitCloudAsset>(HttpMethod.POST, "delete-others")
			.setRelativeUrl(`/v1/cloud-asset/delete-all-others`)
			.setJsonBody({unit, key, dontDelete})
			.setOnError(() => ToastModule.toastError("Could not delete unit cloud assets"))
			.execute(() => {
				this.cloudAssetsPerUnit[unit.unitId][key] = this.cloudAssetsPerUnit[unit.unitId][key].filter(asset =>
					                                                         dontDelete.find(dontDeleteId => dontDeleteId === asset._id));
				dispatch_onCloudAssetUpdated.dispatchUI([])
			})
	}

	resetUnitPicturePlan(unit: Unit): BaseHttpRequest<any> {
		return XhrHttpModule
			.createRequest<Api_ResetUnitPicturePlan>(HttpMethod.POST, "reset-picture-plan")
			.setRelativeUrl(`/v1/cloud-asset/reset-unit-picture-plan`)
			.setJsonBody({unit})
			.setOnError(() => ToastModule.toastError("Could not reset unit picture plan"))
			.execute(() => {
				this.cloudAssetsPerUnit[unit.unitId][Key_PicturePlan] = [];
				dispatch_onCloudAssetUpdated.dispatchUI([])
			})
	}

	getKeys() {
		return [Key_TimeCapsuleRecord, Key_PicturePlan, Key_MemoirRecord]
	}

	getUnitCloudAssets(unit?: Unit, unitCloudAssetKey?: string) {
		if (!unit || !unitCloudAssetKey)
			return;

		if (!this.cloudAssetsPerUnit || !this.cloudAssetsPerUnit[unit.unitId] || !this.cloudAssetsPerUnit[unit.unitId][unitCloudAssetKey])
			return;

		return this.cloudAssetsPerUnit[unit.unitId][unitCloudAssetKey];

	}

	protected onEntryDeleted(item: DB_UnitCloudAsset): Promise<void> {
		this.cloudAssetsPerUnit[item.unitId][item.key] = this.cloudAssetsPerUnit[item.unitId][item.key].filter(asset => asset._id !== item._id)
		dispatch_onCloudAssetUpdated.dispatchUI([])
		return super.onEntryDeleted(item);
	}

	public deleteAsset = (item: DB_UnitCloudAsset, unit: Unit) => {
		XhrHttpModule
			.createRequest<Api_DeleteUnitCloudAsset>(HttpMethod.POST, "delete-asset")
			.setRelativeUrl("/v1/cloud-asset/delete-unit-cloud-asset")
			.setJsonBody({_id: item._id, unit})
			.setOnError(resError => {
				this.logError("Failed to delete cloud asset:", resError);
			})
			.execute((async _ => {
				await this.onEntryDeleted(item).then(() => {});
			}));
	};

	public notifyUnits = () => {
		XhrHttpModule
			.createRequest<Api_NotifyUnitsToReloadPictures>(HttpMethod.POST, "notify-units-to-reload-pictures")
			.setRelativeUrl("/v1/cloud-asset/notify-units-to-reload-pictures")
			.setJsonBody({})
			.setOnError(resError => {
				ToastModule.toastError("Failed to notify units");
				this.logError("Failed to notify units:", resError);
			})
			.execute((async _ => {
				ToastModule.toastSuccess("Notified units");
			}));
	};

}

export const UnitCloudAssetsModule = new UnitCloudAssetsModule_Class({key: "unit-cloud-assets", relativeUrl: "/v1/unit-cloud-asset"}, "UnitCloudAssetsModule");
