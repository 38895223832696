import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {VideoCalling} from "../../video-calling/VideoCalling";
import {AdminPlaceCall} from "../../video-calling/components/AdminPlaceCall";
import * as emotion from "emotion";

type State = {
	inCall: boolean;
	videoConfig: string;
	audioConfig: string;
}

const wrapper = emotion.css`
    height: 80vh;
    width: 80vw;
`;


export class Playground_VideoCall
	extends BaseComponent<{}, State> {

	constructor(props: {}) {
		super(props);
		this.state = {
			inCall: false,
			videoConfig: '',
			audioConfig: '',
		};
	}

	private onCallStarted = () => {
		this.setState({inCall: true});
	};

	private onCallEnded = () => {
		this.setState({inCall: false});
	};

	private onCallFailed = () => {
		this.setState({inCall: false});
	};

	render = () => {
		const {inCall, audioConfig, videoConfig} = this.state;
		return <div className={wrapper}>
			{
				!inCall
					?
					<AdminPlaceCall
						onSubmit={this.onCallStarted}
						audioConfig={this.state.audioConfig}
						videoConfig={this.state.videoConfig}
						onAudioConfigUpdated={(newAudioConfig: string) => this.setState({audioConfig: newAudioConfig})}
						onVideoConfigUpdated={(newVideoConfig: string) => this.setState({videoConfig: newVideoConfig})}/>
					:
					<div className={`match_all`}>
						<VideoCalling
							isAdmin={true}
							onCallEnded={this.onCallEnded}
							onCallFailed={this.onCallFailed}
							audioConfig={audioConfig}
							videoConfig={videoConfig}
							canInstall={false}
							installClicked={
								async () => {
								}
							}/>
					</div>
			}
		</div>;
	};
}
