import {FirebaseListenerModule_Class} from "@modules/FirebaseListenerModule";

class TaskModuleListener_Class extends FirebaseListenerModule_Class {

	constructor() {
		super("tasks", "TaskModuleListener");
	}

	init() {
		super.init();
	}
}

export const TaskModuleListener = new TaskModuleListener_Class();
