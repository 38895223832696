import {
	BaseComponent, DialogModule,
	GenericSelect, ToastModule,
	TS_Checkbox, TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {Loader} from "../../../widgets/Loader";
import * as React from "react";
import {Unit} from "@app/ir-q-app-common/types/units";
import {
	CloudAssetsModule, OnCloudAssetCreated,
	OnGeneralCloudAssetUpdated,
	OnGetImageWithStorageUrls
} from "@modules/CloudAssetsModule";
import * as emotion from "emotion";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import {DB_CloudAsset, makeCacheId} from "@app/app-shared/cloud-asset";
import {butterscotch, dark} from "@styles/colors";
import {_FilesType} from "../ui/file-components/Drag&Drop";
import DragnDrop from "../ui/file-components/Drag&Drop";
import {UploaderModule} from "@intuitionrobotics/file-upload/frontend";
import {postProcessorImageKey} from "@app/app-shared/media-resource";
import {FileStatus, OnFileStatusChanged} from "@intuitionrobotics/file-upload/shared/modules/BaseUploaderModule";
import {
	_values,
	auditBy,
	currentTimeMillies,
	PartialProperties
} from "@intuitionrobotics/ts-common";
import {UnitCloudAssetsModule} from "@modules/UnitCloudAssetsModule";
import {ConfirmDialog} from "../ui/dialogs/ConfirmDialog";
import {ComputerVisionAnnotation} from "@app/ir-q-app-common/types/computer-vision-tags";
import CloudAssetForm, {INTENT_PARAMETERS_NAME, LANDMARKS_NAME, TAGS_NAME} from "./CloudAssetForm";
import {CloudAssetGallery} from "./CloudAssetGallery";
import ImageConfigTable from "./ImageConfigTable";
import {ImageConfigModule, OnGetImageConfig} from "@modules/ImageConfigModule";

type State = {
	showLoader: boolean,
	selectedKey: string,
	selected: Set<string>,
	queryString: string,
	showOnlySelected: boolean,
	showOnlyHidden: boolean,
	showOnlyNotHidden: boolean,
	showOnlySuggestionCards: boolean,
	sort: "dateOldestFirst" | "dateNewestFirst" | "sizeLargestFirst" | "sizeSmallestFirst" | "",
	files: File[],
	showGallery: boolean,
	showUserGroupPercentages: boolean
}

type Props = {
	unit?: Unit,
}

export class CloudAssetsTable
	extends BaseComponent<Props, State>
	implements OnGeneralCloudAssetUpdated, OnGetImageWithStorageUrls, OnFileStatusChanged, OnCloudAssetCreated, OnGetImageConfig {

	constructor(props: Props) {
		super(props);
		this.state = {
			selectedKey: 'picture-plan',
			showLoader: true,
			selected: new Set<string>(),
			queryString: '',
			showOnlySelected: false,
			showOnlyHidden: false,
			showOnlyNotHidden: false,
			showOnlySuggestionCards: false,
			sort: "dateNewestFirst",
			files: [],
			showGallery: false,
			showUserGroupPercentages: false
		};
	}

	componentDidMount(): void {
		ImageConfigModule.fetchImageConfig();
		CloudAssetsModule.fetchUrlsOfImages();
		if (!DataEnvsModule.getItems().length)
			DataEnvsModule.query()
	}

	__onGetImageConfig = () => this.forceUpdate();

	__onGetImageWithStorageUrls = () => this.setState({showLoader: false});

	__onGeneralCloudAssetUpdated = () => this.setState({showLoader: false });

	__onCloudAssetCreated = () => this.forceUpdate();

	__onFileStatusChanged = async (feId?: string) => {
		if (!feId)
			return;

		const fileInfo = UploaderModule.getFullFileInfo(feId);
		if (!fileInfo?.tempDoc || fileInfo.status !== FileStatus.PostProcessing)
			return;

		const getHeightAndWidthFromDataUrl = (dataURL: string) => new Promise<{height: number, width: number}>(resolve => {
			const img = new Image()
			img.onload = () => {
				resolve({
					height: img.height,
					width: img.width
				})
			}
			img.src = dataURL
		})

		const file = CloudAssetsModule.getFile(feId);
		const fileAsDataURL = window.URL.createObjectURL(file)
		const imageDimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

		const bucketPointer = {
			path: fileInfo.tempDoc.path,
			bucketName: fileInfo.tempDoc.bucketName
		};

		const cacheId = makeCacheId(bucketPointer);
		const cloudAsset: PartialProperties<DB_CloudAsset, "_id"> = {
			mimeType: fileInfo.tempDoc.mimeType,
			_created: currentTimeMillies(),
			_audit: auditBy("Upload cloud-asset messaging frontend"),
			key: this.state.selectedKey,
			metadata: {
				description: '',
				subject: '',
				location: '',
				photographer: '',
				fileName: file.name,
				fileSize: file.size,
				imageHeight: imageDimensions.height,
				imageWidth: imageDimensions.width,
			},
			bucketPointer: bucketPointer,
			cacheId
		}
		this.logInfo("creating cloud asset", {cloudAsset})
		CloudAssetsModule.replaceFileInCache(feId, cacheId);
		CloudAssetsModule.create(cloudAsset);
	}

	validateFiles = (files: File[]): _FilesType => {
		const allowedExtensions = ['jpg', 'jpeg', 'png'];
		if (files.length === 0) {
			ToastModule.toastError("no files");
			return {
				accepted: [] as File[],
				rejected: files,
				uploaded: [] as File[]
			};
		}

		const reply: _FilesType = {
			accepted: [],
			rejected: [],
			uploaded: [],
		};
		for (const file of files) {
			const extension = file.name.split('.').pop()?.toLowerCase();
			if (extension && allowedExtensions.includes(extension)) {
				reply.accepted.push(file);
			}
			reply.rejected.push(file);
			ToastModule.toastError(`Rejected file ${file.name}`)
		}
		return reply;
	};

	onAssetSelected = (key: string, prevChecked: boolean): void => {
		this.setState((prevState) => {
			const currState = {...prevState};
			if (prevChecked) {
				currState.selected.delete(key);
			} else {
				currState.selected.add(key);
			}
			return currState;
		})
	}
	onDragAndDrop = async (files: _FilesType) => {
		files.accepted.forEach(file => {
			const [f] = UploaderModule.upload([file], postProcessorImageKey, true)
			CloudAssetsModule.setFileInCache(f.feId, file)
		}) ;
		this.setState((prevState) => {
			const currState = {...prevState};
			currState.files.concat(files.accepted);

			return currState;
		})
		if (files.accepted.length > 0)
			ToastModule.toastSuccess(`Adding ${files.accepted.length} files.`)
	};

	private renderFileInfo(asset: DB_CloudAsset) {
		return <td>
			<div className={emotion.css`
								margin: 5px;
								`}>
									<span className={emotion.css`
										display: block;
									`}>
										<input
											className={emotion.css`
												width: 100%;
												text-overflow:ellipsis;`
											}
											value={asset.bucketPointer.bucketName}
											disabled={true}
										/>
									</span>
				<button
					onClick={
						() => {
							navigator.clipboard
							.writeText(asset.bucketPointer.bucketName)
							.then(() => {})
							.catch((e) => {this.logError(e);})
						}
					}>
					Copy Text
				</button>
			</div>
			<div className={emotion.css`
								margin: 5px;
								`}>
									<span className={emotion.css`
										display: block;
									`}>
										<input
											className={emotion.css`
												width: 100%;
												text-overflow:ellipsis;`
											}
											value={asset.bucketPointer.path}
											disabled={true}
										/>
									</span>
				<button
					onClick={
						() => {
							navigator.clipboard
								.writeText(asset.bucketPointer.path)
								.then(() => {})
								.catch((e) => {this.logError(e);})
						}
					}>
					Copy Text
				</button>
			</div>
		</td>

	}

	private renderSearchBar() {
		return <div className={'cloud-asset-search'}>
			<div className={'cloud-asset-search-bar'}>
				<TS_Input
					id={'search-bar'}
					type={'text'}
					placeholder={'search'}
					value={this.state.queryString}
					onChange={(value: string) => {
						this.setState({queryString: value});
					}}
				/>
			</div>
		</div>
	}

	private calculateAssets(assets: DB_CloudAsset[]) {
		return assets.filter((val) => {
			if(this.state.queryString) {
				return Object.keys(val.metadata).some((key: string) => {
					const isMatch = (item: string) => item.toString().toLowerCase().match(`${this.state.queryString.toLowerCase()}.*`);
					if (key === INTENT_PARAMETERS_NAME) {
						return Object.keys(val.metadata[key]).some(isMatch) ||
							_values(val.metadata[key]).some(isMatch);
					}

					if (key === TAGS_NAME || key === LANDMARKS_NAME)
						return val.metadata[key]?.some((annotation: ComputerVisionAnnotation) => isMatch(annotation.description));

					return isMatch(val.metadata[key]);
				})
			}
			return true;
		}).filter((val) => {
			if (this.state.showOnlySelected) {
				return this.state.selected.has(val._id);
			}
			if (this.state.showOnlyHidden) {
				return val.isHidden;
			}
			if (this.state.showOnlySuggestionCards){
				return !!(val.metadata.isSuggestion);
			}
			return true;
		}).sort(this.getSortingFilter());
	}

	private renderAssetsTable() {
		const assets = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey);
		if (!assets.length)
			return <Loader/>;

		const filteredAssets = this.calculateAssets(assets);

		return <div>
			<div className={emotion.css`
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
			`}>
				<table
					className='cloud-asset-table'
				>
					<thead className={emotion.css`
						background-color: ${butterscotch};
						border: 2px solid ${dark};
						border-radius: 18px;
					`}>
					<tr>
						<th style={{width: '5%'}}></th>
						<th style={{width: '75%'}}> Information</th>
						<th style={{width: '20%'}}> Bucket Pointer </th>
					</tr>
					</thead>
					<tbody>
					{filteredAssets.map((asset) => {
						return <tr key={asset._id}>
							<td align={"center"}> <TS_Checkbox value={asset._id} checked={this.state.selected.has(asset._id)} onCheck={this.onAssetSelected} label={""} /></td>
							<td> <CloudAssetForm selectedKey={this.state.selectedKey} assetId={asset._id}/> </td>
							{this.renderFileInfo(asset)}
						</tr>
					})}
					</tbody>

				</table>
			</div>
			</div>
	}
	private renderActionButtons = () => {
		return (
			<div className={'cloud-asset-search-actions'}>
					{
						this.state.selected.size > 0 ?
							<>
								<button className={'cloud-asset-action-button'} style={{backgroundColor: "red"}} onClick={() => {
									ConfirmDialog.show(
										{
											okLabel: "Delete",
											onOk: () => {
												DialogModule.close();
												this.deleteSelected();
											},
											title: "Delete Cloud Assets",
											displayMessage: `Are you sure you want to delete selected cloud assets?`
										});
								}}>
									Delete Selected
								</button>
								<button className={'cloud-asset-action-button'} onClick={() => this.changeSelectedIsHiddenStatus(true)} > Hide selected content </button>
								<button className={'cloud-asset-action-button'} onClick={() => this.changeSelectedIsHiddenStatus(false)} > Unhide selected content </button>
							</>
							: null
					}
					<button className={'cloud-asset-action-button'} style={{backgroundColor: "orange"}} onClick={() => {
						ConfirmDialog.show(
							{
								okLabel: "Publish",
								onOk: () => {
									DialogModule.close();
									this.notifyUnits();
								},
								title: "Publish to all units ASAP",
								displayMessage: `Are you sure you want to publish to all units ASAP?`
							});
					}} >
						Publish ASAP
					</button>
			</div>
		);
	}

	private renderSorting = () => {
		return (
			<div className={'cloud-asset-search-sorting'}>
				<div className={'cloud-asset-search-actions-left'}>
					<button
						className={(this.state.sort === 'dateNewestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
						onClick={() => this.setState({sort: 'dateNewestFirst'})}>
						Sort by date newest
					</button>
					<button
						className={(this.state.sort === 'dateOldestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
						onClick={() => this.setState({sort: 'dateOldestFirst'})}>
						Sort by date oldest
					</button>
					<button
						className={(this.state.sort === 'sizeLargestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
						onClick={() => this.setState({sort: 'sizeLargestFirst'})}>
						Sort by size largest
					</button>
					<button
						className={(this.state.sort === 'sizeSmallestFirst') ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
						onClick={() => this.setState({sort: 'sizeSmallestFirst'})}>
						Sort by size smallest
					</button>
					<button
						className={(this.state.showUserGroupPercentages) ? 'cloud-asset-sorting-button-active' : 'cloud-asset-sorting-button'}
						onClick={() => this.setState(oldState => ({showUserGroupPercentages: !oldState.showUserGroupPercentages}))}>
						{(this.state.showUserGroupPercentages) ? "Hide group %" : "Show group %"}
					</button>
				</div>
				<div className={'cloud-asset-search-actions-right'}>
					<div>
						<TS_Checkbox
							value={this.state.showOnlySuggestionCards}
							checked={this.state.showOnlySuggestionCards}
							label={'Show only suggestion cards'}
							onCheck={(_, prevChecked: boolean) => {
								this.setState({showOnlySuggestionCards: !prevChecked})
							}}
						/>
					</div>
					<div>
						<TS_Checkbox
							value={this.state.showOnlySelected}
							checked={this.state.showOnlySelected}
							label={'Show only selected'}
							onCheck={(_, prevChecked: boolean) => {
								this.setState({showOnlySelected: !prevChecked})
							}}
						/>
					</div>
					<div>
						<TS_Checkbox
							value={this.state.showOnlyHidden}
							checked={this.state.showOnlyHidden}
							label={'Show only hidden'}
							onCheck={(_, prevChecked: boolean) => {
								this.setState({showOnlyHidden: !prevChecked})
							}}
						/>
					</div>
					<div>
						<TS_Checkbox
							value={!this.state.showGallery}
							checked={!this.state.showGallery}
							label={'List View'}
							onCheck={(_) => {
								this.setState({showGallery: false})
							}}
						/>
					</div>
					<div>
						<TS_Checkbox
							value={this.state.showGallery}
							checked={this.state.showGallery}
							label={'Gallery View'}
							onCheck={(_) => {
								this.setState({showGallery: true})
							}}
						/>
					</div>
				</div>
			</div>
		);
	}

	private renderImageConfigTable = () => {
		return <ImageConfigTable imageConfig={ImageConfigModule.getImageConfig()}></ImageConfigTable>
	}


	private deleteSelected = () => {
		if (this.state.selected.size === 0)
			return;

		this.setState({
			showLoader: true,
			showOnlyHidden: false,
			showOnlyNotHidden: false,
			showOnlySelected: false,
			selected: new Set()
		});

		this.state.selected.forEach((id) => {
			const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey).filter((a) => a._id === id)[0];
			if (!asset)
				return;
			CloudAssetsModule.delete(id);
		});
		this.setState({selected: new Set()});
	}

	private changeSelectedIsHiddenStatus = (b: boolean) => {
		if (this.state.selected.size === 0)
			return;
		this.setState({
			showLoader: true,
			showOnlyHidden: false,
			showOnlyNotHidden: false,
			showOnlySelected: false,
			selected: new Set()
		});
		this.state.selected.forEach((id) => {
			const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey).filter((a) => a._id === id)[0];
			if (!asset)
				return;

			asset.isHidden = b;
			CloudAssetsModule.update(asset);
		});
	}

	private notifyUnits = () => {
		UnitCloudAssetsModule.notifyUnits();
		return;
	}

	render() {
		if (this.state.showLoader)
			return <Loader/>;

		return <div style={{marginTop: 50, height: '100%'}}>
			<DragnDrop onChange={this.onDragAndDrop} validate={this.validateFiles} height={'100%'}>
				<div style={{width: 300}}>
					<GenericSelect<string>
						options={["time-capsule-prompt",
							"picture-plan"]}
						selectedOption={this.state.selectedKey}
						iconClose={"D"}
						iconOpen={"U"}
						onChange={(key: string) => {
							this.setState({...this.state, selectedKey: key});
						}}
						styles={{}}
						presentation={option => option}
					/>
				</div>
					{this.renderActionButtons()}
					{this.renderSearchBar()}
					{this.renderSorting()}
					{(this.state.showUserGroupPercentages) && this.renderImageConfigTable()}
				{
					this.state.showGallery? this.renderGallery() : this.renderAssetsTable()
				}
			</DragnDrop>
		</div>;
	}

	private getSortingFilter = () => {
		switch (this.state.sort) {
			case 'dateNewestFirst':
				return (p1: DB_CloudAsset, p2: DB_CloudAsset) => {
					return p2._created - p1._created;
				}
			case 'dateOldestFirst':
				return (p1: DB_CloudAsset, p2: DB_CloudAsset) => {
					return p1._created - p2._created;
				}
			case 'sizeLargestFirst':
				return (p1: DB_CloudAsset, p2: DB_CloudAsset) => {
					return (p2.metadata.fileSize | 0) - (p1.metadata.fileSize | 0);
				}
			case 'sizeSmallestFirst':
				return (p1: DB_CloudAsset, p2: DB_CloudAsset) => {
					return (p1.metadata.fileSize | 0) - (p2.metadata.fileSize | 0);
				}
			default:
				return (p1: DB_CloudAsset, p2: DB_CloudAsset) => {
					return p2._created - p1._created;
				}
		}
	}
	private renderGallery = () => {
		const assets = CloudAssetsModule.getDbAssetsWithImagesByKey(this.state.selectedKey);
		if (!assets.length)
			return <Loader/>;

		const filteredAssets = this.calculateAssets(assets);

		return <div style={{margin: "10px"}}>
			<CloudAssetGallery assets={filteredAssets}  selectedKey={this.state.selectedKey} onSelectImage={(selectedAssets) => {
				this.setState({selected: new Set(selectedAssets)});
			}} />
		</div> ;
	}
}
