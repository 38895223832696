import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
	DialogModule,
} from "@intuitionrobotics/thunderstorm/frontend";
import {COLORS} from "@res/colors";
import {Dialog_VideoCallMenu} from "./Dialog_VideoCallMenu";
import {StorageKey_PWAUserName} from "@modules/ContactLoginFEModule";
import {Color} from "csstype";
import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {FONTS} from "@res/fonts";
import {DB_Contact_View} from "@app/ir-q-app-common/types/db-contact-view";

const icon__E_orange = require("@res/icons/icon__E_orange.svg");
const icon__download_app = require("@res/icons/icon__download_app.svg");
const icon__elliq_connect_logo = require('@res/icons/icon__elliq_connect_logo_white.svg');

type State = {};

type Props = {
	callEndedBackClicked: () => void;
	redialClicked: () => void;
	installClicked: () => Promise<void>;
	canInstall: boolean;
	unitContact?: DB_Contact_View<"agentUser">
};

const wrapper = emotion.css(
	{
		boxSizing: "border-box",
		backgroundColor: COLORS.salmon(),
		position: "relative",
	}
);

const header = emotion.css(
	{
		justifyContent: "space-between",
		boxSizing: "border-box",
		padding: 16
	}
);

const body = emotion.css(
	{
		boxSizing: "border-box",
		marginTop: 64
	}
);

const redial = emotion.css(
	{
		boxSizing: "border-box",
		width: 128,
		height: 42,
		justifyContent: "center",
		backgroundColor: COLORS.white(),
		borderRadius: 8,
		boxShadow: `0px 2px 4px 2px ${COLORS.dark_salmon(0.5)}`,
		marginTop: 72
	}
);

const installInstructions = emotion.css(
	{
		boxSizing: "border-box",
		backgroundColor: COLORS.white(),
		borderRadius: 15,
		padding: 24,
		margin: "auto 24px 24px 24px"
	}
);

const logoE = emotion.css(
	{
		boxSizing: "border-box",
		width: 60,
		height: 60,
		filter: "drop-shadow(2px 4px 7px rgba(0, 0, 0, 0.15))",
		marginBottom: 24
	}
);

const download = emotion.css(
	{
		boxSizing: "border-box",
		marginTop: "40",
		height: 32,
	}
);

const genericInstructions = emotion.css(
	{
		boxSizing: "border-box",
		marginTop: "24",
	}
);

const circle = (size: number, bgColor: Color) => emotion.css(
	{
		width: size,
		height: size,
		borderRadius: "50%",
		backgroundColor: bgColor,

		display: "flex",
		flexFlow: "column",
		alignItems: "center",
		justifyContent: "center",
	}
);


export class MissedCall
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	private onLogoutClick = () => {
		DialogModule.close();
		AccountModule.logout();
	};


	render = () => {
		const {unitContact} = this.props;
		if (!unitContact || unitContact.deleted)
			return this.renderNoContact();

		return <div className={`match_all ll_v_c ${wrapper}`}>
			{this.renderHeader()}
			{this.renderBody()}
			{this.renderInstallInstructions()}
		</div>;
	};

	private renderHeader = () => {
		return (
			<div id={`place-call-header`} className={`ll_h_c match_width ${header}`}>
				<div className={`ll_h_c`} style={{height: 40}} onClick={this.props.callEndedBackClicked}>
					<img src={icon__elliq_connect_logo} alt={"Logo ElliQ Connect"}/>
				</div>
				<div className={`${circle(32, COLORS.white(0.6))}`}
				     onClick={() => {
					     Dialog_VideoCallMenu.show(this.onLogoutClick);
				     }}>
					<div className={`ll_h_c`} style={{cursor: "default"}}>
						{FONTS.centra_no2_book(StorageKey_PWAUserName.get("=").charAt(0), COLORS.brand_blue(), 16)}
					</div>
				</div>
			</div>
		);
	};

	private renderBody = () => {
		const {redialClicked} = this.props;
		return (
			<div id={`call-ended-body`}
			     className={`ll_v_c ${body}`}>
				{FONTS.centra_no2_bold("No Answer", "white", 45)}
				<div className={`${redial} ll_h_c`}
				     onClick={redialClicked}>
					{FONTS.centra_no2_book("Redial", COLORS.brand_blue(), 20)}
				</div>
			</div>
		);
	};

	private renderInstallInstructions = () => {
		return <div className={`ll_v_c ${installInstructions}`}>
			<div className={`${logoE}`}>
				<img className={`match_all`} src={icon__E_orange} alt={"ElliQ"}/>
			</div>
			{
				this.props.canInstall ?
					this.renderSingleClickInstall() :
					this.renderBrowserBasedInstallInstructions()
			}
		</div>;
	};

	private renderSingleClickInstall = () => {
		return (
			<>
				<div>
					{FONTS.centra_no2_book("Would you like to add a shortcut to<br/>ElliQ connect on your home screen?", "black", 16)}
				</div>
				<div className={`ll_h_c ${download}`}
				     onClick={this.props.installClicked}>
					<div style={{boxSizing: "border-box", marginRight: 14, width: 18, height: 18}}>
						<img className={`match_all`} src={icon__download_app} alt={`Download app`}/>
					</div>
					<div>
						{FONTS.centra_no2_book("Save to home screen", COLORS.brand_blue(), 16)}
					</div>
				</div>
			</>
		);
	};

	private renderBrowserBasedInstallInstructions = () => {
		return <>
			<div>
				{FONTS.centra_no2_book("Add a shortcut to your home screen:", "black", 16)}
			</div>
			<div className={`ll_h_c ${genericInstructions}`}>
				{FONTS.centra_no2_book("Open the menu of your browser and select install or save to home screen.", "black", 16)}
			</div>
		</>;
	};

	private renderNoContact = () => {
		return <div>
			Couldn't find an ElliQ to call<br/>
			Please contact the support team
		</div>;
	};

}
