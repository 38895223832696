import * as React from "react";
import {Key_SendMMS, MessagesModule} from "@modules/MessagesModule";
import {Loader} from "../../../../widgets/Loader";
import {
	BaseComponent,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import {Body_SendMMS} from "@app/app-shared";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";

type State = {
	message: Body_SendMMS,
	loading: boolean
}

export class Playground_SendMmsTest
	extends BaseComponent<{}, State> implements OnRequestListener {

	constructor(props: {}) {
		super(props);
		this.state = {
			loading: false,
			message: {
				path: '',
				bucketName: '',
				mimeType: '',
				phoneNumber: '',
				format: ''
			}
		};
	}

	__onRequestCompleted = (key: string, success: boolean) => {
		switch (key) {
			default:
				return;

			case Key_SendMMS:
				this.setState({loading: false});
		}
	};

	loading = () => {
		return this.state.loading ? <Loader/> : null;
	};

	render() {
		const {path, phoneNumber} = this.state.message
		const disabled = !phoneNumber || !path;
		return <div style={{width: '98vw'}}>
			<h3>Send MMS Message</h3>
			<section style={{display: "flex", marginTop: "5mm", marginBottom: "5mm"}}>
				{this.renderInput('phoneNumber')}
				{this.renderInput('mimeType')}
				{this.renderInput('path')}
				{this.renderInput('format')}
				{this.renderInput('bucketName')}
			</section>
			<button disabled={disabled}
			        onClick={async () => {
				        this.setState({loading: true});
				        MessagesModule.sendMMS(this.state.message);
			        }}>Send
			</button>
			{this.loading()}
		</div>;
	}

	private renderInput = (key: keyof Body_SendMMS) => <TS_Input
		placeholder={key.toString()}
		type={"text"}
		value={this.state.message[key]}
		id={key.toString()}
		onChange={(value) => {
			this.setState(state => ({
				message: {
					...state.message,
					[key]: value?.trim() || ""
				}
			}))
		}
		}/>;

}
