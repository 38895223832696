import {
	BaseComponent,
	TS_Checkbox,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {StartVideoCall} from "../forms/StartVideoCall";
import {VideoRoomJoin} from "../forms/VideoRoomJoin";
import * as emotion from "emotion";
import {
	AccountModule,
	LoggedStatus
} from "@intuitionrobotics/user-account/frontend";
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {isValidJsonConfig} from "../videoCallUtils";
import {
	ContactsModule,
	OnContactsUpdated
} from "@modules/ContactsModule";
import {Loader} from "../../../../widgets/Loader";
import {TwilioVideoModule} from "@modules/TwilioVideoModule";

const wrapper = emotion.css(
	{
		width: "100%",
		height: "100%"
	}
);

const input = emotion.css(
	{
		border: `1px solid black`,
		padding: 5,
		margin: 10,
		boxSizing: "border-box"
	}
);

type State = {
	loader: boolean;
	twilioLoggerEnabled: boolean;
};

type Props = {
	onSubmit: () => void;
	audioConfig: string;
	videoConfig: string;
	onAudioConfigUpdated: (value: string) => void;
	onVideoConfigUpdated: (value: string) => void;
};

export class AdminPlaceCall
	extends BaseComponent<Props, State>
	implements OnContactsUpdated {

	constructor(props: Props) {
		super(props);
		this.state = {
			loader: true,
			twilioLoggerEnabled: false
		};

		TwilioVideoModule.disableLogger();
	}

	componentDidMount() {
		this.logInfo(`Querying for contacts.`);
		ContactsModule.query({contactType: "agentUser"});
	}

	__onContactsUpdated = () => {
		this.logInfo(`Got contacts.`);
		this.setState({loader: false});
	};

	private renderErrorJson = (isValid: boolean) => {
		return <div className={`ll_v_c`} style={{color: "red", fontSize: 14, height: 12, padding: 8}}>
			{
				!isValid && <i>
					Invalid JSON string
				</i>
			}
		</div>;
	};

	render = () => {
		const status = AccountModule.getLoggedStatus();
		if (status !== LoggedStatus.LOGGED_IN)
			return;

		if (this.state.loader)
			return (
				<div className={`match_all`}>
					<Loader message={"Getting contacts..."}/>
				</div>
			);

		return (
			<React.StrictMode>
				<div className={`ll_v_c ${wrapper}`}>
					<StartVideoCall
						onSubmit={this.props.onSubmit}
						overrideConfigInvalid={!isValidJsonConfig(this.props.audioConfig) || !isValidJsonConfig(this.props.videoConfig)}
					/>
					<div style={{marginTop: "5vh"}}/>
					<VideoRoomJoin
						onSubmit={this.props.onSubmit}
						overrideConfigInvalid={!isValidJsonConfig(this.props.audioConfig) || !isValidJsonConfig(this.props.videoConfig)}
					/>
					{this.enableTwilioLogger()}
					{this.renderConfigOverride()}
				</div>
			</React.StrictMode>
		);
	};

	private enableTwilioLogger = () => {
		const {twilioLoggerEnabled} = this.state;

		return (
			<div className={`ll_h_c`} style={{marginTop: "5vh"}}>
				<TS_Checkbox
					label={`Twilio Logger`}
					value={twilioLoggerEnabled}
					checked={twilioLoggerEnabled}
					onCheck={(value: boolean, checked: boolean) => {
						value && checked ? TwilioVideoModule.disableLogger() : TwilioVideoModule.enableLogger();
						this.setState({twilioLoggerEnabled: !twilioLoggerEnabled});
					}}/>
			</div>
		);
	};

	private renderConfigOverride = () => {
		return (
			<PermissionsComponent url={"/v1/override-video-call-configs"}>
				<div className={`ll_v_c`} style={{width: "100%", marginTop: "5vh", boxSizing: "border-box"}}>
					<div id={`video-config`} className={`match_width ll_v_c`} style={{boxSizing: "border-box", marginBottom: 5}}>
						<div style={{margin: "5px 0px"}}>Audio config</div>
						<TS_Input
							placeholder={"write a JSON object here"}
							type={"text"}
							className={`${input} match_width`}
							value={this.props.audioConfig}
							id={'video-config-input'}
							onChange={
								(value) => this.props.onAudioConfigUpdated(value?.trim() || "")
							}/>
						{
							this.renderErrorJson(isValidJsonConfig(this.props.audioConfig))
						}
					</div>
					<div id={`audio-config`} className={`match_width ll_v_c`} style={{boxSizing: "border-box"}}>
						<div style={{margin: "5px 0px"}}>Video config</div>
						<TS_Input
							placeholder={"write a JSON object here"}
							type={"text"}
							className={`${input} match_width`}
							value={this.props.videoConfig}
							id={'video-config-input'}
							onChange={
								(value) => this.props.onVideoConfigUpdated(value?.trim() || "")
							}/>
						{
							this.renderErrorJson(isValidJsonConfig(this.props.videoConfig))
						}
					</div>
				</div>
			</PermissionsComponent>
		);
	};
}
