import * as React from "react";
import {
	Key_SendText,
	Key_UploadFile,
	MessagesModule
} from "@modules/MessagesModule";
import {Loader} from "../../../../widgets/Loader";
import {ContactsModule} from "@modules/ContactsModule";
import {
	BaseComponent,
	TS_Checkbox
} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {Playground_ParticipantsForm} from "./Playground_ParticipantsForm";
import {OnRequestListener} from "@intuitionrobotics/thunderstorm";

type State = {
	message: string,
	unitContact: string,
	loading: boolean,
	isFromUnitToContact: boolean,
	selectedUnit?: Unit,
	userContact?: DB_Contact<any>
	requireResponse: boolean
}

export class Playground_SendMessageToContact
	extends BaseComponent<{}, State>
	implements OnRequestListener {

	constructor(props: {}) {
		super(props);
		this.state = {
			isFromUnitToContact: true,
			message: "",
			unitContact: "",
			loading: false,
			requireResponse: false
		};
	}

	componentDidMount(): void {
		ContactsModule.query();
	}

	loading = () => {
		return this.state.loading ? <Loader/> : null;
	};

	__onRequestCompleted(key: string, success: boolean) {
		switch (key) {
			case Key_SendText:
			case Key_UploadFile:
				this.setState({loading: false, message: ""});
		}
	}

	sendMessage = () => {
		if (!this.state.userContact)
			return;

		MessagesModule.sendTextMessage({
			                               senderId: this.state.isFromUnitToContact ? this.state.unitContact : this.state.userContact?._id,
			                               contactIds: this.state.isFromUnitToContact ? [this.state.userContact?._id] : [this.state.unitContact],
			                               text: this.state.message
		                               }, this.state.requireResponse);
	};

	render() {

		return <div>
			<h3>Send Message To Contact</h3>
			<div style={{marginBottom: "5px"}}>
				<label>Message Body:</label>
				<input value={this.state.message} onChange={(event) => {
					this.setState({message: event.target.value});
				}} type="text" style={{border: "1px solid gray", display: "block"}}/>
				<div style={{display: "flex"}}>
					<TS_Checkbox label={<div/>} checked={this.state.requireResponse} value={this.state.requireResponse} onCheck={() => this.setState({requireResponse: !this.state.requireResponse})}/>
					Patient reply required
				</div>
			</div>
			<Playground_ParticipantsForm userContact={this.state.userContact}
			                             selectedUnit={this.state.selectedUnit}
			                             fromUnitToContact={this.state.isFromUnitToContact}
			                             setMessageDirection={() => this.setState({
				                                                                      isFromUnitToContact: !this.state.isFromUnitToContact
			                                                                      })}
			                             setUnitContact={(unit, unitContact) =>
				                             this.setState({selectedUnit: unit, unitContact: unitContact._id})}
			                             setUserContact={(contact) => this.setState({userContact: contact})}/>
			<button disabled={!this.state.message || !this.state.userContact || !this.state.unitContact} onClick={() => {
				this.setState({loading: true});
				this.sendMessage();
			}}>Send
			</button>
			{this.loading()}
		</div>;
	}

}
