import * as React from "react";

export class Playground_Hello
	extends React.Component {

	render() {
		return <div style={{textAlign: "center", width: 800, height: 1280, position: "absolute", paddingLeft: 100, paddingRight: 100}}>
			<img style={{width: 800, height: 1280}} alt={"image"}
			     src={"https://storage.googleapis.com/elliq-env-dev.appspot.com/resources_v2/memoir/childhood/backgroundImage.png"}/>
			<div style={{
				position: "absolute",
				transform: "translate(-50%, -50%)",
				top: "50%",
				left: "50%"
			}}>
				<p style={{
					fontFamily: 'Libre Baskerville',
					fontStyle: "italic",
					fontWeight: 400,
					fontSize: 100,
					paddingBottom: 80
				}}>Mary's hometown</p>
				<p style={{
					fontFamily: 'Centra No2',
					fontWeight: 700,
					fontSize: 45,
					paddingBottom: 80
				}}>Growing up in New Jersey in the 1950s...</p>
				<p style={{
					fontFamily: 'Centra No2',
					fontWeight: 700,
					fontSize: 45
				}}>1/11/2023</p>
			</div>
		</div>
	}

}
