import * as React from "react";
import {
    BaseComponent,
    ToastModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {Loader} from "../../../widgets/Loader";
import { getSelectedUnit } from "../../utils/utils";
import {
    ContactsModule,
    OnContactsUpdated,
    OnContactsUpserted
} from "@modules/ContactsModule";
import {Dialog_AddContact} from "./Dialog_AddContact";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {SupportModule} from "@modules/SupportModule";

type Props = {
    unit?: Unit
}

type State = {
    showLoader: boolean,
    contact?: Partial<DB_Contact<any>>
}

export class AgentUserDetails
    extends BaseComponent<Props, State>
    implements OnContactsUpdated, OnContactsUpserted {

    readonly unit?: Unit;
    private owner?: DB_Contact<any>

    constructor(props: Props) {
        super(props);
        this.unit = getSelectedUnit(this.props.unit)

        this.state = {showLoader: true}
    }

    __onContactsUpdated = () => {
        if(!this.unit)
            return;

        this.owner = ContactsModule.getUnitContact(this.unit);
        for (const supportUrl of SupportModule.getSPUrls())
            window.parent.postMessage({contact: this.owner}, supportUrl)

        this.setState({showLoader: false});
    };

    __onContactsUpserted = () => {
        ToastModule.toastSuccess("Contact details have updated successfully");
    }

    render() {
        if (!this.unit) {
            this.logError(`You navigated to the unit's contacts table but with no unit...`);
            return null;
        }


        if(!this.owner && !this.state.showLoader){
            this.logError(`Couldn't find an owner to unit ${this.unit.product}/${this.unit.unitId}`)
            return <h1>Couldn't find an owner to unit, please notify support team</h1>
        }

        return <div className="ll_v_c" style={{overflow: "hidden"}}>
            <h3>Fill in patient's contact details</h3>
            {this.owner && <Dialog_AddContact
                ownerId={this.owner._id}
                contact={this.owner}
                onSave={() => {
                    this.setState({showLoader: true})
                }}
                avoidCancelButton
            />}
            {this.state.showLoader && <Loader overlay/>}
            </div>;
    }
}
