import * as React from "react";
import {EditableInput} from "../EditableInput";
import * as cfCss from "./css/customFieldCss";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {
	ApiCaller_PermissionsProject,
	OnPermissionsProjectsLoaded
} from "@intuitionrobotics/permissions/frontend";
// import { CustomDropdown } from "../ui/CustomDropdown";

const icon__remove = require('@res/images/icon_x.svg');

export type newCustomFieldRow = {
	newKey: string,
	newTextValue: string
}

type State = {
	showRowActions: boolean,
	showInput: boolean,
	key: string,
	rowTextValue: string
}

type Props = {
	customFieldRow: { [key: string]: string },
	emptyRowMode?: boolean,
	onRowClose?: () => void,
	onRowDelete: (customKey: string) => void,
	onRowUpdate: (customKey: string, newRow: newCustomFieldRow) => void,
	customKey: string,
	disableEdit?: boolean
	displayOnlyKey?: boolean
}

export class CustomFieldRow
	extends BaseComponent<Props, State>
	implements OnPermissionsProjectsLoaded {

	constructor(props: Props) {
		super(props);

		this.state = {
			showRowActions: false,
			showInput: !props.emptyRowMode,
			key: '',
			rowTextValue: ''
		};

		this.onCustomKeyChange = this.onCustomKeyChange.bind(this);
		this.handleInputTextSave = this.handleInputTextSave.bind(this);
	}

	__onPermissionsProjectsLoaded() {
		this.forceUpdate();
	}

	renderRowActions() {
		return <div style={{width: '15%', textAlign: 'end', cursor: 'pointer'}}>
			{this.renderActions()}
		</div>;
	}

	renderActions() {
		const {showRowActions} = this.state;
		const {emptyRowMode, onRowClose, onRowDelete, customKey} = this.props;
		if (emptyRowMode && onRowClose)
			return <div onClick={() => {
				onRowClose()
			}} className={cfCss.cancelTextStyle}>close</div>

		if (!showRowActions)
			return null;

		return <img style={{verticalAlign: 'bottom'}} onClick={() => onRowDelete(customKey)} src={icon__remove}/>;
	}

	setShowRowActions = (value: boolean) => {
		this.setState({showRowActions: value});
	};

	handleInputTextSave(textInput: string) {
		const {onRowUpdate, customKey, emptyRowMode, onRowClose} = this.props;
		const {key} = this.state;
		if (!key || !textInput)
			return;

		this.setState({rowTextValue: textInput});
		onRowUpdate(customKey, {newKey: key, newTextValue: textInput});
		if (emptyRowMode && onRowClose)
			onRowClose();
	}


	static getDerivedStateFromProps(props: Props, currentState: State) {
		const propCustomRowKey = Object.keys(props.customFieldRow)[0];
		const propCustomRowValue = props.customFieldRow[propCustomRowKey];
		if ((!currentState.key && propCustomRowKey) || (!currentState.rowTextValue && propCustomRowValue)) {
			return {
				showRowActions: currentState.showRowActions,
				showInput: currentState.showInput,
				key: propCustomRowKey,
				rowTextValue: propCustomRowValue
			}
		}
		return null;
	}


	onCustomKeyChange(newKey: string) {
		const {onRowUpdate, onRowClose, customKey, emptyRowMode} = this.props;
		const {rowTextValue} = this.state;

		if (!newKey)
			return;

		if (this.props.displayOnlyKey) {
			onRowUpdate(customKey, {newKey, newTextValue: "1"});
			if (emptyRowMode && onRowClose)
				onRowClose();

			return;
		}

		if (!this.props.displayOnlyKey && emptyRowMode) {
			this.setState({showInput: true, key: newKey});
		} else {
			this.setState({key: newKey});
		}

		if (!rowTextValue)
			return;

		onRowUpdate(customKey, {newKey: newKey, newTextValue: rowTextValue});
	}

	getDdlOptions() {
		const arrayCustomFields = ApiCaller_PermissionsProject.getProjectsCustomKeys();
		return arrayCustomFields.map((customFieldKey: string) => {
			return {value: customFieldKey, label: customFieldKey};
		});
	}


	renderInput(textInput: string, onSaveInput?: (text:  string) => void) {
		const {emptyRowMode} = this.props;
		// const {rowTextValue} = this.state;
		// const textInput = emptyRowMode ? '': (this.state.showInput ? rowTextValue: this.state.key);

		return <EditableInput disableEdit={this.props.disableEdit} inputText={textInput} onSaveInput={(input) => {
			onSaveInput? onSaveInput(input): this.handleInputTextSave(input)
		}} beginWithEditMode={emptyRowMode === true} placeholder={'Input...'}/>;
	}

	render() {
		const {showInput} = this.state;
		// const ddlValue = key ? {value: key, label: key} : null;


		return <div onMouseEnter={() => {
			this.setShowRowActions(true)
		}} onMouseLeave={() => {
			this.setShowRowActions(false)
		}} className={`${cfCss.customFieldAddRow} ll_h_c`}>
			<div style={{width: '40%', marginRight: '3%'}}>
				{/*<CustomDropdown options={this.getDdlOptions()} onChange={(option) => {*/}
				{/*	this.onCustomKeyChange(option.value)*/}
				{/*}}*/}
				{/*                value={ddlValue}/>*/}
				{this.renderInput(this.state.key, this.onCustomKeyChange)}
			</div>
			<div style={{width: '40%', color: '#363f46'}}>
				{!this.props.displayOnlyKey && showInput && this.renderInput(this.state.rowTextValue)}
			</div>
			{this.renderRowActions()}
		</div>;
	}

}
