import {
	BaseComponent,
	TS_Input
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as emotion from "emotion";
import {
	TwilioVideoModule
} from "@modules/TwilioVideoModule";

type State = {
	roomName: string;
}

type Props = {
	onSubmit: () => void;
	overrideConfigInvalid?: boolean;
}

const roomNameInput = emotion.css(
	{
		border: "1px solid",
		padding: 5,
		margin: 10
	}
);

export class VideoRoomJoin
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);
		this.state = {
			roomName: "",
		};
	}

	private joinVideoRoom = () => {
		const roomName = this.state.roomName;
		if (!roomName?.trim())
			return;

		if (this.props.overrideConfigInvalid) {
			this.logError(`Invalid override config`);
			return;
		}

		this.logInfo(`Will attempt to join video room with name ${roomName}.`);
		TwilioVideoModule.requestAccessToken(roomName);
		return this.props.onSubmit();
	};

	render = () => {
		const {overrideConfigInvalid} = this.props;

		return <div className={`ll_v_c`}>
			<h3 style={{
				margin: 10
			}}>
				Join an existing video room
			</h3>
			<div className={`ll_v_c`}>
				<TS_Input
					placeholder={"Room name"}
					type={"text"}
					className={roomNameInput}
					value={this.state.roomName}
					id={'room-to-join-name'}
					onChange={
						(value) => {
							this.setState({roomName: (value?.trim() || "")});
						}
					}
					onAccept={this.joinVideoRoom}/>
				<button
					disabled={!this.state.roomName || !this.state.roomName.trim() || overrideConfigInvalid}
					onClick={this.joinVideoRoom}>
					Join
				</button>
			</div>
		</div>;
	};

}
