import {
	BaseComponent,
	ConnectivityModule,
	Dialog_Builder,
	DialogModule,
	OnConnectivityChange
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {DialogTitle} from "./components/DialogTitle";
import {DialogButtons} from "./components/DialogButtons";

export class Dialog_Connectivity
	extends BaseComponent
	implements OnConnectivityChange {

	public static show() {
		new Dialog_Builder(<Dialog_Connectivity/>)
			.show();
	}

	__onConnectivityChange = () => {
		ConnectivityModule.isConnected() && DialogModule.close();
	};

	render() {
		return <div className={'ll_v_s fill'} style={{width: 300}}>
			<DialogTitle title="Connection Lost"/>
			<div style={{fontWeight: 100, fontSize: 16, paddingLeft: 25, paddingRight: 25}}>
				It seems like you have an issue with your connection, please try to connect to the internet to use Q Messaging
			</div>
			<DialogButtons okTitle="OK" okPressed={DialogModule.close}/>
		</div>;
	}
}
