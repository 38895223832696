import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
	Dialog_Builder,
} from "@intuitionrobotics/thunderstorm/frontend";
import {COLORS} from "@res/colors";
import {StorageKey_PWAUserName} from "@modules/ContactLoginFEModule";
import {
	StorageKey_UserEmail
} from "@intuitionrobotics/user-account/frontend";
import {FONTS} from "@res/fonts";

const dialog = emotion.css(
	{
		boxSizing: "border-box",
		position: "absolute",
		right: 16,
		top: 64,
		minWidth: undefined,
		width: 150,
		height: 110,
		backgroundColor: COLORS.white(),
		borderRadius: 8,
		boxShadow: "0px 2px 4px 2px rgba(158, 89, 72, 0.5)",
		padding: "16px 24px"
	}
);

const button = emotion.css(
	{
		boxSizing: "border-box",
		backgroundColor: "white",
		border: "1px solid rgba(0, 0, 0, 0.2)",
		height: 24,
		width: 72,
		justifyContent: "center",
		borderRadius: 64,
		padding: "4px 16px",
		marginTop: "auto"
	}
);

type Props = {
	onLogoutClick: () => void;
};

type State = {};

export class Dialog_VideoCallMenu
	extends BaseComponent<Props, State> {

	public static show(onLogoutClick: () => void) {
		return new Dialog_Builder(<Dialog_VideoCallMenu onLogoutClick={onLogoutClick}/>)
			.setOverlayColor(COLORS.midnight_blue(0.4))
			.setAllowIndirectClosing(true)
			.show();
	}

	render() {
		const userName = StorageKey_PWAUserName.get("");
		const phoneNumber = StorageKey_UserEmail.get('').split('@intuitionrobotics.com')[0];

		return <div className={`ll_v_c ${dialog}`}>
			{
				userName &&
				<div style={{marginBottom: 4}}>
					{FONTS.centra_no2_book(userName, "black", 14)}
				</div>
			}
			{
				phoneNumber &&
				<div>
					{FONTS.centra_no2_book(phoneNumber, "rgba(0, 0, 0, 0.5)", 11)}
				</div>
			}
			<div className={`ll_v_c ${button}`}
			     onClick={this.props.onLogoutClick}>
				{FONTS.centra_no2_book("Logout", "black", 14)}
			</div>
		</div>;
	}
}
