import * as React from "react";
import {BaseComponent, BrowserHistoryModule, GenericSelect} from "@intuitionrobotics/thunderstorm/frontend";
import {UnitAndComment} from "@app/ir-q-app-common/types/units";
import {OnContactsUpdated} from "@modules/ContactsModule";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {PARAM_PRODUCT, PARAM_UNIT_ID, PARAM_USER_GROUP} from "../../Routes";
import {ContactsTable} from "./ContactsTable";

type Props = {}
type State = {
	showLoader: boolean
}

export class ContactsList
	extends BaseComponent<Props, State>
	implements OnUnitsLoaded, OnContactsUpdated {

	componentWillUnmount() {
		BrowserHistoryModule.removeQueryParam(PARAM_UNIT_ID)
		BrowserHistoryModule.removeQueryParam(PARAM_PRODUCT)
		BrowserHistoryModule.removeQueryParam(PARAM_USER_GROUP)
	}

	__onContactsUpdated = () => this.forceUpdate();
	__onUnitsLoaded = () => this.forceUpdate();

	render() {
		const unitId = BaseComponent.getQueryParameter(PARAM_UNIT_ID);
		const product = BaseComponent.getQueryParameter(PARAM_PRODUCT);
		const userGroup = BaseComponent.getQueryParameter(PARAM_USER_GROUP);
		const selectedUnit = unitId && product ? {unitId, product, comment: userGroup} : undefined;
		return <div>
			<div style={{width: 244, position: "absolute", marginLeft: 15}}>
				<GenericSelect<UnitAndComment>
					options={CommonFEModule.getUnits()}
					selectedOption={selectedUnit}
					iconClose={"D"}
					iconOpen={"U"}
					onChange={(unit: UnitAndComment) => {
						BrowserHistoryModule.addQueryParam(PARAM_UNIT_ID, unit.unitId);
						BrowserHistoryModule.addQueryParam(PARAM_PRODUCT, unit.product);
						if (unit.comment)
							BrowserHistoryModule.addQueryParam(PARAM_USER_GROUP, unit.comment);

						this.forceUpdate();
					}}
					styles={{}}
					presentation={option => option.unitId}/>
			</div>
			{selectedUnit && <ContactsTable unit={selectedUnit}/>}
		</div>;
	}
}
