import {
	BaseComponent,
	BrowserHistoryModule,
	GenericSelect
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {Unit} from "@app/ir-q-app-common/types/units";
import {CommonFEModule} from "@modules/CommonFEModule";
import {
	PARAM_PRODUCT,
	PARAM_UNIT_ID
} from "../../../Routes";
import { VideoCallTable } from "../../video-calling/components/VideoCallTable";
import {
	ContactsModule,
	OnContactsUpdated
} from "@modules/ContactsModule";
import {Loader} from "../../../../widgets/Loader";

type State = {
	showLoader: boolean
}

export class Playground_VideoCallDashboard
	extends BaseComponent<{}, State> implements OnContactsUpdated {

	constructor(props: {}) {
		super(props);

		this.state = {
			showLoader: true
		};
	}

	__onContactsUpdated = () => {
		this.setState({showLoader: false});
	};

	componentWillUnmount() {
		BrowserHistoryModule.removeQueryParam(PARAM_UNIT_ID)
		BrowserHistoryModule.removeQueryParam(PARAM_PRODUCT)
	}


	render = () => {

		if (!ContactsModule.getItems())
			return <Loader overlay={true}/>

		const unitId = BaseComponent.getQueryParameter(PARAM_UNIT_ID);
		const product = BaseComponent.getQueryParameter(PARAM_PRODUCT);

		const selectedUnit = unitId && product ? {unitId, product} : undefined;

		return (<div>
			<div style={{width: 244, position: "absolute", marginLeft: 15, marginBottom: 100}}>
				<GenericSelect<Unit>
					options={CommonFEModule.getUnits()}
					selectedOption={selectedUnit}
					iconClose={"D"}
					iconOpen={"U"}
					onChange={(unit: Unit) => {
						BrowserHistoryModule.addQueryParam(PARAM_UNIT_ID, unit.unitId);
						BrowserHistoryModule.addQueryParam(PARAM_PRODUCT, unit.product);
						this.forceUpdate();
					}}
					styles={{}}
					presentation={option => option.unitId}/>
			</div>
			<VideoCallTable unit={selectedUnit}/>
		</div>);
	};
}
