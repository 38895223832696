import * as emotion from "emotion";

export const customFieldContainer = emotion.css`
	margin-bottom: 10px;
	margin-top: 15px;
	padding: 15px;
	background-color: rgba(228, 231, 233, 0.4);
	border-radius: 3px;
`;

export const customFieldRow = emotion.css`
	margin-bottom: 5px;
`;

export const customFieldAddRow = emotion.css`
	margin-bottom: 10px;
`;

export const cancelTextStyle = emotion.css`
	color: #6c777f;
	font-size: 14px;
	margin-left: 15px;
	cursor: pointer;
`;

export const inputContainer = emotion.css`
	// width: 10%;
	// transition: width 0.5s;
	margin-right: 10px;
	width: 115px;
`;

export const domainInputStyle = emotion.css`
	::-webkit-input-placeholder {
		color: #babcbe
	};
	background-color: inherit;
	font-style: italic;
	font-size: 12px;
	outline: none;
	border-bottom: solid 1px rgba(0, 0, 0, 0.23);
	width: 100%;
`;

export const addMethodIcon = emotion.css`
	vertical-align: middle;
	height: 22px;
	width: 22px;
	cursor: pointer;
`;