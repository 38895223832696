import React from "react";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import '../../../res/styles/dropdown-with-checkboxes.scss';

type State = {
}

type Props = {
    onUpdate: (selectedOptions: string[]) => void
    onDropdownToggle: (event: any) => void
    options: CheckboxOption[],
    selectedOptions: string[],
    placeholder: string,
    hasSelectAllCheckbox: boolean,
    isOpen: boolean
}

export type CheckboxOption = {
    value: any,
    label: string
}

export const selectAllCheckboxOption = {value: "SelectAll", label: "Select all"}

class DropdownWithCheckboxes extends BaseComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isOpen: false
        }
    }

    handleCheckboxChange = (value: string) => {
        let selectedOptionsEdit;
        if (this.props.selectedOptions.includes(value)) {
            selectedOptionsEdit = [...this.props.selectedOptions.filter((selected) => selected !== value)];
        } else {
            selectedOptionsEdit = [...this.props.selectedOptions, value];
        }
        // if the user selects a checkbox we want to unselect the select all checkbox automatically
        if (selectedOptionsEdit.includes(selectAllCheckboxOption.value)) {
            selectedOptionsEdit = [...selectedOptionsEdit.filter((selected) => selected !== selectAllCheckboxOption.value)];
        }
        this.props.onUpdate(selectedOptionsEdit);
    };

    handleSelectAllChange = () => {
        if (this.props.selectedOptions.includes(selectAllCheckboxOption.value)) {
            this.props.onUpdate([]);
        } else {
            this.props.onUpdate([selectAllCheckboxOption.value]);
        }
    };

    render() {
        return (
            <div className="dropdown-checkbox">
                <button onClick={(event: any) => this.props.onDropdownToggle(event)}>{this.props.placeholder}</button>
                {this.props.isOpen && (
                    <div className="checkbox-options">
                        {this.props.hasSelectAllCheckbox && (
                            <label>
                                <input type="checkbox"
                                       checked={this.props.selectedOptions.includes(selectAllCheckboxOption.value)}
                                       onChange={this.handleSelectAllChange}/>
                                Select All
                            </label>
                        )}
                        {this.props.options.map((option) => (
                            <label key={option.value}>
                                <input
                                    type="checkbox"
                                    value={option.value}
                                    checked={this.props.selectedOptions.includes(option.value)}
                                    onChange={() => this.handleCheckboxChange(option.value)}
                                />
                                {option.label}
                            </label>
                        ))}
                    </div>
                )}
            </div>
        );
    }

}

export default DropdownWithCheckboxes;