import React from 'react';
import '../../../res/styles/cloud-asset-form.scss';
import {PermissionsComponent} from "@intuitionrobotics/permissions/frontend";
import {CloudAssetsModule, OnGeneralCloudAssetUpdated,} from "@modules/CloudAssetsModule";
import {ImagePopUp} from "./ImagePopUp";
import {
    BaseComponent,
    DialogModule,
    GenericSelect,
    ToastModule,
    TS_Checkbox
} from "@intuitionrobotics/thunderstorm/frontend";
import {Loader} from "../../../widgets/Loader";
import {buttonStyle} from "@styles/buttonStyle";
import {ConfirmDialog} from "../ui/dialogs/ConfirmDialog";
import {CustomField} from "../ui/custom-fields/CustomField";
import {ObjectTS, StringMap} from "@intuitionrobotics/ts-common";
import {ComputerVisionAnnotation} from "@app/ir-q-app-common/types/computer-vision-tags";
import {TypedMap} from "@intuitionrobotics/ts-common/utils/types";
import {ChipGroup} from "../ui/chips/ChipGroup";
import {Chip} from "../ui/chips/Chip";
import {DB_CloudAsset} from "@app/app-shared/cloud-asset";
import {ICONS} from "@res/icons";
import {COLORS} from "@res/colors";
import {DataEnvsModule} from "@modules/DataEnvsModule";
import DropdownWithCheckboxes, {
    CheckboxOption,
    selectAllCheckboxOption
} from '../ui/DropdownWithCheckboxes';

const KILOBYTES = 1024;
const MEGABYTES = 1024 * KILOBYTES;
const GIGABYTES = 1024 * MEGABYTES;
export const INTENT_PARAMETERS_NAME = "intentParameters";
export const TAGS_NAME = "tags";
export const LANDMARKS_NAME = "landmarks";
const VERSION_REGEX = /^\d{1,2}\.\d{1,2}\.\d{1,2}$/

enum Priority {
    High = "HIGH",
    Low = "LOW",
}

enum TypesOfAsset {
    SuggestionCardTopPriority = "Suggestion Card Top Priority",
    SuggestionCardLowPriority = "Suggestion Card Low Priority",
    LandscapeImage = "Landscape Image"
}

type CloudAssetFormProps = {
    assetId: string;
    selectedKey: string;
    imageUrl?: string;
};

type EditingMap = {
    fileName?: string;
    description?: string,
    photographer?: string,
    landmark?: string,
    location?: string,
    subject?: string,
    categories?: string,
    intent?: string;
    sourceData?: string;
    fromVersion?: string;
    toVersion?: string;
    intentParameters?: TypedMap<string>;
    tags?: ComputerVisionAnnotation[];
    landmarks?: ComputerVisionAnnotation[];
    isSuggestion?: boolean;
    priority?: Priority;
    isHidden?: boolean,
    userGroups?: string[]
};

type CloudAssetFormState = {
    isEditing: boolean;
    showLoader: boolean;
    errorOnFromVersionValidate?: boolean;
    errorOnToVersionValidate?: boolean;
    editingMap: EditingMap;
    isUserGroupsDropdownOpen?: boolean
};


class CloudAssetForm extends BaseComponent<CloudAssetFormProps, CloudAssetFormState> implements OnGeneralCloudAssetUpdated {
    constructor(props: CloudAssetFormProps) {
        super(props);

        this.state = {
            isEditing: false,
            showLoader: false,
            editingMap: {},
        };
    }

    componentDidMount(): void {
        if (!DataEnvsModule.getItems().length)
            DataEnvsModule.query()
    }

    __onGeneralCloudAssetUpdated = (assetId?: string) => {
        if (!assetId)
            return
        if (this.props.assetId === assetId) {
            this.setState({showLoader: false});
            this.logInfo(`Asset updated ${assetId}`);
        }
    }

    cancelChanges = () => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = false;
            currState.editingMap = {};
            currState.isUserGroupsDropdownOpen = false;
            return currState;
        })
    }

    validateFromVersion = (event: any): void => {
        if (this.state.editingMap.fromVersion && !VERSION_REGEX.test(this.state.editingMap.fromVersion)) {
            ToastModule.toastError("Version format is x.x.x");
            return this.setState({errorOnFromVersionValidate: true});
        }

        this.setState({errorOnFromVersionValidate: false});
    }

    validateToVersion = (event: any): void => {
        if (this.state.editingMap.toVersion && !VERSION_REGEX.test(this.state.editingMap.toVersion)) {
            ToastModule.toastError("Version format is x.x.x");
            return this.setState({errorOnToVersionValidate: true});
        }

        this.setState({errorOnToVersionValidate: false});
    }

    handleChange = (event: any): void => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isEditing = true;
            currState.editingMap[name as keyof EditingMap] = value as never;
            return currState;
        })
    }

    handleIntentParameterChanged = (value: StringMap): void => {
        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            currState.editingMap.intentParameters = value as never;
            return currState;
        })
    }

    addNewAnnotation = (newEntry: string): void => {
        const newAnnotation: ComputerVisionAnnotation = {originalDescription: newEntry, description: newEntry.replace(/ /g, "_"), score: 1, confidence: 1, topicality: 1};
        const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.props.selectedKey).find((value) => {
            return value._id === this.props.assetId;
        });
        if (!asset)
            return

        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            currState.editingMap.tags = (currState.editingMap.tags || asset.metadata?.tags?.slice(0) || []) as ComputerVisionAnnotation[];
            currState.editingMap.tags.push(newAnnotation);

            return currState;
        })
    }

    handleHidden = (key: string, prevChecked: boolean) => {
        this.setState((prevState) => {
            const currState = {...prevState};

            currState.isEditing = true;
            currState.editingMap.isHidden = !prevChecked;

            return currState;
        })
    };

    handleTypeOfImage = (key: string) => {
        this.setState((prevState) => {
            const currState = {...prevState};

            currState.isEditing = true;
            if (key === "Suggestion Card Top Priority") {
                currState.editingMap.isSuggestion = true;
                currState.editingMap.priority = Priority.High;
                return currState;
            }
            if (key === "Suggestion Card Low Priority") {
                currState.editingMap.isSuggestion = true;
                currState.editingMap.priority = Priority.Low;
                return currState;
            }
            currState.editingMap.isSuggestion = undefined;
            currState.editingMap.priority = undefined;

            return currState;
        })
    };

    handleUserGroups = (selectedUserGroups: string[]) => {
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.editingMap.userGroups = selectedUserGroups;
            currState.isEditing = true;
            return currState;
        })
    };

    createUserGroupOptions(): CheckboxOption[] {
        return DataEnvsModule.getItems().map((envItem) => {
            return {value: envItem.label, label: envItem.label}
        });
    }

    getSelectedUserGroups(asset: DB_CloudAsset): string[] {
        if (this.state.isEditing) {
            return this.state.editingMap.userGroups || [selectAllCheckboxOption.value];
        }
        return asset.metadata.userGroups || [selectAllCheckboxOption.value];
    }

    getPlaceholderForUserGroupsDropdown(asset: DB_CloudAsset): string {
        if (this.getSelectedUserGroups(asset).includes(selectAllCheckboxOption.value)) {
            return "All user groups";
        }
        return "Selected groups";
    }

    toggleUserGroupsDropdown = (event: any) => {
        event.preventDefault();
        console.log("hello")
        this.setState((prevState) => {
            const currState = {...prevState};
            currState.isUserGroupsDropdownOpen = !prevState.isUserGroupsDropdownOpen;
            console.log(currState.isUserGroupsDropdownOpen)
            return currState;
        })
    };

    getTypeOfAsset(asset: DB_CloudAsset): TypesOfAsset {
        let isSuggestion;
        let priority;
        if (!this.state.isEditing) {
            isSuggestion = asset.metadata.isSuggestion;
            priority = asset.metadata.priority;
        } else {
            isSuggestion = this.state.editingMap.isSuggestion;
            priority = this.state.editingMap.priority;
        }
        if (isSuggestion) {
            return (priority === Priority.Low) ? TypesOfAsset.SuggestionCardLowPriority : TypesOfAsset.SuggestionCardTopPriority;
        }
        return TypesOfAsset.LandscapeImage;
    }

    handleSubmit = (event: any) => {
        event.preventDefault();
        const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.props.selectedKey).find((value) => {
            return value._id === this.props.assetId;
        });
        if (!asset)
            return

        if (this.state.editingMap.isHidden !== undefined)
            asset.isHidden = this.state.editingMap.isHidden;

        if (this.state.editingMap.userGroups?.includes(selectAllCheckboxOption.value))
            this.state.editingMap.userGroups = undefined;

        asset.metadata = {...asset.metadata, ...this.state.editingMap};
        delete asset.metadata.isHidden;

        const cacheId = asset.cacheId;
        if (cacheId && CloudAssetsModule.getFile(cacheId))
            delete asset.signedUrl

        this.setState({showLoader: true})
        CloudAssetsModule.create(asset);
        this.cancelChanges();
    }
    private prettyPrintFileSize = (fileSize: string) => {
        const size = parseInt(fileSize);
        if (isNaN(size)) {
            return '';
        }
        if (size < KILOBYTES) {
            return size + ' B';
        }
        if (size < MEGABYTES) {
            return (size / KILOBYTES).toFixed(2) + ' KB';
        }
        if (size < GIGABYTES) {
            return (size / MEGABYTES).toFixed(2) + ' MB';
        }
        return (size / GIGABYTES).toFixed(2) + ' GB';
    }

    render() {
        const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.props.selectedKey).find((value) => {
            return value._id === this.props.assetId;
        });

        if (!asset)
            return <div></div>

        if (this.state.showLoader) {
            return <Loader/>;
        }

        const sortAnnotations = (a: ComputerVisionAnnotation, b: ComputerVisionAnnotation) => b.score - a.score;
        const tagToScore = (this.state.editingMap.tags || asset.metadata.tags || []).sort(sortAnnotations).reduce((toRet: ObjectTS, item: ComputerVisionAnnotation) => {
            toRet[item.originalDescription || item.description] = item.score;
            return toRet;
        }, {} as ObjectTS) as { [tag: string]: Number };

        const landmarks = (asset.metadata.landmarks || []).sort(sortAnnotations).reduce((toRet: ObjectTS, item: ComputerVisionAnnotation) => {
            toRet[item.description] = item.score;
            return toRet;
        }, {} as ObjectTS) as { [tag: string]: Number };

        const disableSaveBtn = this.state.errorOnFromVersionValidate || this.state.errorOnToVersionValidate;

        const typeOfAssetOptions = Object.values(TypesOfAsset);
        const selectedTypeOfAsset = this.getTypeOfAsset(asset);

        const userGroupOptions = this.createUserGroupOptions();
        const selectedUserGroups = this.getSelectedUserGroups(asset);
        const placeholderForUserGroupsDropdown = this.getPlaceholderForUserGroupsDropdown(asset);

        return <div className="cloud-asset-info">
            <form className="cloud-asset-form" onSubmit={this.handleSubmit}>
                <div className="cloud-asset-image">
                    {
                        asset.signedUrl ?
                            <ImagePopUp src={this.props.imageUrl || asset.signedUrl}></ImagePopUp> : <div></div>
                    }
                    <div style={{width: 250}}>
                        {
                            <GenericSelect<TypesOfAsset>
                                options={typeOfAssetOptions}
                                selectedOption={selectedTypeOfAsset}
                                iconClose={ICONS.triangle_up(COLORS.grays_dark, 12)}
                                iconOpen={ICONS.triangle_down(COLORS.grays_dark, 12)}
                                onChange={(key: string) => {
                                    this.handleTypeOfImage(key)
                                }}
                                styles={{}}
                                presentation={option => option}
                            />
                        }

                    </div>

                    <div style={{width: 250}}>
                        {
                            <DropdownWithCheckboxes
                                options={userGroupOptions}
                                selectedOptions={selectedUserGroups}
                                onUpdate={(selectedOptions: string[]) => {
                                    this.handleUserGroups(selectedOptions)
                                }}
                                onDropdownToggle={(event: any) => {
                                    this.toggleUserGroupsDropdown(event)
                                }}
                                isOpen={this.state.isUserGroupsDropdownOpen || false}
                                placeholder={placeholderForUserGroupsDropdown}
                                hasSelectAllCheckbox={true}
                            />
                        }

                    </div>

                    <div className="cloud-asset-form-buttons">
                        <PermissionsComponent url={"/v1/cloud-asset/delete"}>
                            <div style={buttonStyle("red")} onClick={() => {
                                ConfirmDialog.show(
                                    {
                                        okLabel: "Delete image",
                                        onOk: () => {
                                            this.setState({showLoader: true});
                                            CloudAssetsModule.delete(asset._id);
                                            DialogModule.close();
                                        },
                                        title: "Delete image",
                                        displayMessage: `Are you sure you want to delete this image?`
                                    });

                            }}>
                                Delete
                            </div>
                        </PermissionsComponent>
                        <div className="cloud-asset-form-toggle">
                            {
                                <TS_Checkbox
                                    value={''}
                                    checked={this.state.editingMap.isHidden !== undefined ? this.state.editingMap.isHidden : (asset.isHidden || false)}
                                    label={'Hide image'}
                                    onCheck={this.handleHidden}/>
                            }
                        </div>
                    </div>

                </div>
                <div className="cloud-asset-data">
                    <div>
                        <div>
                            <label>Description:</label>
                        </div>
                        <textarea
                            value={this.state.editingMap.description || asset.metadata.description || ''}
                            cols={30}
                            rows={5}
                            name='description'
                            onChange={this.handleChange}
                            className={this.state.editingMap.description ? 'cloud-asset-input-edited' : ''}
                        />
                    </div>
                    <div>
                        <label>Photographer:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.photographer ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="photographer"
                            value={this.state.editingMap.photographer || asset.metadata.photographer}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Landmark:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.landmark ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='landmark'
                            value={this.state.editingMap['landmark'] || asset.metadata.landmark || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Location:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.location ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="location"
                            value={this.state.editingMap.location || asset.metadata.location || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Subject:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.subject ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='subject'
                            value={this.state.editingMap.subject || asset.metadata.subject || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Categories:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.categories ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='categories'
                            value={this.state.editingMap.categories || asset.metadata.categories || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>File Name:</label>
                        <input
                            placeholder='no data..'
                            className={this.state.editingMap.fileName ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name='fileName'
                            value={this.state.editingMap.fileName || asset.metadata.fileName || ''}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>File Size:</label>
                        <input
                            placeholder='no data..'
                            type="text"
                            name='fileSize'
                            value={this.prettyPrintFileSize(asset.metadata.fileSize) || ''}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label>Dimensions:</label>
                        <input
                            placeholder='no data..'
                            type="text"
                            name='Dimensions'
                            value={(asset.metadata.imageWidth) && asset.metadata.imageHeight ? `${asset.metadata.imageWidth} x ${asset.metadata.imageHeight}` : 'no data..'}
                            disabled={true}
                        />
                    </div>
                    <div>
                        <label>From version:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.errorOnFromVersionValidate ? 'error-border' : this.state.editingMap.fromVersion ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="fromVersion"
                            value={this.state.editingMap.fromVersion !== undefined ? this.state.editingMap.fromVersion : (asset.metadata.fromVersion || '')}
                            onBlur={(e) => this.validateFromVersion(e)}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>To version:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.errorOnToVersionValidate ? 'error-border' : this.state.editingMap.toVersion ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="toVersion"
                            value={this.state.editingMap.toVersion !== undefined ? this.state.editingMap.toVersion : (asset.metadata.toVersion || '')}
                            onBlur={(e) => this.validateToVersion(e)}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Intent:</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.intent ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="intent"
                            value={this.state.editingMap.intent !== undefined ? this.state.editingMap.intent : (asset.metadata.intent || '')}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label>Source Data :</label>
                        <input
                            placeholder="no data.."
                            className={this.state.editingMap.sourceData ? 'cloud-asset-input-edited' : ''}
                            type="text"
                            name="sourceData"
                            value={this.state.editingMap.sourceData !== undefined ? this.state.editingMap.sourceData : (asset.metadata.sourceData || '')}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div style={{width: "350px"}}>
                        Intent parameters:
                        <CustomField onCustomFieldUpdate={this.handleIntentParameterChanged}
                                     customField={this.state.editingMap.intentParameters || asset.metadata.intentParameters || {}}/>
                    </div>
                    <div style={{width: "350px"}}>
                        Tags:
                        <ChipGroup
                            editable={true}
                            onNewEntry={(newTag: string) => {
                                this.addNewAnnotation(newTag);
                                tagToScore[newTag] = 1;
                            }}>
                            {
                                Object.keys(tagToScore).map(tag => {
                                    return <Chip text={tag} closable={true} onClose={() => {
                                        this.deleteAnnotation(tag)
                                    }}/>
                                })
                            }
                        </ChipGroup>
                    </div>
                    <div style={{width: "350px"}}>
                        Landmarks:
                        <ChipGroup editable={false}>
                            {
                                Object.keys(landmarks).map(landmark => {
                                    return <Chip text={landmark} closable={false} onClose={() => {
                                        this.deleteAnnotation(landmark)
                                    }}/>
                                })
                            }
                        </ChipGroup>

                    </div>
                </div>
            </form>
            <div className="cloud-asset-actions">
                {!this.state.isEditing ?
                    <div></div>
                    :
                    <>
                        <div style={buttonStyle("green", disableSaveBtn)} onClick={(event) => {
                            if (disableSaveBtn)
                                return;

                            this.handleSubmit(event);
                        }}>
                            Save
                        </div>
                        <div style={buttonStyle("red")} onClick={() => {
                            this.cancelChanges();
                        }}>
                            Cancel
                        </div>
                    </>
                }
            </div>
        </div>;
    }

    private deleteAnnotation = (annotation: string) => {
        const asset = CloudAssetsModule.getDbAssetsWithImagesByKey(this.props.selectedKey).find((value) => {
            return value._id === this.props.assetId;
        });

        this.setState((prevState) => {
            const currState = {...prevState};
            // when a user edits a field we show the save/cancel buttons
            currState.isEditing = true;
            let tags: ComputerVisionAnnotation[] = currState.editingMap.tags || asset?.metadata.tags;
            tags = tags.filter(t => t.originalDescription !== annotation);
            currState.editingMap.tags = tags;
            return currState;
        });
    }
}

export default CloudAssetForm;
