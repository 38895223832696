import {
	BaseComponent,
	Dialog_Builder,
	TS_Checkbox,
	TS_Input,
	TS_TextArea
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import {ContactsModule} from "@modules/ContactsModule";
import {Relation} from "@app/ir-q-app-common/types/db-contact";
import {DB_Contact_View} from "@app/ir-q-app-common/types/db-contact-view";
import {MessagesModule} from "@modules/MessagesModule";
import {
	CommonFEModule,
	OnUnitsLoaded
} from "@modules/CommonFEModule";
import {filterInstances} from "@intuitionrobotics/ts-common";

type SearchResultContact = DB_Contact_View<any> & { relatedUnitId: string | undefined | null }

type State = {
	query: string,
	selected: Map<string, DB_Contact_View<any>>,
	smsDialogOpen: boolean,
	smsMessage: string,
	selectedContactType: string,
	allContacts: SearchResultContact[] | null,
	filterDeleted: boolean,
	filterUnpaired: boolean,
	labels: string[]
	selectedLabels: string[]
}

export class Playground_SearchContacts
	extends BaseComponent<{}, State>
	implements OnUnitsLoaded {

	__onUnitsLoaded(): void {
		const labels: string[] = [];
		CommonFEModule.getUnits().forEach(u => {
			if (u.comment && !labels.includes(u.comment))
				labels.push(u.comment)
		})
		this.setState({labels})
	}

	state = {
		query: '',
		smsDialogOpen: false,
		selected: new Map(),
		smsMessage: '',
		selectedContactType: '',
		allContacts: null,
		filterDeleted: false,
		filterUnpaired: false,
		labels: [],
		selectedLabels: []
	}

	private onContactTypeSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
		this.setState({selectedContactType: e.target.value});
	};

	private onFilterDeleted = (val: string, prevChecked: boolean) => {
		this.setState({filterDeleted: !prevChecked});
	}
	private onFilterUnpaired = (val: string, prevChecked: boolean) => {
		this.setState({filterUnpaired: !prevChecked});
	}

	componentDidMount() {
		ContactsModule.query().setHandleRequestSuccess(() => {
			const {allContacts} = this.state;
			if (allContacts === null) {
				const contacts = ContactsModule.getItems();
				const nContacts: SearchResultContact[] = contacts.map(c => {
					const agentUserConnections = c.connections.filter(con => con.relation === Relation.AGENT_USER);
					const agentUsers = filterInstances(agentUserConnections.map(agentUser => ContactsModule.get(agentUser._id)?.contactData.unitId));
					const agentUsersInOneString = agentUsers.join(", ");
					return {relatedUnitId: agentUsersInOneString, ...c}
				})
				this.setState({allContacts: nContacts})
			}
		});
	}

	private onContactSelected = (key: string, prevChecked: boolean) => {
		const {selected} = this.state;
		if (!prevChecked) {
			selected.set(key, key)
			this.setState({selected});
			return;
		}
		selected.delete(key);
		this.setState({selected});
	};

	private onSelectedAll = (key: string, prevChecked: boolean) => {
		const {selected} = this.state;
		const contacts = this.getFilteredContacts();
		if (selected.size === 0) {
			contacts.forEach(c => selected.set(c._id, c._id));
		} else {
			selected.clear();
		}
		this.setState({selected});
	};

	private getFilteredContacts = (): SearchResultContact[] => {
		const {query, selectedContactType, allContacts, filterDeleted, filterUnpaired} = this.state;
		let units = CommonFEModule.getUnits();
		if (this.state.selectedLabels.length) { // @ts-ignore
			units = units.filter(u => u.comment && this.state.selectedLabels.includes(u.comment))
		}
		if (allContacts === null)
			return [];

		// @ts-ignore
		const results: SearchResultContact[] = allContacts.filter(c => {
			if (filterDeleted && c.deleted)
				return false;
			if (!!selectedContactType && c.contactType !== selectedContactType)
				return false;
			if (filterUnpaired) {
				const unitId = c.contactData.unitId || c.relatedUnitId;
				if (unitId && !units.find(u => u.unitId === unitId))
					return false;
			}

			if (!query)
				return true;

			return c.contactData.phoneNumber?.toLowerCase().includes(query)
				|| c.contactData.unitId?.toLowerCase().includes(query)
				|| c.relatedUnitId?.toLowerCase().includes(query)
				|| c.lastName?.toLowerCase().includes(query)
				|| c.firstName?.toLowerCase().includes(query);
		});
		//console.table(results);
		return results;
	}

	private openSmsDialog = () => {
		this.setState({smsDialogOpen: true});
	};

	private closeSmsDialog = () => {
		this.setState({smsDialogOpen: false});
	}

	private sendSms = async (validContacts: DB_Contact_View<any>[]) => {
		await MessagesModule.sendMultipleSMS({
			                                     phoneNumbers: validContacts?.map(c => c.contactData?.phoneNumber),
			                                     message: this.state.smsMessage
		                                     });
		this.closeSmsDialog();
	}

	private isValidPhoneNumber = (phoneNumber: string | null | undefined) => {
		return (!!phoneNumber?.trim());
	}

	renderSendSmsDialog = () => {
		const {smsDialogOpen, selected, allContacts} = this.state;
		// @ts-ignore
		const validContacts: SearchResultContact[] = allContacts?.filter(c => selected.has(c._id) && this.isValidPhoneNumber(c.contactData?.phoneNumber));

		return <dialog open={smsDialogOpen}>
			<p>Send SMS to multiple users</p>
			<div>Selected: {selected.size} | Have phone numbers: {validContacts?.length || 0} </div>
			<div>
				<TS_TextArea type={"text"} onChange={value => this.setState({smsMessage: value})}/>
			</div>
			<div>
				<button onClick={this.closeSmsDialog}>Cancel</button>
				&nbsp;
				<button onClick={() => this.sendSms(validContacts)}>Send</button>
			</div>
		</dialog>
	}


	render() {
		const {selected, query, filterDeleted, filterUnpaired} = this.state;
		return <>
			{this.renderSendSmsDialog()}
			<section style={{margin: "2vw", marginTop: "1rem", marginBottom: "1rem", width: "92vw"}}>
				<div style={{display: "flex", alignItems: "left", gap: "1rem"}}>
					<TS_Input
						style={{border: '1px solid'}}
						onChange={(value, id) => {
							this.setState({query: value})
						}}
						type={"text"}
						id={'phoneNumber'}
						value={query}
						placeholder="Search..."
					/>
					<div>{selected?.size} contacts selected.</div>
					<TS_Checkbox value="filter" checked={filterDeleted} label="Hide Deleted" onCheck={this.onFilterDeleted}/>
					<TS_Checkbox value="filter" checked={filterUnpaired} label="Hide Unpaired" onCheck={this.onFilterUnpaired}/>
					<div>
						Type: &nbsp;
						<select onChange={this.onContactTypeSelected}>
							<option value="">All</option>
							<option value="agentUser">Agent User</option>
							<option value="familyMember">Family Member</option>
							<option value="group">Group</option>
						</select>
					</div>
					<div>
						<button onClick={() => ComponentSelectLabels.show(
							{
								labels: this.state.labels,
								selectedLabels: this.state.selectedLabels,
								onLabelsUpdated: (selectedLabels: string[]) => {
									this.setState({selectedLabels})
								}
							})}>Select labels
						</button>
					</div>
					<div>
						<button disabled={selected.size < 1} onClick={this.openSmsDialog}>Send SMS</button>
					</div>
				</div>
				<br/>
				{this.renderContacts()}
			</section>
		</>
			;
	}

	private renderContacts = () => {
		const {query, selected} = this.state;
		const contacts = this.getFilteredContacts();
		if (contacts?.length === 0 && query)
			return <div style={{marginTop: "1rem"}}>No contacts found</div>;

		return <div style={{marginTop: "1rem"}}>
			<table style={{width: "100%"}}>
				<thead>
				<tr>
					<td><TS_Checkbox value={''} checked={selected.size > 1} label={""} onCheck={this.onSelectedAll}/></td>
					<td>Row</td>
					<td>First Name</td>
					<td>Middle Name</td>
					<td>Last Name</td>
					<td>Number</td>
					<td>Unit</td>
					<td>Contact of Unit</td>
					<td>Deleted</td>
				</tr>
				</thead>
				<tbody>
				{contacts.map((_c, index) => {
					return <tr key={_c._id}>
						<td><TS_Checkbox value={_c._id} checked={selected.has(_c._id)} onCheck={this.onContactSelected} label={""}/></td>
						<td>{index + 1}</td>
						<td>{_c.firstName}</td>
						<td>{_c.middleName}</td>
						<td>{_c.lastName}</td>
						<td>{_c.contactData.phoneNumber}</td>
						<td>{_c?.contactData.unitId}</td>
						<td>{_c.relatedUnitId}</td>
						<td>{_c.deleted ? 'Deleted' : ''}</td>
					</tr>
				})}
				</tbody>
			</table>
		</div>
	};
}


type Props = {
	labels: string[]
	onLabelsUpdated: (newLabels: string[]) => void
	selectedLabels: string[]
}


export class ComponentSelectLabels
	extends React.Component<Props, { labels: string[] }> {

	constructor(props: Props) {
		super(props);
		this.state = {
			labels: [...this.props.selectedLabels]
		}
	}

	public static show(props: Props) {
		new Dialog_Builder(
			<ComponentSelectLabels {...props} />)
			.setAllowIndirectClosing(true)
			.show();
	}

	render() {
		const {labels} = this.state;
		const length = labels.length;
		return <ul>
			<TS_Checkbox value={'all'} checked={!length || length === this.props.labels.length} onCheck={(value: string, checked: boolean) => {
				this.set(checked ? [] : [...this.props.labels])
			}} label={"All"}/>
			{this.props.labels.map((l: string) => <TS_Checkbox value={l} checked={labels?.includes(l)} label={l} onCheck={(value: string, checked: boolean) => {
				const idx = labels.indexOf(l);
				const newLabels: string[] = idx === -1 ? [...labels,
				                                          l] : labels.filter(ll => ll !== l)
				this.set(newLabels);
			}}/>)}
		</ul>
	}

	private set(newLabels: string[]) {
		this.props.onLabelsUpdated(newLabels)
		this.setState({labels: newLabels})
	}
}
