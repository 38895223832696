import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as Video from 'twilio-video';

type State = {};

type Props = {
	audioTrackPublication: Video.AudioTrackPublication;
	identity: string;
	isLocal: boolean;
};

export class AudioTrack
	extends BaseComponent<Props, State> {

	private audioRef = React.createRef<HTMLAudioElement>();

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	componentDidMount() {
		if (!this.audioRef?.current) {
			this.logError('Audio track div refs are not set.');
			return;
		}

		const audioTrack = this.props.audioTrackPublication.track;
		if (audioTrack) {
			audioTrack.attach(this.audioRef.current);
		}
	}

	componentWillUnmount() {
		// Workaround to avoid warning:
		// [RemoteAudioTrack #36: MTd46122610578cb1db69da98c44b6bbf6] Unintentionally paused:
		//     <audio autoplay></audio>
		if (this.audioRef?.current)
			this.audioRef.current.pause();


		if (!this.props.isLocal)
			return;

		// Stop all media tracks.
		const localAudioTrackPublication = this.props.audioTrackPublication as Video.LocalAudioTrackPublication;
		this.logInfo(`Unpublishing local audio track publication ${localAudioTrackPublication.trackSid}.`);

		localAudioTrackPublication.track?.detach();
		localAudioTrackPublication.track?.stop();
		localAudioTrackPublication.unpublish();

		this.logInfo(`Done.`);
	}

	render = () => {
		const {identity, audioTrackPublication} = this.props;

		return (
			<div id={`audio-track-${identity}`}
			     style={{width: 0, height: 0}}>
				{<audio ref={this.audioRef} muted={!audioTrackPublication.track?.isEnabled}/>}
			</div>
		);
	};
}
