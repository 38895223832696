import * as React from "react";
import Lottie from "lottie-react";
import {COLORS} from "@res/colors";
import {FONTS} from "@res/fonts";

const pwaLoader = require("@res/animations/pwa-loader.json");

type Props = {
	message?: string;
	overlay?: boolean;
	color?: string;
};

export class PWALoader
	extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	render() {
		const spinner = this.renderSpinner();
		if (!this.props.overlay)
			return spinner;

		return <div
			id={'loader'}
			onClick={(e: React.MouseEvent) => {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}}
			className="match_width match_height full_screen"
			style={{background: COLORS.white(0.5), pointerEvents: "auto", zIndex: 99}}>
			{spinner}
		</div>;
	}

	private renderSpinner = () => {
		const {color} = this.props;

		return (
			<div className="ll_h_c match_height fill">
				<div className="ll_v_c match_width">
					<Lottie animationData={pwaLoader} loop={true}/>
					<div style={{marginTop: '20px'}}>
						{
							FONTS.centra_no2_book(
								this.props.message !== undefined ? this.props.message : "Loading...",
								color || COLORS.brand_blue()
							)
						}
					</div>
				</div>
			</div>
		);
	};

}