import {COLORS,} from "@res/colors";

export const selectStyles = {
	container: (provided: any) => ({
		...provided,
		width: "100%",
		minWidth: 200,
		fontSize: 13,
		outline: "none"
	}),
	control: () => ({
		border: "1px solid",
		color: COLORS.grays_2,
		display: "flex",
		// Will become 32 with the 1px border.
		height: 30,
		fontSize: 13,
		outline: "none"
	}),
	singleValue: (provided: any) => ({
		...provided,
		color: COLORS.grays_dark,
		fontWeight: 500
	}),
	input: (provided: any) => ({
		...provided,
		color: COLORS.grays_dark
	}),
	option: (provided: any, state: any) => ({
		...provided,
		backgroundColor: "unset",
		color: COLORS.grays_dark,
		':hover': {
			backgroundColor: COLORS.grays_1
		}
	})
};
