import * as React from "react";
import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as emotion from "emotion";
import {
	InputHTMLAttributes
} from "react";
import {COLORS} from "@res/colors";
import {generateHex} from "@intuitionrobotics/ts-common";

type StyleKeys = (
	| "root"
	| "fieldsetWrapper"
	| "fieldset"
	| "legend"
	| "input"
	| "placeholder"
	| "inputFocusWithin"
	| "legendFocusWithin"
	| "fieldsetFocusWithin"
	| "placeholderFocusWithin"
	| "error"
	);

/*
	TODO: Specify type for css properties instead of `{ [key: string]: any }`, so that we have auto-complete.
	React.CSSPropertied is currently not working with emotion css package.
*/
type Styles = {
	[key in StyleKeys]?: { [key: string]: any };
};

type Props = {
	inputType: InputHTMLAttributes<HTMLInputElement>["type"];
	inputMode: InputHTMLAttributes<HTMLInputElement>["inputMode"];
	value: InputHTMLAttributes<HTMLInputElement>["value"];
	placeholder: InputHTMLAttributes<HTMLInputElement>["placeholder"];
	onChange: InputHTMLAttributes<HTMLInputElement>["onChange"];
	autocomplete: InputHTMLAttributes<HTMLInputElement>["autoComplete"];
	label: string;
	styles?: Styles;
	// By providing undefined, no space for error message is reserved.
	error?: boolean;
	errorMessage?: string
};

type State = {
	error: boolean
};

const root = (rootStyle?: Styles["root"]) => emotion.css(
	{
		boxSizing: "border-box",
		...rootStyle,
		marginBottom: 0,
	}
);

const fieldsetWrapper = (wrapperStyle?: Styles["fieldsetWrapper"]) => emotion.css(
	{
		boxSizing: "border-box",
		flexDirection: "column-reverse",
		...wrapperStyle
	}
);

const errorCSS = (errorState: boolean, errorStyle?: Styles["error"], rootMarginBottom?: number | string) => emotion.css(
	{
		boxSizing: "border-box",
		height: rootMarginBottom || "fit-content",
		color: COLORS.error_red(),
		fontSize: 12,
		visibility: errorState ? "visible" : "hidden",
		...errorStyle
	}
);


const inputFieldSet = (styles?: Styles, error?: boolean) => {
	const rules: { [k: string]: any } = {
		/* ---  fieldset when input not focused --- */
		boxSizing: "border-box",
		...styles?.fieldset,
		/*----------------------------------------------------*/

		/* --- legend tag when input not focused --- */
		"& > legend": {
			display: "none",
			...styles?.legend,
		},
		/*----------------------------------------------------*/

		/* --- input tag when input not focused --- */
		"& > input": {
			boxSizing: "border-box",
			...styles?.input
		},
		/*----------------------------------------------------*/

		/* --- input placeholder when input not focused --- */
		"& > input::placeholder": {
			...styles?.placeholder
		},
		/*----------------------------------------------------*/

		"&:focus-within": {
			/* --- fieldset when input focused --- */
			...styles?.fieldsetFocusWithin,
			/*----------------------------------------------------*/

			/* --- legend tag when input focused --- */
			"& > legend": {
				display: "flex",
				flexFlow: "row",
				alignItems: "center",
				boxSizing: "border-box",
				...styles?.legendFocusWithin,
			},
			/*----------------------------------------------------*/

			/* --- input tag when input focused --- */
			"& > input": {
				...styles?.inputFocusWithin,
			},
			/*----------------------------------------------------*/

			/* --- input placeholder tag when input focused --- */
			"& > input::placeholder": {
				color: "transparent",
				...styles?.placeholderFocusWithin,
			},
			/*----------------------------------------------------*/
		}
	};

	if (error)
		rules["border"] = `1px solid ${COLORS.error_red()}`;

	return emotion.css(rules);
};


export class LabeledInput
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			error: !!(props.error)
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
		if (!!(this.props.error) !== this.state.error)
			this.setState({error: !!(this.props.error)});
	}

	render() {
		const {
			      inputType,
			      value,
			      placeholder,
			      onChange,
			      label,
			      styles,
			      autocomplete,
			      inputMode,
			      errorMessage
		      } = this.props;

		return (
			<div id={`root-${generateHex(8)}`}
			     className={`ll_v_l match_width ${root(styles?.["root"])}`}>
				<div className={`match_width ll_v_c ${fieldsetWrapper(styles?.["fieldsetWrapper"])}`}>
					<fieldset
						className={`match_width ${inputFieldSet(styles, this.state.error)}`}>
						<legend className={`ll_h_c`}>
							{label}
						</legend>
						<input
							autoComplete={autocomplete}
							placeholder={placeholder}
							type={inputType}
							inputMode={inputMode}
							className={`match_all ll_h_c`}
							value={value}
							onChange={onChange}
						/>
					</fieldset>
				</div>
				{
					this.props.error !== undefined &&
					<div className={
						`ll_v_l ${errorCSS(this.state.error, styles?.["error"], styles?.root?.marginBottom)}`}>
						{errorMessage || "Invalid input"}
					</div>
				}
			</div>
		);
	}

}
