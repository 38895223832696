import {
    BaseComponent,
    DialogModule,
    ToastModule,
    TS_Checkbox,
    TS_Table
} from "@intuitionrobotics/thunderstorm/frontend";
import {ContactsModule, OnContactsUpdated} from "@modules/ContactsModule";
// import {
// 	PARAM_PRODUCT,
// 	PARAM_UNIT_ID
// } from "../../Routes";
import {
    ContactCommunicationMethod,
    UpdatingContactGeneric_Body,
    UpsertBody_ConnectionsData
} from "@app/ir-q-app-common/types/db-contact";
import {DB_Contact_View} from "@app/ir-q-app-common/types/db-contact-view";
import * as emotion from "emotion";
import {_keys, auditBy, ObjectTS} from "@intuitionrobotics/ts-common";
import {Dialog_AddContact} from "./Dialog_AddContact";
import {ConfirmDialog} from "../ui/dialogs/ConfirmDialog";
import {Loader} from "../../../widgets/Loader";
import * as React from "react";
import {UnitAndComment} from "@app/ir-q-app-common/types/units";
import {getSelectedUnit, getUserGroup} from "../../utils/utils";
import {buttonStyle} from "@styles/buttonStyle";
import {PARAM_VERSION} from "../../Routes";

const wideButton = {
    minWidth: "85px"
};

type State = {
    showLoader: boolean
}

type Props = {
    unit?: UnitAndComment,
}

export class ContactsTable
    extends BaseComponent<Props, State>
    implements OnContactsUpdated {

    constructor(props: Props) {
        super(props);
        const unit = getSelectedUnit(this.props.unit);
        const userGroup = getUserGroup(this.props.unit?.comment);
        const version = BaseComponent.getQueryParameter(PARAM_VERSION);

        if (unit)
            ContactsModule.getByUnit({...unit, userGroup, version});

        this.state = {
            showLoader: true
        };
    }

    __onContactsUpdated = () => {
        this.setState({showLoader: false});
    };

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (this.props.unit && prevProps.unit?.unitId !== this.props.unit?.unitId) {
            const userGroup = getUserGroup(this.props.unit?.comment);
            ContactsModule.getByUnit({...this.props.unit, userGroup})
        }
    }

    private getTsTable() {
        const selectedUnit = getSelectedUnit(this.props.unit);
        const contacts = this.getContacts(selectedUnit);
        return <div style={{height: "calc(100vh - 190px)", overflow: "auto"}}>
            <TS_Table<DB_Contact_View<"familyMember" | "group">>
                className={emotion.css`
				width: 97%;
				margin: 15px;
				border-collapse: collapse;
				position: relative;
				tbody > * > * {
					border: 1px solid black;
					padding: 8px;
				}`}
                id={"contacts-table"}
                header={["firstName",
                    "SSML_Name",
                    "middleName",
                    "lastName",
                    "contactType",
                    "gender",
                    "contactData",
                    "deleted"]}
                rows={contacts}
                headerRenderer={(columnKey: keyof DB_Contact_View<keyof ContactCommunicationMethod>) => {
                    return <span style={{fontWeight: "bold"}}>{columnKey}</span>;
                }}
                cellRenderer={(cellValue: DB_Contact_View<keyof ContactCommunicationMethod>[keyof DB_Contact_View<keyof ContactCommunicationMethod>], rowIndex: number, columnKey: keyof DB_Contact_View<keyof ContactCommunicationMethod>) => {
                    const vals: ObjectTS = typeof cellValue === "object" ? {emergency: false, ...cellValue} : {[columnKey]: cellValue};
                    return <div>{_keys(vals).map((k, i) => {
                        const key = columnKey + i;
                        const val = vals[k];
                        if (k === 'emergency') {
                            if (!selectedUnit || contacts[rowIndex].contactType !== "familyMember")
                                return;

                            return <TS_Checkbox
                                key={key}
                                value={val}
                                checked={val}
                                label={'Emergency'}
                                onCheck={_val => {
                                    // @ts-ignore
                                    const contactId = cellValue?._id as unknown as string;
                                    this.setAsEmergency(contactId, !_val, selectedUnit)
                                }}
                            />
                        }

                        if (k === "deleted")
                            return <span
                                key={key}
                            >{val === true ? "--deleted--" : ""}</span>;

                        if (k === columnKey)
                            return <span
                                key={key}
                            >{val}</span>;

                        return <span key={key}>
							<span>{`${k}: ${val}`}</span><br/>
						</span>;
                    })}</div>;
                }}
                actions={["edit | delete"]}
                actionsRenderer={(rowIndex: number) => {
                    if (!selectedUnit)
                        return;

                    const contact = contacts[rowIndex];
                    if (!contact || contact.deleted)
                        return;

                    if (contact.contactType === "group")
                        return;

                    if (contact.contactType === "landline")
                        return;

                    const owner = ContactsModule.getUnitContact(selectedUnit) as DB_Contact_View<"agentUser">;
                    const unit = owner ? {
                        unitId: owner.contactData.unitId,
                        product: owner.contactData.product
                    } : undefined;
                    if (!unit) {
                        ToastModule.toastError(`Unit is undefined - pls set it first`);
                        return null;
                    }

                    return <div className={"ll_h_c"}>
                        {owner && <div style={buttonStyle("blue")} onClick={() => {
                            Dialog_AddContact.show({contact, ownerId: owner._id, unit, onSave: this.onUpdate});
                        }}>
                            Edit
                        </div>}
                        {contact.contactType !== "agentUser" && <>
                            <div style={buttonStyle("red")} onClick={() => {
                                ConfirmDialog.show(
                                    {
                                        okLabel: "Delete",
                                        onOk: () => {
                                            this.setState({showLoader: true});
                                            DialogModule.close();
                                            ContactsModule.softDelete({_id: contact._id, unit});
                                        },
                                        title: "Delete Contact",
                                        displayMessage: `Are you sure you want to delete contact: ${contact.firstName} ${contact.lastName}`
                                    });
                            }}>
                                Delete
                            </div>
                            <div style={{...buttonStyle("red"), ...wideButton}} onClick={() => {
                                ConfirmDialog.show(
                                    {
                                        okLabel: "Hard Delete",
                                        onOk: () => {
                                            this.setState({showLoader: true});
                                            DialogModule.close();
                                            ContactsModule.hardDelete({_id: contact._id, unit});
                                        },
                                        title: "Hard Delete Contact",
                                        displayMessage: `Are you sure you want to hard delete contact: ${contact.firstName} ${contact.lastName} -- This will delete all contact conversations (from DB)`
                                    });
                            }}>
                                Hard delete
                            </div>
                        </>}
                    </div>;
                }}
            /></div>;
    }

    private onUpdate = () => {
        this.setState({showLoader: true})
    }

    private getContacts = (selectedUnit?: UnitAndComment) => {
        if (!selectedUnit)
            return [];

        const contactsByUnit = ContactsModule.getContactsByUnit(selectedUnit);
        if (!contactsByUnit)
            return []

        return contactsByUnit.map(c => {
            return {...c, contactData: {...c.contactData, _id: c._id}};
        })
    };

    private renderButton = () => {
        const selectedUnit = getSelectedUnit(this.props.unit);
        if (!selectedUnit)
            return;

        const owner = ContactsModule.getUnitContact(selectedUnit);
        if (!owner)
            return;

        return <div className="ll_h_c match_width" style={{justifyContent: "center", padding: 10}}>
            <div style={{...buttonStyle(), ...wideButton}} onClick={() => {
                Dialog_AddContact.show({ownerId: owner._id, unit: selectedUnit, onSave: this.onUpdate});
            }}>
                Add Contact
            </div>
        </div>;
    };

    render() {
        if (!getSelectedUnit(this.props.unit)) {
            this.logError(`You navigated to the unit's contacts table but with no unit...`);
            return null;
        }

        return <div style={{marginTop: 50, overflow: "hidden"}}>
            {this.renderButton()}
            {this.getTsTable()}
            {this.state.showLoader && <Loader overlay={true}/>}
        </div>;
    }

    private setAsEmergency = (_id: string, val: boolean, selectedUnit: UnitAndComment) => {
        const contactsByUnit = ContactsModule.getContactsByUnit(selectedUnit);
        if (!contactsByUnit)
            return;

        const contactsToUpdate = contactsByUnit.reduce((carry: UpdatingContactGeneric_Body<any>[], c) => {
            const connectionsData: UpsertBody_ConnectionsData = {
                agentUserData: {
                    byUnit: selectedUnit,
                    metadata: {}
                }
            };
            if (c._id === _id) {
                if (c.contactData.emergency !== val) {
                    connectionsData.emergency = val;
                    c._updated = auditBy("emergency contact UI");
                    carry.push({contact: c, connectionsData} as UpdatingContactGeneric_Body<any>)
                }
            } else {
                if (c.contactData.emergency === true) {
                    connectionsData.emergency = false;
                    c._updated = auditBy("emergency contact UI");
                    carry.push({contact: c, connectionsData} as UpdatingContactGeneric_Body<any>)
                }
            }
            return carry
        }, [])
        if (!contactsToUpdate.length)
            return;

        ContactsModule.createManySimple(contactsToUpdate);
        this.setState({showLoader: true})
    };

}
