import {
	BaseComponent,
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";
import * as emotion from "emotion";
import * as Video from 'twilio-video';
import {COLORS} from "@res/colors";
import {LocalParticipant} from "./LocalParticipant";
// import {VideoRoomMonitor} from '@twilio/video-room-monitor';
import {FONTS} from "@res/fonts";

const icon__call_end = require('@res/icons/icon__end_call.svg');
const icon__audio_on = require('@res/icons/icon__audio_on.svg');
const icon__audio_off = require('@res/icons/icon__audio_off.svg');
const icon__video_on = require('@res/icons/icon__video_on.svg');
const icon__video_off = require('@res/icons/icon__video_off.svg');
const icon__room_monitor = require('@res/icons/icon__room_monitor.svg');

type State = {};

type Props = {
	isAdmin: boolean;

	localParticipant: Video.LocalParticipant;
	localVideoPaused: boolean;

	remoteParticipantName?: string;
	disconnectFromRoom: () => void;
	toggleTracks: (trackKind: Video.Track.Kind) => void;
	onCameraSwitchClicked: (facingMode: "user" | "environment") => Promise<void>;

	localAudioDisabled: boolean;
	localVideoDisabled: boolean;
};


const overlay = emotion.css(
	{
		position: "absolute",
		bottom: 0,
		flexDirection: "column-reverse",
		pointerEvents: "none",
	}
);

const adminControls = emotion.css(
	{
		boxSizing: "border-box",
		marginBottom: "auto",
		justifyContent: "flex-end",
		padding: 24
	}
);

const monitorButton = emotion.css(
	{
		boxSizing: "border-box",
		height: 40,
		width: 40,
		justifyContent: "center",
		pointerEvents: "auto",
		backgroundColor: COLORS.midnight_blue(0.4),
		color: "lightgray",
		cursor: "default",
		borderRadius: "50%",
		padding: 8
	}
);

const controlButtons = emotion.css(
	{
		boxSizing: "border-box",
		height: 112,
		justifyContent: "center",
		backgroundColor: COLORS.midnight_blue(0.4),
		pointerEvents: "auto"
	}
);

const endCallButton = emotion.css(
	{
		width: 64,
		height: 64,
		margin: "0px 56px"
	}
);

const sideButton = emotion.css(
	{
		width: 64,
		height: 64,
	}
);

const localVideoAndRemoteName = emotion.css(
	{
		boxSizing: "border-box",
		justifyContent: "space-between",
		paddingLeft: 24,
		paddingRight: 24,
		marginBottom: 24
	}
);

export class Overlay
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	private openRoomMonitor = () => {
		// if (VideoRoomMonitor.isOpen)
			return;

		// VideoRoomMonitor.openMonitor();
	};

	render = () => {
		const {isAdmin} = this.props;

		return (
			<div className={`match_all ll_v_c ${overlay}`}>
				{this.renderControlButtons()}
				{this.renderRemoteNameAndLocalVideo()}
				{
					isAdmin && this.renderAdminControls()
				}
			</div>
		);
	};

	private renderAdminControls = () => {
		return (
			<div className={`match_width ll_h_c ${adminControls}`}>
				<div className={`ll_h_c ${monitorButton}`} onClick={this.openRoomMonitor}>
					<img src={icon__room_monitor}
					     className={`match_all`}
					     alt={"Room Monitor"}/>
				</div>
			</div>
		);
	};

	private renderLocalParticipant = () => {
		const {
			      localParticipant,
			      localVideoPaused,
			      localVideoDisabled,
			      onCameraSwitchClicked
		      } = this.props;

		return (
			<LocalParticipant
				videoDisabled={localVideoDisabled}
				onCameraSwitchClicked={onCameraSwitchClicked}
				videoPaused={localVideoPaused}
				localParticipant={localParticipant}/>
		);
	};

	private renderRemoteParticipantName = () => {
		const {remoteParticipantName} = this.props;
		return (
			remoteParticipantName && <div style={{paddingBottom: 8}}>
				{FONTS.centra_no2_book(remoteParticipantName, "white", 20)}
			</div>
		);
	};

	private renderRemoteNameAndLocalVideo = () => {
		return (
			<div className={`match_width ll_h_r ${localVideoAndRemoteName}`}>
				{this.renderRemoteParticipantName()}
				{this.renderLocalParticipant()}
			</div>
		);
	};

	private renderControlButtons = () => {
		const {localAudioDisabled, localVideoDisabled} = this.props;
		return <div className={`ll_h_c match_width ${controlButtons}`}>
			<img src={localAudioDisabled ? icon__audio_off : icon__audio_on}
			     className={`${sideButton}`}
			     alt={"Mute"}
			     onClick={() => this.props.toggleTracks(`audio`)}/>
			<img src={icon__call_end}
			     className={`${endCallButton}`}
			     alt={"End call"}
			     onClick={this.props.disconnectFromRoom}/>
			<img src={localVideoDisabled ? icon__video_off : icon__video_on}
			     className={`${sideButton}`}
			     alt={"Camera"}
			     onClick={() => this.props.toggleTracks(`video`)}/>
		</div>;
	};

}
