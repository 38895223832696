import * as React from "react";
import {
	BaseComponent,
	BrowserHistoryModule,
	GenericSelect,
	RoutingModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";
import {CommonFEModule, OnUnitsLoaded} from "@modules/CommonFEModule";
import {PARAM_PRODUCT, PARAM_UNIT_ID, Route_Playground} from "../../Routes";
import {MessagesModule, OnChatsArchived} from "@modules/MessagesModule";
import {Loader} from "../../../widgets/Loader";
import {AppPermissionsComponent} from "../../utils/PermissionsComponent";

type Props = {}
type State = {
    showLoader: boolean
}

export class ArchiveChats
    extends BaseComponent<Props, State>
    implements OnUnitsLoaded, OnChatsArchived {

    constructor(props: Props) {
        super(props);

        this.state = {showLoader: true}
    }

    componentWillUnmount() {
        BrowserHistoryModule.removeQueryParam(PARAM_UNIT_ID)
        BrowserHistoryModule.removeQueryParam(PARAM_PRODUCT)
    }

    __onUnitsLoaded = () => this.setState({showLoader: false});
    __onChatsArchived = () => this.setState({showLoader: false});

    render() {
        const unitId = BaseComponent.getQueryParameter(PARAM_UNIT_ID);
        const product = BaseComponent.getQueryParameter(PARAM_PRODUCT);
        const selectedUnit = unitId && product ? {unitId, product} : undefined;
        return <AppPermissionsComponent
            url={"/v1/message/archive-chats"}
            fallback={() => RoutingModule.redirect(Route_Playground)}>
            <section style={{marginTop: "5mm", marginBottom: "5mm", width: "98vw", marginLeft: 15}}>
                <div style={{width: 244}}>
                    <GenericSelect<Unit>
                        options={CommonFEModule.getUnits()}
                        selectedOption={selectedUnit}
                        iconClose={"D"}
                        iconOpen={"U"}
                        onChange={(unit: Unit) => {
                            BrowserHistoryModule.addQueryParam(PARAM_UNIT_ID, unit.unitId);
                            BrowserHistoryModule.addQueryParam(PARAM_PRODUCT, unit.product);
                            this.forceUpdate();
                        }}
                        styles={{}}
                        presentation={option => option.unitId}/>
                </div>
                {selectedUnit &&
                    <button className={'cloud-asset-action-button'} style={{backgroundColor: "orange", marginTop: 40}}
                            onClick={() => {
                                this.setState({showLoader: true})
                                MessagesModule.archiveChats(selectedUnit)
                            }}>
                        Migrate all chats
                    </button>}
                {this.state.showLoader && <Loader/>}
            </section>
        </AppPermissionsComponent>;
    }
}
