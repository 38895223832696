import {
	BaseComponent,
	Dialog_Builder
} from "@intuitionrobotics/thunderstorm/frontend";
import * as React from "react";

type Props = {
	src: string
}

export class ImagePopUp
	extends BaseComponent<Props> {
	public static show(props: Props) {
		new Dialog_Builder(
			<ImagePopUp {...props} />)
			.setAllowIndirectClosing(true)
			.setStyle({width: "30%"})
			.show();
	}

	render() {
		return <img alt={'image'} src={this.props.src} onClick={() => {ImagePopUp.show(this.props)}} />;
	}
}
