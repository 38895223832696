import {BaseDB_ApiGeneratorCaller} from "@intuitionrobotics/db-api-generator/frontend";
import {Body_SendMedia, Body_SendText, DB_Message, MessageMap} from "@app/ir-q-app-common/types/message";

import {ThunderDispatcher, XhrHttpModule} from "@intuitionrobotics/thunderstorm/frontend";
import {BaseHttpRequest, ErrorResponse, HttpMethod, OnRequestListener} from "@intuitionrobotics/thunderstorm";
import {OnPushMessageReceived, PushPubSubModule} from "@intuitionrobotics/push-pub-sub/frontend";
import {PushKey_GotMessageByTwilio, PushKey_NewMessage} from "@app/app-shared/common";
import {DB_Notifications} from "@intuitionrobotics/push-pub-sub";
import {generateHex, StringMap} from "@intuitionrobotics/ts-common";
import {
	Api_ArchiveChats,
	Api_GetSignedUrl,
	Api_ImageTransfer,
	Api_SendMedia,
	Api_SendText,
	Response_SendMediaToContact
} from "@app/ir-q-app-common/types/message-api";
import {now} from "moment";
import {Api_SendMMS, Api_SendMultipleSMS, Body_SendMMS, Body_SendMultipleSMS} from "@app/app-shared";
import {AccountModule, LoggedStatus, OnLoginStatusUpdated} from "@intuitionrobotics/user-account/frontend";
import {Unit} from "@app/ir-q-app-common/types/units";


export interface OnMessagesQueried {
	__onMessagesQueried: () => void;
}

export interface OnGotSignedUrl {
	__onGotSignedUrl: (path: string, signedUrl: string) => void;
}

export interface OnImageTransfer {
	__onImageTransfer: () => void;
}

export interface OnChatsArchived {
	__onChatsArchived: () => void;
}

export const dispatch_onMessagesQueried = new ThunderDispatcher<OnMessagesQueried, "__onMessagesQueried">("__onMessagesQueried");
export const dispatch_gotSignedUrl = new ThunderDispatcher<OnGotSignedUrl, "__onGotSignedUrl">("__onGotSignedUrl");
export const dispatch_gotImageTransfer = new ThunderDispatcher<OnImageTransfer, "__onImageTransfer">("__onImageTransfer");
export const dispatch_chatsArchived = new ThunderDispatcher<OnChatsArchived, "__onChatsArchived">("__onChatsArchived");
export const dispatch_requestCompleted = new ThunderDispatcher<OnRequestListener, "__onRequestCompleted">("__onRequestCompleted");
export const Key_SendText = "message/send-text";
export const Key_SendMedia = "message/send-media";
export const Key_UploadFile = "upload-file";
export const Key_SendMMS = 'message/send-mms';
export const Key_SendMultipleSMS = 'message/send-multiple-sms';

export class MessagesModule_Class
	extends BaseDB_ApiGeneratorCaller<DB_Message<keyof MessageMap>>
	implements OnPushMessageReceived, OnLoginStatusUpdated {

	private messages: DB_Message<keyof MessageMap>[] = [];
	__onMessageReceived = (notification: DB_Notifications) => {
		switch (notification.pushKey) {
			case PushKey_GotMessageByTwilio:
				this.logInfo("received by push (in twilio hook) - ", JSON.stringify((notification)));
				break;
			case PushKey_NewMessage:
				return this.upsertAll(notification.data);
			default:
				break;
		}
	};
	private signedUrls: StringMap = {};

	onLoginStatusUpdated = () => {
		switch (AccountModule.getLoggedStatus()) {
			// We register on component did mount in the app (not in the pwa)
			// case LoggedStatus.LOGGED_IN:
			// 	return PushPubSubModule.subscribeMulti([{pushKey: PushKey_GotMessageByTwilio}, {pushKey: PushKey_NewMessage}]);
			case LoggedStatus.LOGGED_OUT:
				[{pushKey: PushKey_GotMessageByTwilio}, {pushKey: PushKey_NewMessage}].forEach(sub => PushPubSubModule.unsubscribe(sub))
		}
	};

	getMessages = () => this.messages;

	async onQueryReturned(items: DB_Message<keyof MessageMap>[]) {
		this.messages = items;
		dispatch_onMessagesQueried.dispatchUI([]);
	}

	private upsertAll = (messages: DB_Message<keyof MessageMap>[]) => {
		for (const message of messages) {
			let idx = this.messages.findIndex(m => m._id === message._id);
			if (idx === -1)
				idx = this.messages.length;

			this.messages[idx] = message;
		}
		dispatch_onMessagesQueried.dispatchUI([]);
	};

	sendTextMessage(body: Body_SendText, requireResponse: boolean) {
		if (requireResponse) {
			const messagesResponses = ["Yes", "No", "Contact Me"].map((response: string) => (
				{
					_id: generateHex(32),
					chatId: "",
					type: "text",
					senderId: body.senderId,
					data: {text: response},
					draft: true,
					timestamp: now()
				} as DB_Message<"text">));

			body["choices"] = messagesResponses.length > 0 ? {options: messagesResponses} : undefined;
		}

		XhrHttpModule
			.createRequest<Api_SendText>(HttpMethod.POST, Key_SendText)
			.setJsonBody(body)
			.setRelativeUrl("/v1/message/send-text")
			.setOnError((request: BaseHttpRequest<Api_SendText>, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to send text", resError);
				dispatch_requestCompleted.dispatchUI([Key_SendText,false])
			})
			.execute();
	}

	async sendMediaMessage(body: Body_SendMedia, file?: File) {
		if (!file)
			return this.logErrorBold("No file to upload media");

		const response: Response_SendMediaToContact = await XhrHttpModule
			.createRequest<Api_SendMedia>(HttpMethod.POST, Key_SendMedia)
			.setJsonBody(body)
			.setRelativeUrl("/v1/message/send-media")
			.setOnError((request: BaseHttpRequest<Api_SendMedia>, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to send media", resError);
			})
			.executeSync();

		XhrHttpModule
			.createRequest(HttpMethod.PUT, Key_UploadFile)
			.setHeader("Content-Type", response.mimeType)
			.setBody(file)
			.setUrl(response.signedUrl)
			.setOnError((request: any, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to load data", resError);
				dispatch_requestCompleted.dispatchUI([Key_UploadFile,false])
			})
			.execute(() => {

			});
	}

	sendMMS(body: Body_SendMMS) {
		XhrHttpModule
			.createRequest<Api_SendMMS>(HttpMethod.POST, Key_SendMMS)
			.setJsonBody(body)
			.setRelativeUrl("/v1/message/send-mms")
			.execute();
	}

	sendMultipleSMS(body: Body_SendMultipleSMS) {
		XhrHttpModule.createRequest<Api_SendMultipleSMS>(HttpMethod.POST, Key_SendMultipleSMS)
			.setJsonBody(body)
			.setRelativeUrl("/v1/message/send-multiple-sms")
			.execute();
	}

	fetchSignedUrl = (messageId: string) => {
		XhrHttpModule
			.createRequest<Api_GetSignedUrl>(HttpMethod.POST, "fetch-signed-url")
			.setJsonBody({messageId})
			.setRelativeUrl("/v1/message/get-read-signed-url")
			.setOnError((request: any, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to get signed url", resError);
			})
			.execute(response => {
				const path = response.data.bucketPointer?.path;
				const updatedSignedUrl = response.data.signedUrl;
				if (!path || !updatedSignedUrl)
					return;

				this.signedUrls[path] = updatedSignedUrl;
				dispatch_gotSignedUrl.dispatchUI([path, updatedSignedUrl]);
			});
	};

	getSignedUrl = (path: string) => this.signedUrls[path];

	imageTransfer(value: string) {
		XhrHttpModule
			.createRequest<Api_ImageTransfer>(HttpMethod.POST, "image-transfer")
			.setJsonBody({url: value})
			.setRelativeUrl("/v1/message/image-transfer")
			.setOnError((request: any, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to transfer image - wip", resError);
			})
			.execute(() => {
				dispatch_gotImageTransfer.dispatchUI([]);
			});

	}

	archiveChats(selectedUnit: Unit) {
		XhrHttpModule
			.createRequest<Api_ArchiveChats>(HttpMethod.POST, "archive-chats")
			.setJsonBody(selectedUnit)
			.setRelativeUrl("/v1/message/archive-chats")
			.setOnError((request: any, resError?: ErrorResponse<any>) => {
				this.logWarning("failed to archive chats", resError);
			})
			.execute(() => {
				dispatch_chatsArchived.dispatchUI([]);
			});
	}
}

export const MessagesModule = new MessagesModule_Class({key: "messages", relativeUrl: "/v1/message"}, "MessagesModule");
