import * as emotion from "emotion";

export const chipGroupCss = emotion.css`
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    padding: 0;
`;

export const chipCss = emotion.css`
    display: flex;
    flex-direction: row;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    background-color: #e6e6e6;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
`;

export const chipGroupNewEntry = emotion.css`
    display: flex;
    flex-direction: row;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    background-color: #e6e6e6;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
`;
