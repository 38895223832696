import * as React from "react";
import * as emotion from "emotion";
import {
	BaseComponent,
	DialogModule,
} from "@intuitionrobotics/thunderstorm/frontend";
import {DB_Contact_View} from "@app/ir-q-app-common/types/db-contact-view";
import {COLORS} from "@res/colors";
import {Color} from "csstype";
import {generateHex} from "@intuitionrobotics/ts-common";
import {TwilioVideoModule} from "@modules/TwilioVideoModule";
import {StorageKey_PWAUserName} from "@modules/ContactLoginFEModule";
import {Dialog_VideoCallMenu} from "./Dialog_VideoCallMenu";
import {AccountModule} from "@intuitionrobotics/user-account/frontend";
import {FONTS} from "@res/fonts";
import {DB_Call} from "@app/app-shared/call-status";
import {PWALoader} from "../../../../widgets/PWALoader";
import moment from "moment";

const icon__elliq_connect_logo = require('@res/icons/icon__elliq_connect_logo_white.svg');
const icon__call_button = require('@res/icons/icon__call_button.svg');
const icon__info_right = require('@res/icons/icon__info_right.svg');
const icon__missed_call = require('@res/icons/icon__missed_call.svg');
const icon__pwa_menu = require('@res/icons/icon__pwa_menu.svg');

type State = {};

type Props = {
	unitContact?: DB_Contact_View<"agentUser">;
	checkingMissed: boolean;
	onSubmit: () => void;
	missedCall?: DB_Call;
	dismissMissedCall: () => void;
};

const wrapper = emotion.css(
	{
		boxSizing: "border-box",
		backgroundColor: COLORS.salmon(),
		justifyContent: "space-between",
		position: "relative"
	}
);

const missedCallHeader = emotion.css(
	{
		position: "absolute",
		top: 1,
		zIndex: 1,
		justifyContent: "space-between",
		boxSizing: "border-box",
		height: 56,
		backgroundColor: "rgba(255, 249, 248, 1)",
		padding: 16,
		boxShadow: "0px 4px 4px rgba(158, 89, 72, 0.5)"
	}
);

const header = emotion.css(
	{
		zIndex: 0,
		justifyContent: "space-between",
		boxSizing: "border-box",
		padding: 16,
	}
);

const body = emotion.css(
	{
		zIndex: 0,
		boxSizing: "border-box",
		justifyContent: "space-evenly",
		flex: 1
	}
);

const circle = (size: number, bgColor: Color, textColor?: Color) => emotion.css(
	{
		width: size,
		height: size,
		borderRadius: "50%",
		backgroundColor: bgColor,
		color: textColor || "black",

		display: "flex",
		flexFlow: "column",
		alignItems: "center",
		justifyContent: "center",
	}
);

export class PlaceCall
	extends BaseComponent<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {};
	}

	private placeCall = () => {
		const {unitContact} = this.props;
		if (!unitContact) {
			this.logError(`No unit contact provided`);
			return;
		}

		const unitId = unitContact.contactData.unitId;
		const roomName = `${unitContact.contactData.unitId}--${generateHex(32)}`;
		this.logInfo(`Will attempt to start video call with unit ${unitId}. Room name will be ${roomName}.`);
		TwilioVideoModule.callUnit(roomName, unitId);
		this.props.onSubmit();
	};

	private onLogoutClick = () => {
		DialogModule.close();
		AccountModule.logout();
	};

	render = () => {
		const {unitContact} = this.props;
		if (!unitContact || unitContact.deleted)
			return this.renderNoContact();

		return <div className={`match_all ll_v_c ${wrapper}`}>
			{this.props.checkingMissed && <PWALoader message={""} overlay={true}/>}
			{this.renderHeader()}
			{this.renderBody()}
			{this.renderMoreInfo()}
		</div>;
	};

	private renderHeader = () => {
		const username = StorageKey_PWAUserName.get("")?.trim()?.charAt(0);

		return (
			<>
				{this.props.missedCall && this.missedCallHeader(this.props.missedCall)}
				<div id={`place-call-header`} className={`ll_h_c match_width ${header}`}>
					<div className={`ll_h_c`} style={{height: 40}}>
						<img src={icon__elliq_connect_logo} alt={"Logo ElliQ Connect"}/>
					</div>
					{
						username ?
							<div className={`${circle(32, COLORS.white(0.6))}`}
							     onClick={() => {
								     Dialog_VideoCallMenu.show(this.onLogoutClick);
							     }}>
								<div className={`ll_h_c`} style={{cursor: "default"}}>
									{FONTS.centra_no2_book(username, COLORS.brand_blue(), 16)}
								</div>
							</div> :
							<div style={{width: 32, height: 32}}
							     onClick={() => {
								     Dialog_VideoCallMenu.show(this.onLogoutClick);
							     }}>
								<img src={icon__pwa_menu} alt={"Menu"} className={'match_all'}/>
							</div>
					}
				</div>
			</>
		);
	};

	private missedCallHeader = (missedCall: DB_Call) => {
		const format = moment(missedCall.timestamp).isSame(new Date(), "day") ? "h:mm a" : "h:mm a DD MMM";

		return <div id={`missed-call-header`} className={`ll_h_c match_width ${missedCallHeader}`}>
			<div className={`ll_h_c`} style={{height: 24, marginRight: 8}}>
				<img src={icon__missed_call} alt={"Logo ElliQ Connect"}/>
			</div>
			<div className={`ll_h_c`} style={{flex: 1}}>
				{
					FONTS.centra_no2_book(
						`Missed call from ${this.props.unitContact?.firstName} at ${moment(missedCall.timestamp).format(format)}`,
						"rgba(131, 2, 2, 1)",
						16
					)
				}
			</div>
			<div className={`ll_h_c`} style={{cursor: "default"}} onClick={() => this.props.dismissMissedCall()}>
				{
					FONTS.centra_no2_book(
						`OK`,
						COLORS.brand_blue(),
						16
					)}
			</div>
		</div>;
	};

	private renderBody = () => {
		return (
			<div id={`place-call-body`} className={`ll_v_c match_width ${body}`}>
				{this.renderCallerInfo()}
				{this.renderCallButton()}
			</div>
		);
	};

	private renderCallerInfo = () => {
		const {unitContact} = this.props;

		if (!unitContact)
			return null;

		const firstName = unitContact.firstName.trim();
		const lastName = unitContact.lastName?.trim();
		const displayLetters = lastName ? `${firstName.substring(0, 1)}${lastName.substring(0, 1)}` : firstName.substring(0, 2);

		return (
			<div id={`place-call-caller-info`} className={`ll_v_c match_width`}>
				<div className={`${circle(176, COLORS.white(0.6))}`}>
					<div>
						{
							FONTS.centra_no2_bold(displayLetters.toUpperCase(), COLORS.brand_blue(), 40)
						}
					</div>
				</div>
				<div style={{marginTop: 24}}>
					{FONTS.centra_no2_bold(unitContact.firstName, "white", 40)}
				</div>
			</div>
		);
	};

	private renderCallButton = () => {
		return (
			// TODO: Look into button alignment
			<div className={`ll_v_c match_width`} onClick={this.placeCall}>
				<img src={icon__call_button} alt={"Call"}
				     style={{filter: "drop-shadow(2px 4px 7px rgba(200, 109, 91, 0.5))"}}/>
			</div>
		);
	};

	private renderMoreInfo = () => {
		const url = "https://info.elliq.com/connect";
		return (
			<div className={`ll_h_c`} style={{marginBottom: 40}}
			     onClick={() => window.open(url, '_blank')}>
				<div>
					{FONTS.centra_no2_medium("What else can I do with ElliQ Connect?", "white", 16)}
				</div>
				<div className={`ll_h_c`} style={{width: 16, height: 16, justifyContent: "center", marginLeft: 4}}>
					<img style={{width: 7, height: 10}} src={icon__info_right} alt={`Info right`}/>
				</div>
			</div>
		);
	};

	private renderNoContact = () => {
		return <div>
			Couldn't find an ElliQ to call<br/>
			Please contact the support team
		</div>;
	};
}
