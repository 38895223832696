import React from 'react';
import {VideoCalling} from "./VideoCalling";
import {BaseComponent} from "@intuitionrobotics/thunderstorm/frontend";
import {
	OnGetCallableUnitsReturned,
	TwilioVideoModule
} from "@modules/TwilioVideoModule";
import {DB_Contact} from "@app/ir-q-app-common/types/db-contact";
import {PWALoader} from "../../../widgets/PWALoader";

type State = {
	unitContact?: DB_Contact<any>;
	installEvent?: Event;
	loader: boolean;
	canInstall: boolean;
}

type Props = {};

export class VideoCallingAppSuggested
	extends BaseComponent<{}, State>
	implements OnGetCallableUnitsReturned {

	constructor(props: Props) {
		super(props);
		this.state = {
			loader: true,
			installEvent: undefined,
			canInstall: false,
		};
	}

	__onGetCallableUnitsReturned = (success: boolean, unitContact?: DB_Contact<'agentUser'>): void => {
		this.setState({unitContact, loader: false});
	};

	componentDidMount() {
		window.addEventListener("beforeinstallprompt", this.beforeInstallPromptListener);
		window.addEventListener('appinstalled', this.appInstalledListener);

		// Get units that the user can call.
		TwilioVideoModule.getCallableUnits();
	}

	componentWillUnmount() {
		window.removeEventListener("beforeinstallprompt", this.beforeInstallPromptListener);
		window.removeEventListener("appinstalled", this.appInstalledListener);
	}

	beforeInstallPromptListener = (e: Event) => {
		// Investigation is needed on when this even is not triggered.
		// If the user dismisses once, it seems to not be triggered any more.
		this.logInfo("beforeinstallprompt event has triggered.");
		// Prevent the mini info bar from appearing on mobile.
		e.preventDefault();
		this.setState({installEvent: e, canInstall: true});
	};

	appInstalledListener = (e: Event) => {
		// TODO: Log install to analytics.
		this.logInfo('App install success ', e);
	};

	onCallEnded = () => {
		// Stub
	};

	onCallFailed = () => {
		// Stub
	};

	private installClicked = async () => {
		const {installEvent} = this.state;

		if (!installEvent) {
			this.logError(`Cannot install PWA.`);
			return;
		}

		// prompt() return a promise per https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent.
		await (installEvent as any).prompt();

		const userChoice = await (installEvent as any).userChoice;
		if (userChoice.outcome === "accepted") {
			this.logInfo(`User accepted to install.`);
		} else {
			this.logInfo('User refused to install.');
		}
	};

	render() {
		const {loader, unitContact, canInstall} = this.state;
		return (
			<>
				{
					loader ? <PWALoader message={""} overlay={false}/> : <VideoCalling
						isAdmin={false}
						unitContact={unitContact}
						onCallEnded={this.onCallEnded}
						onCallFailed={this.onCallFailed}
						installClicked={this.installClicked}
						canInstall={canInstall}
					/>
				}
			</>
		);
	}

}
