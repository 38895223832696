import {
	Module
} from "@intuitionrobotics/ts-common";
import {
	ThunderDispatcher,
	ToastModule,
	XhrHttpModule
} from "@intuitionrobotics/thunderstorm/frontend";
import {
	BaseHttpRequest,
	ErrorResponse,
	HttpMethod
} from "@intuitionrobotics/thunderstorm";
import {
	ApiTwilioVideoAnswerCall,
	ApiTwilioVideoCallUnit,
	ApiTwilioVideoCreateRoom,
	ApiTwilioVideoRequestAccessToken
} from "@app/app-shared";
import {
	Response_AnswerCall,
	Response_CallUnit,
	Response_CreateVideoRoom,
	Response_VideoAccessToken
} from "@app/app-shared/twilio";
import {
	Api_GetBySession,
	DB_Contact
} from "@app/ir-q-app-common/types/db-contact";
import * as Video from "twilio-video";
import moment from "moment";
import {
	Api_CallStatus_EndTheCall,
	Api_CallStatus_Unique,
	CallStatuses,
	DB_Call
} from "@app/app-shared/call-status";

const {Logger} = Video;

export interface OnVideoRoomCreationReturned {
	__onVideoRoomCreationReturnedError: () => void;
}

export interface OnRequestAccessTokenReturned {
	__onRequestAccessTokenReturnedError: (roomName: string) => void;
}

export interface OnCallUnitReturned {
	__onCallUnitReturnedError: (unitId: string) => void;
}

export interface OnGetCallableUnitsReturned {
	__onGetCallableUnitsReturned: (success: boolean, unit?: DB_Contact<'agentUser'>) => void;
}

export interface OnFoundMissedCall {
	__onFoundMissedCall: (call: DB_Call) => void;
}

export interface OnAnswerCallError {
	__onAnswerCallError: (callId: string, unitIdCalling: string) => void;
}

export const dispatch_onVideoRoomCreationReturnedError = new ThunderDispatcher<OnVideoRoomCreationReturned, "__onVideoRoomCreationReturnedError">(
	"__onVideoRoomCreationReturnedError"
);

export const dispatch_onRequestAccessTokenReturnedError = new ThunderDispatcher<OnRequestAccessTokenReturned, "__onRequestAccessTokenReturnedError">(
	"__onRequestAccessTokenReturnedError"
);

export const dispatch_onCallUnitReturnedError = new ThunderDispatcher<OnCallUnitReturned, "__onCallUnitReturnedError">(
	"__onCallUnitReturnedError"
);

export const dispatch_onGetCallableUnitsReturned = new ThunderDispatcher<OnGetCallableUnitsReturned, "__onGetCallableUnitsReturned">(
	"__onGetCallableUnitsReturned"
);

export const dispatch_onFoundMissedCall = new ThunderDispatcher<OnFoundMissedCall, "__onFoundMissedCall">(
	"__onFoundMissedCall"
);

export const dispatch_onAnswerCallError = new ThunderDispatcher<OnAnswerCallError, "__onAnswerCallError">(
	"__onAnswerCallError"
);

export type CallDetails = {
	roomName: string;
	roomSid: string;
	roomAccessToken: string;
	callId?: string;
}

export interface OnCallDetails {
	__onCallDetails: (callDetails: CallDetails) => void;
}

export const dispatch_onCallDetails = new ThunderDispatcher<OnCallDetails, "__onCallDetails">("__onCallDetails");


const Key_CreateVideoRoom = "key--create-video-room";
const Key_RequestAccessToken = "key--request-access-token";
const Key_CallUnit = "key--callUnit";
const Key_AnswerCall = "key--answerCall";
const Key_GetCallableUnits = "key--get-callable-units";
const Key_CallStatus_EndCall = "key--call-status-end-call";
const Key_CallStatus_Unique = "key--call-status-unique";


class TwilioVideoModule_Class
	extends Module {

	private twilioLogger?: Video.Log.Logger;

	protected init(): void {
		super.init();
		this.createTwilioVideoLogger();
	}

	private createTwilioVideoLogger = () => {
		const twilioLogger = Logger.getLogger('twilio-video');
		// The default logger factory twilio provides.
		// const originalFactory = twilioLogger.methodFactory;
		twilioLogger.methodFactory = (_methodName, logLevel, _loggerName) => {
			// const method = originalFactory(_methodName, logLevel, _loggerName);
			return (_datetime, _logLevel, component, message, data) => {
				const prefix = "[Twilio Video Call]";
				// method(prefix, datetime, _logLevel, component, message, data);
				console.log(`\n${prefix}\n${moment().format(`YYYY-MM-DD HH:mm:ss.SSS`)} - ${component}\n`, `\nMessage\n`, message, `\n\nData\n`, data, `\n\n`);
			};
		};

		twilioLogger.setLevel("silent");
		this.twilioLogger = twilioLogger;
	};

	enableLogger = () => {
		if (!this.twilioLogger)
			return;

		this.twilioLogger.setLevel("debug");
		this.logInfo('Enabled twilio logger.');
	};

	disableLogger = () => {
		if (!this.twilioLogger)
			return;

		this.twilioLogger.setLevel("silent");
		this.logInfo('Disabled twilio logger.');
	};

	createVideoRoom(roomName: string) {
		if (!roomName || !roomName.trim()) {
			this.dispatchRoomCreationError();
			return ToastModule.toastError("Please provide a room name.");
		}

		XhrHttpModule
			.createRequest<ApiTwilioVideoCreateRoom>(HttpMethod.POST, Key_CreateVideoRoom)
			.setJsonBody(
				{
					roomName,
					returnAccessToken: true
				})
			.setRelativeUrl("/v1/twilio/video/create-room")
			.setOnError((request: BaseHttpRequest<ApiTwilioVideoCreateRoom>, resError?: ErrorResponse<any>) => {
				this.logError("Failed to create video room", resError);
				this.dispatchRoomCreationError();
				return ToastModule.toastError(`Failed to create video room`);
			})
			.execute(
				(response: Response_CreateVideoRoom) => {
					this.logInfo("Got response for creating video room:", response);
					if (!response.roomName || !response.roomSid || !response.accessToken) {
						this.logError("Failed to create room and get access token.");
						this.dispatchRoomCreationError();
						return ToastModule.toastError(`Failed to create video room`);
					}

					dispatch_onCallDetails.dispatchUI([{
						roomName: response.roomName,
						roomSid: response.roomSid,
						roomAccessToken: response.accessToken
					}]);
				}
			);
	}

	requestAccessToken(roomName: string) {
		if (!roomName || !roomName.trim()) {
			this.dispatchRequestAccessTokenError(roomName);
			return ToastModule.toastError("Please provide a room name");
		}

		XhrHttpModule
			.createRequest<ApiTwilioVideoRequestAccessToken>(HttpMethod.POST, Key_RequestAccessToken)
			.setJsonBody(
				{
					roomName
				})
			.setRelativeUrl("/v1/twilio/video/request-access-token")
			.setOnError((request: BaseHttpRequest<ApiTwilioVideoRequestAccessToken>, resError?: ErrorResponse<any>) => {
				this.logError("Failed to join video room", resError);
				this.dispatchRequestAccessTokenError(roomName);
				return ToastModule.toastError(`Failed to join video room`);
			})
			.execute(
				(response: Response_VideoAccessToken) => {
					this.logInfo("Got response from requesting video access token:", response);
					if (!response.roomName || !response.roomSid || !response.accessToken) {
						this.logError("Failed to join video room.");
						this.dispatchRequestAccessTokenError(roomName);
						return ToastModule.toastError(`Failed to join video room`);
					}

					dispatch_onCallDetails.dispatchUI([{
						roomName: response.roomName,
						roomSid: response.roomSid,
						roomAccessToken: response.accessToken
					}]);
				}
			);
	}

	callUnit(roomName: string, unitId: string) {
		const trimmedRoomName = roomName.trim();
		const trimmedUnitId = unitId.trim();

		if (!trimmedRoomName) {
			this.dispatchCallUnitError(unitId);
			return ToastModule.toastError("Please provide a room name");
		}

		if (!trimmedUnitId) {
			this.dispatchCallUnitError(unitId);
			return ToastModule.toastError("Please provide a unit id");
		}

		XhrHttpModule
			.createRequest<ApiTwilioVideoCallUnit>(HttpMethod.POST, Key_CallUnit)
			.setJsonBody(
				{
					roomName: trimmedRoomName,
					unitId: trimmedUnitId
				})
			.setRelativeUrl("/v1/twilio/video/call-unit")
			.setOnError((request: BaseHttpRequest<ApiTwilioVideoCallUnit>, resError?: ErrorResponse<any>) => {
				this.logError(`Failed to call unit ${unitId}`, resError);
				this.dispatchCallUnitError(unitId);
				return ToastModule.toastError(`Failed to call unit ${unitId}`);
			})
			.execute(
				(response: Response_CallUnit) => {
					this.logInfo("Got response from calling unit:", response);
					if (!response.roomName || !response.roomSid || !response.accessToken || !response.unitId || !response.callId) {
						this.logError(`Failed to call unit ${unitId}.`);
						this.dispatchCallUnitError(unitId);
						return ToastModule.toastError(`Failed to call unit ${unitId}`);
						// In case of 403 error, should we log out?
					}

					dispatch_onCallDetails.dispatchUI([{
						roomName: response.roomName,
						roomSid: response.roomSid,
						roomAccessToken: response.accessToken,
						callId: response.callId
					}]);
				}
			);
	}

	answerCall(roomName: string, callId: string, unitIdCalling: string) {
		const trimmedRoomName = roomName.trim();
		const trimmedCallId = callId.trim();
		const trimmedUnitIdCalling = unitIdCalling.trim();

		if (!trimmedRoomName || !trimmedCallId || !trimmedUnitIdCalling) {
			this.dispatchAnswerCallError(callId, trimmedUnitIdCalling);
			return ToastModule.toastError("Please provide a room name");
		}

		XhrHttpModule
			.createRequest<ApiTwilioVideoAnswerCall>(HttpMethod.POST, Key_AnswerCall)
			.setJsonBody(
				{
					unitIdCalling: trimmedUnitIdCalling,
					callId: trimmedCallId,
					roomName: trimmedRoomName,
				})
			.setRelativeUrl("/v1/twilio/video/answer")
			.setOnError((request: BaseHttpRequest<ApiTwilioVideoAnswerCall>, resError?: ErrorResponse<any>) => {
				this.logError(`Failed to answer call with id ${callId} from unit ${unitIdCalling}`, resError);
				this.dispatchAnswerCallError(callId, unitIdCalling);
			})
			.execute(
				(response: Response_AnswerCall) => {
					const {callStatus, callInfo} = response;
					this.logInfo("Got response for answering call:", response);
					if (callStatus.status === CallStatuses.MISSED) {
						this.dispatchFoundMissedCall(callStatus);
					} else if (callInfo?.callId && callInfo?.unitId && callInfo?.roomSid && callInfo?.roomName && callInfo?.accessToken) {
						dispatch_onCallDetails.dispatchUI([{
							roomName: callInfo.roomName,
							roomSid: callInfo.roomSid,
							roomAccessToken: callInfo.accessToken,
							callId: callInfo.callId
						}]);
					} else {
						this.logError(`Failed to answer call with id ${callId} from unit ${unitIdCalling}.`);
						this.dispatchAnswerCallError(callId, unitIdCalling);
					}
				}
			);
	}

	getCallableUnits() {
		XhrHttpModule
			.createRequest<Api_GetBySession>(HttpMethod.POST, Key_GetCallableUnits)
			.setRelativeUrl("/v2/contacts/get-by-session")
			.setOnError((request: BaseHttpRequest<Api_GetBySession>, resError?: ErrorResponse<any>) => {
				this.logError("Failed to get callable units:", resError);
				this.dispatchGetOnGetCallableUnitsError();
				return ToastModule.toastError(`Failed to get ElliQ info`);
			})
			.execute(
				(response: DB_Contact<any>[]) => {
					this.logDebug("Got response from requesting video access token:", response);

					const unitContact = response.find(c => c.contactType === "agentUser")
					dispatch_onGetCallableUnitsReturned.dispatchUI(
						[true,
						 unitContact]
					);
				}
			);
	}

	notifyEndCall(unitId: string, callId: string) {
		XhrHttpModule
			.createRequest<Api_CallStatus_EndTheCall>(HttpMethod.POST, Key_CallStatus_EndCall)
			.setJsonBody(
				{
					unitId,
					callId
				}
			)
			.setRelativeUrl("/v1/call/end")
			.setOnError((request: BaseHttpRequest<Api_CallStatus_EndTheCall>, resError?: ErrorResponse<any>) => {
				this.logError("Failed to notify about end of call:", resError);
			})
			.execute(
				() => {
					this.logInfo("Notified about end of call with callId", callId);
				}
			);
	}

	getCallStatus(callId: string, callback: (callStatus: DB_Call) => void) {
		XhrHttpModule
			.createRequest<Api_CallStatus_Unique>(HttpMethod.GET, Key_CallStatus_Unique)
			.setUrlParams({_id: callId})
			.setRelativeUrl("/v1/call/status/unique")
			.setOnError((request: BaseHttpRequest<Api_CallStatus_Unique>, resError?: ErrorResponse<any>) => {
				this.logError("Failed to get call doc status:", resError);
			})
			.execute(
				(response: DB_Call) => {
					callback(response);
				}
			);
	}

	private dispatchRoomCreationError = () => {
		dispatch_onVideoRoomCreationReturnedError.dispatchUI([]);
	};

	private dispatchRequestAccessTokenError = (roomName: string) => {
		dispatch_onRequestAccessTokenReturnedError.dispatchUI([roomName]);
	};

	private dispatchCallUnitError = (unitId: string) => {
		dispatch_onCallUnitReturnedError.dispatchUI([unitId]);
	};

	private dispatchAnswerCallError = (callId: string, unitIdCalling: string) => {
		dispatch_onAnswerCallError.dispatchUI([callId,
		                                       unitIdCalling]);
	};

	private dispatchFoundMissedCall = (call: DB_Call) => {
		dispatch_onFoundMissedCall.dispatchUI([call]);
	};

	private dispatchGetOnGetCallableUnitsError = () => {
		dispatch_onGetCallableUnitsReturned.dispatchUI(
			[
				false,
				undefined
			]
		);
	};

}


export const TwilioVideoModule = new TwilioVideoModule_Class("TwilioVideoModule");
